import { useDialog } from '@lws/common';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { createContext, PropsWithChildren, useRef } from 'react';

import { userAPIClient } from '~/src/api/v2/UserAPI';
import { ClientError } from '~/src/errors/ClientErrors';
import { useOnboardingData } from '~/src/hooks/v1/Onboarding/useOnboardingData';
import { useAuth } from '~/src/hooks/v2/Account';
import { UserStorage } from '~/src/storage/UserStorage';
import { getOnboardingStepIndex } from '~/src/utils/common';

import { OnboardingExitDialog } from '../OnboardingExitDialog/OnboardingExitDialog';
import { ONBOARDING_STEP_NAMES_BY_ORDER } from '../OnboardingModal/constants/constants';
import { OnboardingModal } from '../OnboardingModal/OnboardingModal';
import { ONBOARDING_STEP_NAME_TYPES } from '../OnboardingModal/OnboardingModal.types';
import { OnboardingRequest } from '../OnboardingRequest/OnboardingRequest';
import { checkShowOnboardingByUser } from './utils';

interface OnboardingProps {
  onboardingRequestPopup: ReturnType<typeof useDialog>;
  onboardingModal: ReturnType<typeof useDialog>;
  onboardingExitDialog: ReturnType<typeof useDialog>;
  updateOnboardingStep: (onboardingStep: ONBOARDING_STEP_NAME_TYPES) => Promise<void>
  onStartOnboarding: () => void;
  onShowOnboardingRequestPopup: () => void;
}

export const OnboardingContext = createContext<OnboardingProps | undefined>(undefined);

export const OnboardingProvider = (props: PropsWithChildren) => {
  const router = useRouter();
  const { userId } = useAuth();
  const onboardingRequestPopup = useDialog({ defaultOpen: false });
  const onboardingModal = useDialog({ defaultOpen: false });
  const onboardingExitDialog = useDialog({ defaultOpen: false });
  const { onboardingQuery, onboardingData } = useOnboardingData();

  // 온보딩을 노출하지 않은 유저는 새로고침이나 재접속시에 계속 보여줘야하므로 내부 상태로 노출 여부를 관리함
  const isAlreadyOnboardingRequested = useRef<boolean>(false);

  /**
   * 유저 정보에 새로운 온보딩 단계를 저장함.
   * 이때, 새로운 온보딩 단계는 이전에 저장된 온보딩 단계보다 높은 경우에만 저장함
   * @param onboardingStep 새로 저장 시도 할 온보딩 단계
   */
  const updateOnboardingStep = async (onboardingStep: ONBOARDING_STEP_NAME_TYPES) => {
    const oldStepIndex = getOnboardingStepIndex(onboardingData?.onboardingStep);
    const newStepIndex = getOnboardingStepIndex(onboardingStep);

    // 새로운 온보딩 단계 > 이전 온보딩 단계 : 새로운 온보딩 단계를 저장
    if (newStepIndex > oldStepIndex) {
      try {
        if (userId) await userAPIClient.updateUser({ userId, isOnboardingRequestPopupOpened: true, onboardingStep });
        UserStorage.update({ onboardingStep: onboardingStep });
        onboardingQuery.refetch();
      } catch (error) {
        if (error instanceof AxiosError) {
          console.log(error);

          return;
        }

        throw new ClientError('UNEXPECTED_ERROR', 'Catch unexpected error');
      }
    }
  };

  const onStartOnboarding = () => {
    // 온보딩 요청 팝업에서 온보딩을 수락하면 가장 처음 단계로 가야함
    const firstStepName = ONBOARDING_STEP_NAMES_BY_ORDER[0] as ONBOARDING_STEP_NAME_TYPES;

    router.push({ query: { stepName: firstStepName }}, undefined, { shallow: true });
    updateOnboardingStep(firstStepName);
    onboardingRequestPopup.onClose();
    onboardingModal.onOpen();
  };

  const onRequestExit = () => {
    onboardingExitDialog.onOpen();
  };

  const onCancelExit = () => {
    onboardingExitDialog.onClose();
  };

  const onConfirmExit = () => {
    // 유저가 명백히 거부의사를 밝혔기에 온보딩 데이터를 보존하지 않음, 따라서 query 정보를 제거함
    router.push({ query: undefined }, undefined, { shallow: true });
    onboardingModal.onClose();
    onboardingExitDialog.onClose();
  };

  /**
   * 유저의 온보딩 진행 상태에 따라 온보딩 요청 팝업을 노출하는 함수
   */
  const onShowOnboardingRequestPopup = async () => {
    const isShowOnboarding = checkShowOnboardingByUser(onboardingData);

    if (isShowOnboarding) {
      if (isAlreadyOnboardingRequested.current === false) {
        onboardingRequestPopup.onOpen();

        UserStorage.update({
          isOnboardingRequestPopupOpened: true,
          onboardingStep: '',
        });

        if (userId && !onboardingData?.isOnboardingRequestPopupOpened) {
          userAPIClient.updateUser({
            userId,
            isOnboardingRequestPopupOpened: true,
            onboardingStep: '',
          });
        }
      }

      isAlreadyOnboardingRequested.current = true;
    }
  };

  return (
    <OnboardingContext.Provider
      value={{
        onboardingRequestPopup,
        onboardingModal,
        onboardingExitDialog,
        updateOnboardingStep,
        onStartOnboarding,
        onShowOnboardingRequestPopup,
      }}
    >
      {props.children}
      <OnboardingRequest />
      <OnboardingModal
        onExit={onRequestExit}
      />
      <OnboardingExitDialog
        onCancel={onCancelExit}
        onConfirm={onConfirmExit}
      />
    </OnboardingContext.Provider>
  );
};
