/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { getGradientCSS } from '../../utils/template';
import { GradientBackgroundColorLayerProps } from './GradientBackgroundColorLayer.types';

export const GradientBackgroundColorLayer = ({
  background,
}: GradientBackgroundColorLayerProps) => {
  const {
    hexColorFirst,
    hexColorSecond,
    colorRatio,
    angle,
  } = background;

  const gradient = getGradientCSS({
    startColor: hexColorFirst,
    endColor: hexColorSecond,
    ratio: colorRatio,
    degree: angle,
  });

  return (
    <div
      className="GradientBackgroundColorLayer"
      style={{
        backgroundImage: `${gradient}`,
      }}
      css={css`
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
      `}
    />
  );
};
