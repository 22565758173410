import {
  BUTTON_COLOR_MONO,
  BUTTON_COLOR_PRIMARY,
  BUTTON_COLOR_SECONDARY,
  BUTTON_STATUS_DISABLE,
  BUTTON_STATUS_ENABLE,
  BUTTON_STATUS_HOVER,
  BUTTON_STATUS_PRESSED,
  BUTTON_STATUS_SELECT,
  BUTTON_VARIANT_CONTAINED,
  BUTTON_VARIANT_OUTLINE,
  BUTTON_VARIANT_TEXT
} from '../constants/Button';

/**
 * Last Updated: 2024.02.13
 */

export const COLORS = {
  brand_900: {
    hex: '#E7571F',
    ratio: [1, 19.8],
    opacity: .97,
  },
  brand_800: {
    hex: '#F17022',
    ratio: [1, 15.59],
    opacity: .86,
  },
  brand_700: {
    hex: '#F87F24',
    ratio: [1, 11.18],
    opacity: .77,
  },
  brand_600: {
    hex: '#FF8E26',
    ratio: [1, 7],
    opacity: .65,
  },
  brand_500: {
    hex: '#FF9B28',
    ratio: [1, 4.5],
    opacity: .56,
  },
  brand_400: {
    hex: '#FFA93A',
    ratio: [1, 3.452],
    opacity: .45,
  },
  brand_300: {
    hex: '#FFB95A',
    ratio: [1, 2.58],
    opacity: .37,
  },
  brand_200: {
    hex: '#FFCD88',
    ratio: [1, 1.94],
    opacity: .26,
  },
  brand_100: {
    hex: '#FFE1B6',
    ratio: [1, 1.41],
    opacity: .15,
  },
  brand_50: {
    hex: '#FFF3E2',
    ratio: [1, 1.08],
    opacity: .04,
  },

  gray_900: {
    hex: '#080808',
    ratio: [1, 20.02],
    opacity: 1,
  },
  gray_800: {
    hex: '#242424',
    ratio: [1, 16.1],
    opacity: 1,
  },
  gray_700: {
    hex: '#3D3D3D',
    ratio: [1, 10.86],
    opacity: 1,
  },
  gray_600: {
    hex: '#595959',
    ratio: [1, 7],
    opacity: 1,
  },
  gray_500: {
    hex: '#757575',
    ratio: [1, 4.6],
    opacity: 1,
  },
  gray_400: {
    hex: '#8C8C8C',
    ratio: [1, 3.452],
    opacity: 1,
  },
  gray_300: {
    hex: '#A1A1A1',
    ratio: [1, 2.58],
    opacity: 1,
  },
  gray_200: {
    hex: '#BDBDBD',
    ratio: [1, 1.88],
    opacity: 1,
  },
  gray_100: {
    hex: '#D9D9D9',
    ratio: [1, 1.41],
    opacity: 1,
  },
  gray_50:  {
    hex: '#F0F0F0',
    ratio: [1, 1.13],
    opacity: 1,
  },

  dark_900: {
    hex: '#F8F8F8',
    ratio: [1, 20.02],
    opacity: .97,
  },
  dark_800: {
    hex: '#E1E1E1',
    ratio: [1, 15.9],
    opacity: .88,
  },
  dark_700: {
    hex: '#BABABA',
    ratio: [1, 10.82],
    opacity: .72,
  },
  dark_600: {
    hex: '#959595',
    ratio: [1, 7.01],
    opacity: .57,
  },
  dark_500: {
    hex: '#777777',
    ratio: [1, 4.68],
    opacity: .45,
  },
  dark_400: {
    hex: '#636363',
    ratio: [1, 3.49],
    opacity: .37,
  },
  dark_300: {
    hex: '#505050',
    ratio: [1, 2.60],
    opacity: .29,
  },
  dark_200: {
    hex: '#3C3C3C',
    ratio: [1, 1.90],
    opacity: .21,
  },
  dark_100: {
    hex: '#282828',
    ratio: [1, 1.42],
    opacity: .13,
  },
  dark_50: {
    hex: '#191919',
    ratio: [1, 1.19],
    opacity: .7,
  },

  red_900: {
    hex: '#520810',
    ratio: [1, 19.8],
    opacity: .97,
  },
  red_800: {
    hex: '#950F22',
    ratio: [1, 15.59],
    opacity: .86,
  },
  red_700: {
    hex: '#BB032A',
    ratio: [1, 11.18],
    opacity: .77,
  },
  red_600: {
    hex: '#DE1135',
    ratio: [1, 7],
    opacity: .65,
  },
  red_500: {
    hex: '#F83446',
    ratio: [1, 5.00],
    opacity: .56,
  },
  red_400: {
    hex: '#FC7F79',
    ratio: [1, 3.452],
    opacity: .45,
  },
  red_300: {
    hex: '#FFB2AB',
    ratio: [1, 2.58],
    opacity: .37,
  },
  red_200: {
    hex: '#FFD2CD',
    ratio: [1, 1.94],
    opacity: .26,
  },
  red_100: {
    hex: '#FFE1DE',
    ratio: [1, 1.41],
    opacity: .15,
  },
  red_50: {
    hex: '#FFF0EE',
    ratio: [1, 1.08],
    opacity: .04,
  },

  blue_900: {
    hex: '#002661',
    ratio: [1, 19.8],
    opacity: .97,
  },
  blue_800: {
    hex: '#1948A3',
    ratio: [1, 15.59],
    opacity: .86,
  },
  blue_700: {
    hex: '#175BCC',
    ratio: [1, 11.18],
    opacity: .77,
  },
  blue_600: {
    hex: '#266EF1',
    ratio: [1, 7],
    opacity: .65,
  },
  blue_500: {
    hex: '#068BEE',
    ratio: [1, 4.98],
    opacity: .56,
  },
  blue_400: {
    hex: '#6DAAFB',
    ratio: [1, 3.452],
    opacity: .45,
  },
  blue_300: {
    hex: '#A9C9FF',
    ratio: [1, 2.58],
    opacity: .37,
  },
  blue_200: {
    hex: '#CDDEFF',
    ratio: [1, 1.94],
    opacity: .26,
  },
  blue_100: {
    hex: '#DEE9FE',
    ratio: [1, 1.41],
    opacity: .15,
  },
  blue_50: {
    hex: '#EFF4FE',
    ratio: [1, 1.08],
    opacity: .04,
  },

  green_900: {
    hex: '#002F14',
    ratio: [1, 19.8],
    opacity: .97,
  },
  green_800: {
    hex: '#0D572D',
    ratio: [1, 15.59],
    opacity: .86,
  },
  green_700: {
    hex: '#166C3B',
    ratio: [1, 11.18],
    opacity: .77,
  },
  green_600: {
    hex: '#0E8345',
    ratio: [1, 7],
    opacity: .65,
  },
  green_500: {
    hex: '#009A51',
    ratio: [1, 5.08],
    opacity: .53,
  },
  green_400: {
    hex: '#06C167',
    ratio: [1, 3.452],
    opacity: .45,
  },
  green_300: {
    hex: '#7FD99A',
    ratio: [1, 2.58],
    opacity: .37,
  },
  green_200: {
    hex: '#B1EAC2',
    ratio: [1, 1.94],
    opacity: .26,
  },
  green_100: {
    hex: '#D3EFDA',
    ratio: [1, 1.41],
    opacity: .15,
  },
  green_50: {
    hex: '#EAF6ED',
    ratio: [1, 1.08],
    opacity: .04,
  },

  yellow_900: {
    hex: '#392300',
    ratio: [1, 19.8],
    opacity: .97,
  },
  yellow_800: {
    hex: '#6B4100',
    ratio: [1, 15.59],
    opacity: .86,
  },
  yellow_700: {
    hex: '#845201',
    ratio: [1, 11.18],
    opacity: .77,
  },
  yellow_600: {
    hex: '#9F6402',
    ratio: [1, 7],
    opacity: .65,
  },
  yellow_500: {
    hex: '#B97502',
    ratio: [1, 5.08],
    opacity: .53,
  },
  yellow_400: {
    hex: '#D79900',
    ratio: [1, 3.452],
    opacity: .45,
  },
  yellow_300: {
    hex: '#F6BC2F',
    ratio: [1, 2.58],
    opacity: .37,
  },
  yellow_200: {
    hex: '#FFD688',
    ratio: [1, 1.94],
    opacity: .26,
  },
  yellow_100: {
    hex: '#FBE5B6',
    ratio: [1, 1.41],
    opacity: .15,
  },
  yellow_50: {
    hex: '#FDF2DC',
    ratio: [1, 1.08],
    opacity: .04,
  },
  white: {
    hex: '#FFFFFF',
    ratio: [1, 1],
    opacity: 1,
  },
};

export const OPACITY = {
  gray_op_900: {
    hex: '#000000',
    ratio: [1, 20.02],
    opacity: .97,
  },
  gray_op_800: {
    hex: '#000000',
    ratio: [1, 16.1],
    opacity: .86,
  },
  gray_op_700: {
    hex: '#000000',
    ratio: [1, 10.86],
    opacity: .76,
  },
  gray_op_600: {
    hex: '#000000',
    ratio: [1, 7],
    opacity: .65,
  },
  gray_op_500: {
    hex: '#000000',
    ratio: [1, 4.6],
    opacity: .54,
  },
  gray_op_400: {
    hex: '#000000',
    ratio: [1, 3.36],
    opacity: .45,
  },
  gray_op_300: {
    hex: '#000000',
    ratio: [1, 2.58],
    opacity: .37,
  },
  gray_op_200: {
    hex: '#000000',
    ratio: [1, 1.87],
    opacity: .26,
  },
  gray_op_100: {
    hex: '#000000',
    ratio: [1, 1.41],
    opacity: .15,
  },
  gray_op_50: {
    hex: '#000000',
    ratio: [1, 1.13],
    opacity: .06,
  },

  dark_op_900: {
    hex: '#FFFFFF',
    ratio: [1, 20.02],
    opacity: .97,
  },
  dark_op_800: {
    hex: '#FFFFFF',
    ratio: [1, 15.9],
    opacity: .88,
  },
  dark_op_700: {
    hex: '#FFFFFF',
    ratio: [1, 10.82],
    opacity: .72,
  },
  dark_op_600: {
    hex: '#FFFFFF',
    ratio: [1, 7.01],
    opacity: .57,
  },
  dark_op_500: {
    hex: '#FFFFFF',
    ratio: [1, 4.68],
    opacity: .45,
  },
  dark_op_400: {
    hex: '#FFFFFF',
    ratio: [1, 3.49],
    opacity: .37,
  },
  dark_op_300: {
    hex: '#FFFFFF',
    ratio: [1, 2.60],
    opacity: .29,
  },
  dark_op_200: {
    hex: '#FFFFFF',
    ratio: [1, 1.90],
    opacity: .21,
  },
  dark_op_100: {
    hex: '#FFFFFF',
    ratio: [1, 1.42],
    opacity: .13,
  },
  dark_op_50: {
    hex: '#FFFFFF',
    ratio: [1, 1.19],
    opacity: .07,
  },
};

export const TYPOGRAPHY_COLORS = {
  title: COLORS['gray_900'],
  content: COLORS['gray_700'],
  caption: COLORS['gray_500'],
  placeholder: COLORS['gray_300'],
};

export const UI_COLORS = {
  button: {
    [BUTTON_COLOR_PRIMARY]: {
      [BUTTON_VARIANT_CONTAINED]: {
        [BUTTON_STATUS_ENABLE]: {
          border: COLORS['brand_600'],
          color: COLORS['white'],
          background: COLORS['brand_600'],
        },
        [BUTTON_STATUS_HOVER]: {
          border: COLORS['brand_700'],
          color: COLORS['white'],
          background: COLORS['brand_700'],
        },
        [BUTTON_STATUS_PRESSED]: {
          border: COLORS['brand_600'],
          color: COLORS['white'],
          background: COLORS['brand_600'],
        },
        [BUTTON_STATUS_SELECT]: {
          border: COLORS['brand_900'],
          color: COLORS['white'],
          background: COLORS['brand_900'],
        },
        [BUTTON_STATUS_DISABLE]: {
          border: COLORS['gray_100'],
          color: COLORS['gray_300'],
          background: COLORS['gray_100'],
        },
        loading: {
          border: COLORS['brand_600'],
          color: COLORS['white'],
          background: COLORS['brand_600'],
        },
      },
      [BUTTON_VARIANT_OUTLINE]: {
        [BUTTON_STATUS_ENABLE]: {
          border: COLORS['brand_900'],
          color: COLORS['brand_900'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_HOVER]: {
          border: COLORS['brand_900'],
          color: COLORS['brand_900'],
          background: COLORS['brand_50'],
        },
        [BUTTON_STATUS_PRESSED]: {
          border: COLORS['brand_900'],
          color: COLORS['brand_900'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_SELECT]: {
          border: COLORS['brand_900'],
          color: COLORS['brand_900'],
          background: COLORS['brand_100'],
        },
        [BUTTON_STATUS_DISABLE]: {
          border: COLORS['gray_100'],
          color: COLORS['gray_300'],
          background: COLORS['gray_100'],
        },
        loading: {
          border: COLORS['brand_900'],
          color: COLORS['brand_900'],
          background: COLORS['white'],
        },
      },
      [BUTTON_VARIANT_TEXT]: {
        [BUTTON_STATUS_ENABLE]: {
          border: COLORS['white'],
          color: COLORS['brand_900'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_HOVER]: {
          border: COLORS['brand_100'],
          color: COLORS['brand_900'],
          background: COLORS['brand_100'],
        },
        [BUTTON_STATUS_PRESSED]: {
          border: COLORS['white'],
          color: COLORS['brand_900'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_SELECT]: {
          border: COLORS['brand_100'],
          color: COLORS['brand_900'],
          background: COLORS['brand_100'],
        },
        [BUTTON_STATUS_DISABLE]: {
          border: COLORS['gray_100'],
          color: COLORS['gray_300'],
          background: COLORS['gray_100'],
        },
        loading: {
          border: COLORS['brand_900'],
          color: COLORS['brand_900'],
          background: COLORS['white'],
        },
      },
    },
    [BUTTON_COLOR_SECONDARY]: {
      [BUTTON_VARIANT_CONTAINED]: {
        [BUTTON_STATUS_ENABLE]: {
          border: OPACITY['gray_op_50'],
          color: COLORS['brand_600'],
          background: COLORS['brand_50'],
        },
        [BUTTON_STATUS_HOVER]: {
          border: OPACITY['gray_op_50'],
          color: COLORS['brand_600'],
          background: COLORS['brand_100'],
        },
        [BUTTON_STATUS_PRESSED]: {
          border: OPACITY['gray_op_50'],
          color: COLORS['brand_600'],
          background: COLORS['brand_50'],
        },
        [BUTTON_STATUS_SELECT]: {
          border: OPACITY['gray_op_50'],
          color: COLORS['brand_900'],
          background: COLORS['brand_100'],
        },
        [BUTTON_STATUS_DISABLE]: {
          border: COLORS['gray_100'],
          color: COLORS['gray_300'],
          background: COLORS['gray_100'],
        },
        loading: {
          border: COLORS['brand_50'],
          color: COLORS['white'],
          background: COLORS['brand_50'],
        },
      },
      [BUTTON_VARIANT_OUTLINE]: {
        [BUTTON_STATUS_ENABLE]: {
          border: COLORS['brand_300'],
          color: COLORS['brand_700'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_HOVER]: {
          border: COLORS['brand_300'],
          color: COLORS['brand_700'],
          background: COLORS['brand_50'],
        },
        [BUTTON_STATUS_PRESSED]: {
          border: COLORS['white'],
          color: COLORS['brand_700'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_SELECT]: {
          border: OPACITY['gray_op_50'],
          color: COLORS['brand_700'],
          background: COLORS['brand_50'],
        },
        [BUTTON_STATUS_DISABLE]: {
          border: COLORS['gray_100'],
          color: COLORS['gray_300'],
          background: COLORS['gray_100'],
        },
        loading: {
          border: COLORS['brand_600'],
          color: COLORS['brand_900'],
          background: COLORS['white'],
        },
      },
      [BUTTON_VARIANT_TEXT]: {
        [BUTTON_STATUS_ENABLE]: {
          border: COLORS['white'],
          color: COLORS['brand_700'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_HOVER]: {
          border: COLORS['brand_50'],
          color: COLORS['brand_700'],
          background: COLORS['brand_50'],
        },
        [BUTTON_STATUS_PRESSED]: {
          border: COLORS['white'],
          color: COLORS['brand_700'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_SELECT]: {
          border: COLORS['brand_50'],
          color: COLORS['brand_700'],
          background: COLORS['brand_50'],
        },
        [BUTTON_STATUS_DISABLE]: {
          border: COLORS['gray_100'],
          color: COLORS['gray_300'],
          background: COLORS['gray_100'],
        },
        loading: {
          border: COLORS['white'],
          color: COLORS['brand_900'],
          background: COLORS['white'],
        },
      },
    },
    [BUTTON_COLOR_MONO]: {
      [BUTTON_VARIANT_CONTAINED]: {
        [BUTTON_STATUS_ENABLE]: {
          background: COLORS['gray_50'],
          border: OPACITY['gray_op_50'],
          color: COLORS['gray_700'],
        },
        [BUTTON_STATUS_HOVER]: {
          background: COLORS['gray_100'],
          border: OPACITY['gray_op_50'],
          color: COLORS['gray_700'],
        },
        [BUTTON_STATUS_PRESSED]: {
          background: COLORS['gray_50'],
          border: OPACITY['gray_op_50'],
          color: COLORS['gray_700'],
        },
        [BUTTON_STATUS_SELECT]: {
          background: COLORS['gray_100'],
          border: OPACITY['gray_op_100'],
          color: COLORS['gray_900'],
        },
        [BUTTON_STATUS_DISABLE]: {
          background: COLORS['gray_100'],
          border: OPACITY['gray_op_50'],
          color: COLORS['gray_300'],
        },
      },
      [BUTTON_VARIANT_OUTLINE]: {
        [BUTTON_STATUS_ENABLE]: {
          border: OPACITY['gray_op_300'],
          color: COLORS['gray_700'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_HOVER]: {
          border: OPACITY['gray_op_300'],
          color: COLORS['gray_700'],
          background: COLORS['gray_300'],
        },
        [BUTTON_STATUS_PRESSED]: {
          border: OPACITY['gray_op_300'],
          color: COLORS['gray_700'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_SELECT]: {
          border: OPACITY['gray_op_300'],
          color: COLORS['gray_900'],
          background: COLORS['gray_50'],
        },
        [BUTTON_STATUS_DISABLE]: {
          border: COLORS['gray_100'],
          color: COLORS['gray_300'],
          background: COLORS['gray_100'],
        },
        loading: {
          border: COLORS['brand_900'],
          color: COLORS['brand_900'],
          background: COLORS['white'],
        },
      },
      [BUTTON_VARIANT_TEXT]: {
        [BUTTON_STATUS_ENABLE]: {
          border: COLORS['white'],
          color: COLORS['gray_700'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_HOVER]: {
          border: COLORS['gray_50'],
          color: COLORS['gray_700'],
          background: COLORS['gray_50'],
        },
        [BUTTON_STATUS_PRESSED]: {
          border: COLORS['white'],
          color: COLORS['gray_700'],
          background: COLORS['white'],
        },
        [BUTTON_STATUS_SELECT]: {
          border: COLORS['gray_50'],
          color: COLORS['gray_700'],
          background: COLORS['gray_50'],
        },
        [BUTTON_STATUS_DISABLE]: {
          border: COLORS['gray_100'],
          color: COLORS['gray_300'],
          background: COLORS['gray_100'],
        },
        loading: {
          border: COLORS['brand_900'],
          color: COLORS['brand_900'],
          background: COLORS['white'],
        },
      },
    },
  },
  input: {
    stroke: OPACITY['dark_op_100'],
    activeStroke: COLORS['brand_600'],
  },
};
