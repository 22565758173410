import { createContext, ReactNode, useState } from 'react';

import { ProductPlan } from '../api/v1/PolicyAPI';

interface PlanSelectorContextArguments {
  selectedPlan?: ProductPlan;
  setSelectedPlan: (plan: ProductPlan) => any;
}

export const PlanSelectorContext = createContext<PlanSelectorContextArguments>({
  selectedPlan: undefined,
  setSelectedPlan: () => {},
});

export const PlanSelectorProvider = ({ children }: { children: ReactNode }) => {
  const [selectedPlan, setSelectedPlan] = useState<ProductPlan>();

  return (
    <PlanSelectorContext.Provider
      value={{
        selectedPlan,
        setSelectedPlan,
      }}
    >
      { children }
    </PlanSelectorContext.Provider>
  );
};
