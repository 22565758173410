
import { TemplateObject } from '@lws/types';

import { COLOR_PICKER_TAB } from '~/src/components/business/ColorToolkit';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { OBJECT_TYPE } from '~/src/types/editor-policy';
import { TaxonomySaveType, TaxonomyTextSpacingLetter, TaxonomyTextSpacingLine } from '~/src/types/taxonomy';
import { UserPlan } from '~/src/types/user';

import { TaxonomyTemplateCategory } from './TaxonomyEditorEvent.types';

export class TaxonomyEditorEvent {
  private static instance: TaxonomyEditorEvent = new TaxonomyEditorEvent();

  public lastSelectedCategory?: TaxonomyTemplateCategory;
  public selectedCategory?: TaxonomyTemplateCategory;
  public selectedTemplateId?: string;
  public isAddedText = false;
  public isAddedSymbol = false;

  public static setTaxonomyCreateClickSave = () => {
    const {
      selectedCategory,
      selectedTemplateId,
      isAddedSymbol,
      isAddedText,
    } = this.getInstance();

    AmplitudeTaxonomyClient.createClickSave({
      industry_category: selectedCategory?.categoryName,
      template_id: selectedTemplateId,
      symbol_added: isAddedSymbol,
      text_added: isAddedText,
    });

    this.clear();
  };

  public static setTaxonomyVisitEditor = (
    userPlan: UserPlan
  ) => {
    AmplitudeTaxonomyClient.visitEditor({
      plan: userPlan,
    });
  };

  public static setTaxonomyToolbarTextFont = () => {
    AmplitudeTaxonomyClient.toolbarTextFont();
  };

  public static setTaxonomySelectFont = (fontName: string) => {
    AmplitudeTaxonomyClient.selectFont({
      font_name: fontName,
    });
  };

  public static setTaxonomyTextSpacing = (type: TaxonomyTextSpacingLine | TaxonomyTextSpacingLetter) => {
    AmplitudeTaxonomyClient.toolbarTextSpacing({
      type,
    });
  };

  public static setTaxonomyToolbarColor = (
    selectedObjectType: OBJECT_TYPE,
    tab?: COLOR_PICKER_TAB
  ) => {
    switch(selectedObjectType) {
    case 'CLTextView':
      AmplitudeTaxonomyClient.toolbarTextColor();
      break;

    case 'CLStickerView':
      AmplitudeTaxonomyClient.toolbarSymbolColor();
      break;

    case 'none':
      if (tab) this.setTaxonomyBackgroundColor(tab);
      break;
    }
  };

  public static setTaxonomyBackgroundColor = (tab: COLOR_PICKER_TAB) => {
    let type: 'color' | 'colorpicker' = 'color';

    if (tab === 'palette') type = 'color';
    if (tab === 'picker') type = 'colorpicker';

    AmplitudeTaxonomyClient.toolbarBgColor({
      type,
    });
  };

  public static setTaxonomyScrollFont = () => {
    AmplitudeTaxonomyClient.scrollFont();
  };

  public static setTaxonomySelectSymbolCategory = (categoryName: string) => {
    AmplitudeTaxonomyClient.selectSymbolCategory({
      symbol_category: categoryName,
    });
  };

  public static setTaxonomySelectSymbol = (graphicResourceId: string, categoryName: string) => {
    AmplitudeTaxonomyClient.selectSymbol({
      symbol_category: categoryName,
      symbol_id: graphicResourceId,
    });
  };

  public static setTaxonomyOpacityApplied = (
    selectedObjects: TemplateObject[],
    alpha: number
  ) => {
    selectedObjects.map(({ type }) => {
      switch (type) {
      case 'CLStickerView':
        AmplitudeTaxonomyClient.symbolOpacityApplied({
          alpha,
        });
        break;

      case 'CLTextView':
        AmplitudeTaxonomyClient.textOpacityApplied({
          alpha,
        });
        break;
      }
    });
  };

  public static setTaxonomyClickCloseExport = () => {
    AmplitudeTaxonomyClient.clickCloseExport();
  };

  public static setTaxonomyUndo = (
    objects: TemplateObject[]
  ) => {
    const element: ('background' | 'symbol' | 'text')[] =
      objects.length <= 0
        ? ['background']
        : objects.map(({ type }) => {
          if (type === 'CLStickerView') return 'symbol';
          return 'text';
        });

    AmplitudeTaxonomyClient.undo({
      element,
    });
  };

  public static setTaxonomyRedo = (
    objects: TemplateObject[]
  ) => {
    const element: ('background' | 'symbol' | 'text')[] =
      objects.length <= 0
        ? ['background']
        : objects.map(({ type }) => {
          if (type === 'CLStickerView') return 'symbol';
          return 'text';
        });

    AmplitudeTaxonomyClient.redo({
      element,
    });
  };

  public static setTaxonomyCreateAutoSave = (
    moment: TaxonomySaveType
  ) => {
    const {
      isAddedSymbol,
      isAddedText,
    } = this.getInstance();

    AmplitudeTaxonomyClient.createAutoSave({
      moment,
      // industry_category: selectedTemplateCategory?.categoryName,
      symbol_added: isAddedSymbol,
      // template_id: selectedTemplateId,
      text_added: isAddedText,
    });

    this.clear();
  };

  public static setTaxonomyCreateExitAutoSave = () => {
    const {
      isAddedSymbol,
      isAddedText,
    } = this.getInstance();

    AmplitudeTaxonomyClient.createExitAutoSave({
      // industry_category: selectedTemplateCategory?.categoryId,
      // template_id: selectedTemplateId,
      symbol_added: isAddedSymbol,
      text_added: isAddedText,
    });

    this.clear();
  };

  public static clear() {
    this.getInstance().isAddedText = false;
    this.getInstance().isAddedSymbol = false;
    this.getInstance().lastSelectedCategory = undefined;
    this.getInstance().selectedCategory = undefined;
    this.getInstance().selectedTemplateId = undefined;
  }

  public static getInstance() {
    return this.instance;
  }
}
