import { Fragment, useCallback, useContext, useEffect } from 'react';

import { SolidColorPainterProps } from './SolidColorPainter.types';
import { StickerContext } from './StickerProvider';

/**
 * 단색 설정이 되어 있는 SVG를 채색합니다.
 */
export const SolidColorPainter = ({
  svg,
}: SolidColorPainterProps) => {
  const context = useContext(StickerContext);

  if (context === undefined) throw new Error('StickerProvider not declared.');

  const { object } = context;
  const { hexColor } = object;

  const paint = useCallback((svg: SVGElement, hexColor: string) => {
    const nodes = svg.querySelectorAll('rect, path, circle, ellipse, polygon');

    nodes.forEach((node) => {
      const element = node as SVGSVGElement;

      if (element.getAttribute('fill') === 'none') {
        element.style.setProperty('stroke', hexColor);
      } else {
        element.style.setProperty('fill', hexColor);
      }
    });
  }, []);

  useEffect(() => {
    if (svg && hexColor && hexColor !== '#none') paint(svg, hexColor);
  }, [svg, hexColor, paint]);

  return (
    <Fragment />
  );
};

