import { css } from '@emotion/react';
import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import Image, { StaticImageData } from 'next/image';

import { Icon } from '~/src/components/app/Icon/v2';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { ComponentProps } from '~/src/types/common';

export interface Review {
  avatar: StaticImageData;
  comment: string;
  name: string;
  jobDescription: string;
  star: number;
}

interface PlanReviewCardProps extends ComponentProps {
  review: Review;
}

export const ReviewCard = (props: PlanReviewCardProps) => {
  const { review } = props;
  const {
    avatar,
    comment,
    name,
    jobDescription,
    star,
  } = review;

  const mobile = useMediaQuery('mobile');
  const theme = useTheme();

  return (
    <div
      className={classNames(
        'PlanReviewCard',
        props.className
      )}
      data-testid="PlanReviewCard"
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 428px;
        padding-top: 30px;
        padding-bottom: 48px;
        border-radius: 18px;
        background-color: #FBF8F5;
        text-align: center;
        width: 100%;
        padding: 32px;
      `}
    >
      <div
        css={css`
          width: 102px;
          height: 102px;
          margin: 0 auto;
          border-radius: 50%;
        `}
      >
        <Image
          src={avatar.src}
          alt={comment}
          data-testid="PlanReviewCard__Image"
          width={avatar.width}
          height={avatar.height}
          css={css`
            width: 100%;
            height: 100%;
            border-radius: inherit;
            object-fit: cover;
            object-position: center;
          `}
        />
      </div>
      <Typography
        variant="body4"
        data-testid="PlanReviewCard__Comment"
        css={css`
          margin-top: 20px;
          color: ${theme.app.colors['neutral-color-01']};
          /* Body02 */
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */

          ${mobile.only.mq} {
            /* Caption01 */
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 21px */
          }
        `}
      >
        {comment}
      </Typography>
      <Typography
        variant="body2"
        data-testid="PlanReviewCard__Name"
        css={css`
          margin-top: 25px;
        `}
      >
        {name}
      </Typography>
      <Typography
        variant="body11"
        data-testid="PlanReviewCard__JD"
        css={css`
          color: #757575;
        `}
      >
        {jobDescription}
      </Typography>
      <div
        data-testid="PlanReviewCard__Star"
        css={css`
          margin-top: 12px;
        `}
      >
        {Array.from({ length: star }).map((_, key) => {
          return (
            <Icon
              key={key}
              width={18}
              height={18}
            >
              ic_review_primary_star
            </Icon>
          );
        })}
      </div>
    </div>
  );
};
