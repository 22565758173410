import { css } from '@emotion/react';

import { ReviewCard } from '../ReviewCard/ReviewCard';
import { SwiperReviewListProps } from './SwiperReviewList.types';

export const SwiperReviewList = ({
  reviews,
}: SwiperReviewListProps) => {
  return (
    <div
      className="SwiperReviewList"
      css={css`
          width: 100%;
          max-width: 100%;
          display: flex;
          overflow-x: auto;
          justify-content: flex-start;
          align-items: flex-start;
          column-gap: 16px;
          padding: 0 32px;
      `}
    >
      {
        reviews.map((review, index) => {
          return (
            <ReviewCard
              key={index}
              review={review}
              css={css`
                min-width: 300px;
                width: 300px;
                min-height: 430px;
                height: 430px;
              `}
            />
          );
        })
      }
    </div>
  );
};
