export const convertNlToP = (text: string) => {
  return text.replaceAll('\\n', '\n').split('\n').map((str) => `<p>${str}</p>`).join('');
};

export const convertStringToHTMLString = (string: string) => {
  return string.replaceAll('\\n', '\n').split('\n').join('<br />');
};

export const getQueryString = (key: string) => {
  const params = new URLSearchParams(window.location.search);

  return params.get(key) ?? undefined;
};

export const isColor = (color: string) => {
  const option = new Option();
  option.style.color = color;

  return option.style.color !== '';
};

export const convertDegToRad = (degree: number) => {
  return degree * (Math.PI / 180);
};

export const convertRadToDeg = (radian: number) => radian * (180 / Math.PI);

export const getSingleLineText = (text: string) => {
  return text.replaceAll('\\n', '\n').replaceAll('\n', '&nbsp');
};

export const clamp = (value: number, min: number, max: number) => {
  return Math.max(min, Math.min(max, value));
};
