import { TemplateTextObject } from '@lws/types';
import uuid from 'react-uuid';

import { MAX_KERNING_SLIDER_VALUE, MIN_KERNING_SLIDER_VALUE, TEXT_KERNING_MULTIPLIER, TEXT_OBJECT_CURVED_TYPE, TEXT_OBJECT_DEFAULT_FONT_SIZE, TEXT_OBJECT_PLAIN_TYPE } from '../../constants';
import { convertRadToDeg, getCurvedTextAngle, getCurvedTextArcLength, getCurvedTextAreaSize, getFontResourceFromDecorators, getObjectPosition, getTextAlignment, getTextObjectFontSize, getTextObjectLineSpacingCSS, getTextObjectSize, getTextType } from '../../utils';

export class PlainTextManager {
  static defaultFont = 'AbhayaLibre-ExtraBold';
  static defaultText = 'Your brand text';
  static defaultFontSize = 20;
  static defaultCenterX = 0.5;
  static defaultCenterY = 0.5;

  static getInitialTextObject() {
    const defaultObject: TemplateTextObject = {
      type: 'CLTextView',
      textAlignment: 1,
      hexColor: '#000000',
      text: this.defaultText,
      alpha: 1,
      centerX: this.defaultCenterX,
      centerY: this.defaultCenterY,
      frameWidth: 0,
      frameHeight: 0,
      maskImageCenterX: 0,
      maskImageCenterY: 0,
      maskImageScale: 0,
      fontSize: this.defaultFontSize,
      scale: this.getScaleFromFontSize(this.defaultFontSize),
      arg: 0,
      hue: 0,
      brightness: 0,
      lineSpacing: 1,
      kerning: 0,
      curve: 0,
      radius: 0,
      step: 0,
      decoratorIds: [
        this.getFontDecorator(this.defaultFont),
      ],
      parameters: {
        primaryKey: uuid(),
        singleLineHeight: 59,
      },
    } as TemplateTextObject;

    return defaultObject;
  }

  static getFontDecorator(fontName: string){
    const decorator = `pixo-cms://resource@embedded/font/${fontName}`;

    return decorator;
  }

  static getScaleFromFontSize(fontSize: number) {
    return fontSize / TEXT_OBJECT_DEFAULT_FONT_SIZE;
  }

  static getFontSizeFromScale(scale: number) {
    return TEXT_OBJECT_DEFAULT_FONT_SIZE * scale;
  }

  static getLetterSpacingStyleFromKerning(kerning: number, scale: number) {
    const parsedKerning = this.getSliderValueFromParsedKerning(kerning, scale);
    const sliderValue = this.getParsedKerningFromKerning(parsedKerning);

    return sliderValue * TEXT_KERNING_MULTIPLIER;
  }

  static getParsedKerningFromKerning(kerning: number) {
    const centerValue = (MIN_KERNING_SLIDER_VALUE + MAX_KERNING_SLIDER_VALUE) / 2;

    if (centerValue < kerning) return (kerning - centerValue) * 2;
    if (centerValue > kerning) return (centerValue - kerning) * -1;
    return 0;
  }

  static getSliderValueFromParsedKerning = (kerning: number, scale: number) => {
    const _kerning = kerning / scale;
    const centerValue = (MIN_KERNING_SLIDER_VALUE + MAX_KERNING_SLIDER_VALUE) / 2;

    if (0 < _kerning) return _kerning / 2 + centerValue;
    if (0 > _kerning) return _kerning + centerValue;
    return centerValue;
  };

  static getTextFrameSize(object: TemplateTextObject) {
    const {
      text,
      decoratorIds,
      scale,
      kerning,
    } = object;

    const fontFamily = getFontResourceFromDecorators(decoratorIds);
    const fontSize = getTextObjectFontSize(scale);
    const letterSpacing = this.getLetterSpacingStyleFromKerning(kerning, scale);

    if (fontFamily) {
      const { width, height } = getTextObjectSize({
        text,
        fontFamily,
        fontSize,
        letterSpacing,
      });

      return {
        width,
        height,
      };
    }

    return undefined;
  }

  static getTextElementStyles(
    object: TemplateTextObject,
    canvasWidth: number,
    canvasHeight: number
  ) {
    const {
      curve,
      decoratorIds,
      centerX,
      centerY,
      arg,
      kerning,
      scale,
      lineSpacing,
      parameters,
      hexColor,
      flipped,
      alpha,
      textAlignment,
      text,
      radius,
    } = object;

    const { singleLineHeight } = parameters;
    const fontFamily = getFontResourceFromDecorators(decoratorIds);

    if (fontFamily === undefined) return undefined;

    const letterSpacing = this.getLetterSpacingStyleFromKerning(kerning, scale);
    const lineHeight = getTextObjectLineSpacingCSS({ singleLineHeight, lineSpacing });
    const fontSize = getTextObjectFontSize(scale);
    const textType = getTextType(object);
    let frameWidth = 0;
    let frameHeight = 0;

    if (textType === TEXT_OBJECT_PLAIN_TYPE) {
      const {
        width,
        height,
      } = getTextObjectSize({
        text,
        fontFamily,
        letterSpacing,
        lineSpacing: lineHeight,
        fontSize,
      });

      frameWidth = width;
      frameHeight = height;
    } else if (textType === TEXT_OBJECT_CURVED_TYPE) {
      const arcLength = getCurvedTextArcLength({
        text,
        fontFamily,
        fontSize,
        letterSpacing,
      });
      const angle = getCurvedTextAngle({ arcLength, radius });
      const {
        width,
        height,
      } = getCurvedTextAreaSize({ angle, radius, singleLineHeight });

      frameWidth = width;
      frameHeight = height;
    }

    const {
      left,
      top,
    } = getObjectPosition({
      frameWidth,
      frameHeight,
      centerX,
      centerY,
      canvasWidth,
      canvasHeight,
    });

    const degree = convertRadToDeg(arg);
    const alignment = getTextAlignment(textAlignment);

    return {
      curve,
      frameWidth,
      frameHeight,
      degree,
      letterSpacing,
      lineHeight,
      fontFamily,
      alignment,
      left,
      top,
      hexColor,
      flipped,
      alpha,
      scale,
    };
  }

  /**
   * FIX: 추후 에디터 + 렌더러 통합 시 제거 될 함수
   */
  static getLegacyTextWrapperElement(object: TemplateTextObject) {
    const { parameters } = object;
    const { primaryKey } = parameters;
    const textElement = document.querySelector(`.TextObject[data-primary-key="${primaryKey}"]`) as HTMLElement;

    if (textElement === undefined) return undefined;

    return textElement;
  }

  static setLegacyTextElementStyles(
    object: TemplateTextObject,
    canvasWidth: number,
    canvasHeight: number
  ) {
    const element = this.getLegacyTextWrapperElement(object);
    const styles = this.getTextElementStyles(object, canvasWidth, canvasHeight);
    const plainTextWrapper = element?.querySelector('div[data-element-type="plain-text-editor"]') as HTMLElement;
    const curvedTextWrapper = element?.querySelector('div[data-element-type="curved-text-editor"]') as HTMLElement;
    const opacityWrapper = element?.querySelector('div[data-text-object-opacity="true"]') as HTMLElement;

    if (
      element &&
      plainTextWrapper &&
      curvedTextWrapper &&
      opacityWrapper &&
      styles
    ) {
      const {
        curve,
        frameWidth,
        frameHeight,
        left,
        top,
        degree,
        letterSpacing,
        lineHeight,
        fontFamily,
        scale,
        hexColor,
        flipped,
        alpha,
        alignment,
      } = styles;

      plainTextWrapper.style.setProperty('opacity', !curve ? '1' : '0');
      plainTextWrapper.style.setProperty('pointer-events', !curve ? 'auto' : 'none');
      curvedTextWrapper.style.setProperty('opacity', curve ? '1' : '0');
      curvedTextWrapper.style.setProperty('pointer-events', curve ? 'auto' : 'none');

      opacityWrapper.style.setProperty('opacity', alpha.toString());

      element.style.setProperty('width', `${frameWidth}px`);
      element.style.setProperty('height', `${frameHeight}px`);
      element.style.setProperty('left', `${left}px`);
      element.style.setProperty('top', `${top}px`);
      element.style.setProperty('transform', `rotateZ(${degree}deg)`);
      element.style.setProperty('--letter-spacing', `${letterSpacing}px`);
      element.style.setProperty('--line-spacing', `${lineHeight}px`);
      element.style.setProperty('--font-family', fontFamily);
      element.style.setProperty('--font-scale', `${scale}`);
      element.style.setProperty('--color', hexColor);
      element.style.setProperty('--fliped-degree', flipped ? '180deg' : '0');
      element.style.setProperty('--alignment', alignment);
    }
  }
}
