import { css, Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import getSymbolFromCurrency from 'currency-symbol-map';

import { Button } from '~/src/components/app/Button/v2';
import { Icon } from '~/src/components/app/Icon/v2';
import { ClickLogger } from '~/src/components/app/Logs/ClickLogger';
import { Nl2br } from '~/src/components/app/Nl2br';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

import { CheckoutCardProps } from './CheckoutCard.types';

/**
 * 플랜 정보를 표시하기 위한 카드입니다.
 */
export const CheckoutCard = ({
  className,
  plan,
  selected,
  selectTaxonomy,
  onClickPlan,
}: CheckoutCardProps) => {
  const {
    currency,
    planName,
    price,
    characteristics,
    period,
    description,
    benefits,
    caution,
    confirmMessage,
    forWho,
    originalPrice,
    saveMoney,
  } = plan;

  const theme = useTheme();
  const tablet = useMediaQuery('tablet');

  return (
    <div
      className={classNames(
        'CheckoutCard',
        className
      )}
      data-testid="CheckoutCard"
      css={css`
        display: inline-block;
        width: 360px;
        align-self: stretch;
        padding: 44px 28px 36px;
        border: 1px solid ${theme.app.colors['neutral-color-07']};
        border-radius: 16px;
        ${selected && css`
          border-width: 4px;
          border-color: ${theme.app.colors['primary-color-02']};
        `}
        ${tablet.down.mq} {
          width: 100%;
        }
      `}
    >
      <Typography
        variant="h4"
        data-testid="CheckoutCard__PlanName"
        css={css`
          color: ${theme.app.colors['neutral-color-01']};
        `}
      >
        {planName}
      </Typography>
      <Typography
        variant="body9"
        data-testid="CheckoutCard__ForWho"
        css={css`
          margin-top: 4px;
          color: ${theme.app.colors['neutral-color-05']};
        `}
      >
        {forWho}
      </Typography>
      <Typography
        variant="h3"
        data-testid="CheckoutCard__Pricing"
        css={css`
          margin-top: 16px;
          color: ${theme.app.colors['neutral-color-01']};
        `}
      >
        {getSymbolFromCurrency(currency)}{price}
        {period && (
          <Typography
            variant="body8"
            component={'span'}
          >
            / {period}
          </Typography>
        )}
      </Typography>
      <Typography
        variant="body9"
        data-testid="CheckoutCard__Characteristics"
        css={css`
          color: ${theme.app.colors['neutral-color-01']};
        `}
      >
        {characteristics}
      </Typography>
      <Typography
        variant="body8"
        data-testid="CheckoutCard__Description"
        css={css`
          font-weight: 500;
          color: ${theme.app.colors['neutral-color-01']};
        `}
      >
        {description}
      </Typography>
      {originalPrice && saveMoney && (
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-items: center;
            width: fit-content;
            margin-top: 8px;
            padding: 6px 16px;
            border: 1px solid ${theme.app.colors['second-color-1']};
            border-radius: 10px;
            background-color: ${theme.app.colors['second-color-3']};
          `}
        >
          <Typography
            variant="body9"
            data-testid="CheckoutCard__OriginalPrice"
            component={'span'}
            css={css`
              margin-right: 1ch;
              color: ${theme.app.colors['neutral-color-01']};
              text-decoration: line-through;
            `}
          >
            {originalPrice}
          </Typography>
          <Typography
            variant="body9"
            data-testid="CheckoutCard__SaveMoney"
            component={'span'}
            css={css`
              color: ${theme.app.colors['second-color-2']};
            `}
          >
            {saveMoney}
          </Typography>
        </div>
      )}
      <ClickLogger
        taxonomy={selectTaxonomy}
      >
        <Button
          variant="outlined"
          color="primary"
          css={css`
          width: 100%;
          margin-top: 28px;
          border-radius: 4px;
          ${!selected && css`
            border-color: ${theme.app.colors['primary-color-02']};
            color: ${theme.app.colors['primary-color-02']};
            background-color: #ffffff !important;
          `}
        `}
          onClick={onClickPlan}
        >
          <Typography
            variant="button2"
            data-testid="CheckoutCard__ConfirmMessage"
          >
            {confirmMessage}
          </Typography>
        </Button>
      </ClickLogger>
      <div
        css={css`
          margin-top: 28px;
        `}
      >
        {
          benefits.map((benefit, key) => {
            return (
              <div
                key={key}
                css={css`
                  display: flex;
                  align-items: flex-start;
                  margin-bottom: 14px;
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                `}
              >
                <div
                  css={css`
                    width: 24px;
                    height: 24px;
                    margin-right: 2px;
                  `}
                >
                  <Icon
                    width={24}
                    height={24}
                  >
                    ic_inform_check
                  </Icon>
                </div>
                <Typography
                  variant="body6"
                  data-testid="CheckoutCard__Benefit"
                  css={css`
                    color: ${theme.app.colors['neutral-color-02']};
                    margin-bottom: 14px;
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                  `}
                >
                  {benefit}
                </Typography>
              </div>
            );
          })
        }
      </div>
      {
        caution && (
          <Typography
            variant="body9"
            data-testid="CheckoutCard__Caution"
            css={css`
              margin-top: 42px;
              color: ${theme.app.colors['neutral-color-01']};
              text-align: center;
            `}
          >
            <Nl2br>
              {caution}
            </Nl2br>
          </Typography>
        )
      }
    </div>
  );
};
