import { TemplateTextObject } from '@lws/types';

import { CURVED_TEXT_DIRECTION_UP, MAX_FONT_SIZE, MAX_KERNING_SLIDER_VALUE, MIN_FONT_SIZE, MIN_KERNING_SLIDER_VALUE, TEXT_ALIGNMENT_CENTER, TEXT_ALIGNMENT_CENTER_LABEL, TEXT_ALIGNMENT_LEFT, TEXT_ALIGNMENT_LEFT_LABEL, TEXT_ALIGNMENT_RIGHT_LABEL, TEXT_KERNING_SLIDER_VALUE_CENTER, TEXT_OBJECT_CURVED_TYPE, TEXT_OBJECT_DEFAULT_FONT_SIZE, TEXT_OBJECT_PLAIN_TYPE } from '../../constants/common';
import { TEXT_ALIGNMENT_ALL } from '../../constants/common/types';
import { clamp, convertDegToRad, convertNlToP, convertRadToDeg, getSingleLineText } from '../common';
import { parsePixoAppURI, parsePixoCmsURI } from '../pixo';
import { ConvertKerningToSliderValueArguments, ConvertSliderValueToKerningArguments, GetCurvedTextAngleArguments, GetCurvedTextArcLengthArguments, GetCurvedTextCircleCenterPointArguments, GetCurvedTextCircleSizeArguments, GetCurvedTextPlainTextSizeArguments, GetCurvedTextRadiusArguments, GetCurvedTextStartAngleArguments, GetCurvedTextStartPointArguments, GetObjectPositionArguments, GetStickerSizeArguments, GetStickerSymbolSizeArguments, GetTextObjectLineSpacingCSSArguments, GetTextObjectSingleLineHeightArguments, GetTextObjectSizeArguments, RenderPlainTextArguments, SetFontArguments } from './types';

/**
 * 텍스트 오브젝트의 decoratorIds 배열을 매개변수로 전달하면
 * 가장 첫번째로 탐색한 폰트의 이름이 반환 됩니다.
 */
export const getFontResourceFromDecorators = (decorators: string[]) => {
  for (const decorator of decorators) {
    if (decorator.includes('/font/')) {
      const { name } = parsePixoCmsURI(decorator);

      return name;
    }
  }

  return undefined;
};

/**
 * 템플릿에서 사용할 imageMaxSize를 구하는 공식입니다.
 * @param width
 * @param height
 * @returns
 */
export const getImageMaxSize = (width: number, height: number) => {
  return Math.min(width, height) * 1.25;
};

export const clampFontSize = (fontSize: number): number => {
  return clamp(fontSize, MIN_FONT_SIZE, MAX_FONT_SIZE);
};

/**
 * 오브젝트가 배치 되는 포지션을 구합니다.
 * @param param0
 * @returns
 */
export const getObjectPosition = ({
  frameWidth,
  frameHeight,
  centerX,
  centerY,
  canvasWidth,
  canvasHeight,
}: GetObjectPositionArguments) => {
  return {
    left: canvasWidth * centerX - frameWidth / 2,
    top: canvasHeight * centerY - frameHeight / 2,
  };
};

export const getOriginalLineHeight = (fontName: string): number => {
  const element = document.createElement('p');

  const style = `
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    letter-spacing: normal;
    line-height: normal;
    font-family: '${fontName}' !important;
    font-size: 50px;
    z-index: 9999;
  `;

  element.setAttribute('style', style);

  element.innerHTML = 'A';

  document.body.append(element);

  const originalLineHeight = element.clientHeight;

  element.remove();

  return originalLineHeight;
};

export const getStickerObjectResourceId = (contentId: string) => {
  const { id } = parsePixoAppURI(contentId);

  return id;
};

/**
 * 스티커 사이즈를 구합니다.
 * @returns
 */
export const getStickerSize = ({
  object,
  imageWidth,
  imageHeight,
  canvasWidth,
  canvasHeight,
  scale,
  deviceScale,
  imageMaxSize,
}: GetStickerSizeArguments) => {
  const { frameWidth, frameHeight } = getStickerSymbolSize({
    imageWidth,
    imageHeight,
    canvasWidth,
    canvasHeight,
    scale,
    deviceScale,
    imageMaxSize,
  });

  return {
    ...object,
    frameWidth,
    frameHeight,
  };
};

export const getStickerSymbolSize = ({
  imageWidth,
  imageHeight,
  canvasWidth,
  canvasHeight,
  scale,
  deviceScale,
  imageMaxSize,
}: GetStickerSymbolSizeArguments) => {
  const maxSize = imageMaxSize ?? getImageMaxSize(canvasWidth, canvasHeight);
  const symbolSize = maxSize * deviceScale * scale;

  const frameWidth = imageWidth > imageHeight ? symbolSize : symbolSize * (imageWidth / imageHeight);
  const frameHeight = imageHeight > imageWidth ? symbolSize : symbolSize * (imageHeight / imageWidth);

  return { frameWidth, frameHeight };
};

export const getTextAlignment = (alignment: TEXT_ALIGNMENT_ALL) => {
  if (alignment === TEXT_ALIGNMENT_LEFT) return TEXT_ALIGNMENT_LEFT_LABEL;
  if (alignment === TEXT_ALIGNMENT_CENTER) return TEXT_ALIGNMENT_CENTER_LABEL;

  return TEXT_ALIGNMENT_RIGHT_LABEL;
};

export const getTextObjectFontSize = (scale: number) => {
  return TEXT_OBJECT_DEFAULT_FONT_SIZE * scale;
};

export const getTextObjectSingleLineHeight = ({
  fontFamily,
  letterSpacing,
}: GetTextObjectSingleLineHeightArguments) => {
  const originalTextNode = renderPlainText({
    text: 'A',
    fontFamily,
    letterSpacing,
    fontSize: TEXT_OBJECT_DEFAULT_FONT_SIZE,
  });

  const { clientHeight } = originalTextNode;

  originalTextNode.remove();

  return clientHeight;
};

export const getTextObjectSize = ({
  text,
  letterSpacing,
  lineSpacing,
  fontFamily,
  fontSize,
}: GetTextObjectSizeArguments) => {
  const textNode = renderPlainText({
    text,
    letterSpacing,
    lineSpacing,
    fontFamily,
    fontSize,
  });

  const {
    width,
    height,
  } = textNode.getBoundingClientRect();

  textNode.remove();

  return {
    width,
    height,
  };
};

export const getTextObjectLineSpacingCSS = ({
  singleLineHeight,
  lineSpacing,
}: GetTextObjectLineSpacingCSSArguments) => {
  const _lineHeight = (lineSpacing - 1) * singleLineHeight;

  return _lineHeight;
};

export const getTextObjectPadding = (singleLineHeight: number, fontSize: number) => {
  const BORDER_PADDING = 5;
  // 초기 렌더링된 폰트 스케일을 구합니다.
  const defaultFontScale = 20 / TEXT_OBJECT_DEFAULT_FONT_SIZE;
  // 현재 렌더링된 폰트 스케일을 구합니다.
  const fontScale = fontSize / TEXT_OBJECT_DEFAULT_FONT_SIZE;
  // 두 스케일간의 비율차를 구합니다.
  const defaultFontScaleRatio = defaultFontScale / fontScale;

  // BORDER_PADDING과 singleLineHeight에 비율차를 적용하여 스케일 다운/업에 대응합니다.
  return (BORDER_PADDING / defaultFontScaleRatio) * 2 + (singleLineHeight / defaultFontScaleRatio) * 0.6;
};

export const hasLoadedFont = (fontName: string): Promise<boolean> => {
  return document.fonts.load(fontName, 'Text').then(() => true);
};

export const removeFont = (fontFace: FontFace) => {
  document.fonts.delete(fontFace);
};

export const renderPlainText = ({
  text,
  fontFamily,
  letterSpacing,
  fontSize,
  lineSpacing,
}: RenderPlainTextArguments) => {
  const p = convertNlToP(text);
  const div = document.createElement('div');
  const scale = getFontScaleFromFontSize(fontSize);

  div.innerHTML = p;
  div.style.cssText = `
    position: fixed;
    left: 0;
    top: 0;
    font-family: ${fontFamily};
    letter-spacing: ${letterSpacing ? `${letterSpacing}px` : 'normal'};
    line-height: ${lineSpacing ? `${lineSpacing}px` : 'normal'};
    font-size: 50px;
    transform: scale(${scale});
    transform-origin: left top;
    visibility: hidden;
  `;

  const childNodes = Array.from(div.getElementsByTagName('p'));

  childNodes.forEach((node, index) => {
    const isLast = index === childNodes.length - 1;

    node.style.cssText = `
      margin: 0;
      margin-bottom: ${(lineSpacing && !isLast) ? `${lineSpacing}px` : '0px'};
      line-height: normal;
    `;
  });

  document.documentElement.append(div);

  return div;
};

export const setFont = ({
  fontName,
  fontData,
}: SetFontArguments) => {
  return new Promise<FontFace>((resolve, reject) => {
    const newFont = new FontFace(fontName, `url(${fontData})`);

    if (newFont.status === 'loaded') return resolve(newFont);

    return newFont.load().then(() => {
      document.fonts.add(newFont);

      resolve(newFont);
    }).catch((error) => {
      reject(error);
    });
  });
};

export const getFontScaleFromFontSize = (fontSize: number) => {
  return fontSize / TEXT_OBJECT_DEFAULT_FONT_SIZE;
};

export const getCenterPoint = (width: number, height: number) => {
  const x = width / 2;
  const y = height / 2;

  return { x, y };
};

export const convertKerningToSliderValue = ({
  kerning,
  scale,
}: ConvertKerningToSliderValueArguments) => {
  const scaledKerning = kerning / scale;

  if (0 < scaledKerning) return scaledKerning / 2 + TEXT_KERNING_SLIDER_VALUE_CENTER;
  if (0 > scaledKerning) return scaledKerning + TEXT_KERNING_SLIDER_VALUE_CENTER;

  return TEXT_KERNING_SLIDER_VALUE_CENTER;
};

export const convertSliderValueToKerning = ({
  sliderValue,
}: ConvertSliderValueToKerningArguments) => {
  if (TEXT_KERNING_SLIDER_VALUE_CENTER < sliderValue) return (sliderValue - TEXT_KERNING_SLIDER_VALUE_CENTER) * 2;
  if (TEXT_KERNING_SLIDER_VALUE_CENTER > sliderValue) return (TEXT_KERNING_SLIDER_VALUE_CENTER - sliderValue) * -1;

  return 0;
};

export const getTextType = (textObject: TemplateTextObject) => {
  const {
    type,
    curve,
  } = textObject;

  if (type !== 'CLTextView') throw new Error('This is not Text Object.');

  if (curve === undefined || curve === 0) return TEXT_OBJECT_PLAIN_TYPE;
  if (curve !== 0) return TEXT_OBJECT_CURVED_TYPE;

  return TEXT_OBJECT_PLAIN_TYPE;
};

export const getCurvedTextRadius = ({
  curve,
  step,
  plainTextWidth,
}: GetCurvedTextRadiusArguments) => {
  if (curve === 0) return 0;
  const absCurve = Math.abs(curve);

  return plainTextWidth / (Math.PI * absCurve * step) * 180;
};

export const getChord = (radius: number, angle: number, isDegree = false) => {
  const radian = isDegree ? convertDegToRad(angle / 2) : angle / 2;
  return 2 * radius * Math.sin(radian);
};

export const getSagitta = (radius: number, angle: number, isDegree = false) => {
  const radian = isDegree ? convertDegToRad(angle / 2) : (angle / 2);
  return radius * (1 - Math.cos(radian));
};

export const getCurvedTextSize = (radius: number, singleLineHeight: number, angle: number) => {
  const width = getChord(radius, Math.min(180, angle), false);
  const height = getSagitta(radius, Math.min(360, angle), false) + singleLineHeight;

  return { width, height };
};

export const getCurvedTextStartAngle = ({
  curveDirection,
  angle,
  isDegree = true,
}: GetCurvedTextStartAngleArguments) => {
  const degree = isDegree ? angle : convertRadToDeg(angle);

  return (curveDirection === CURVED_TEXT_DIRECTION_UP ? degree : -degree) / 2;
};

export const getCurvedTextAngle = ({
  arcLength,
  radius,
}: GetCurvedTextAngleArguments) => {
  return (arcLength / (radius * Math.PI)) * 180;
};

export const getCurvedTextArcLength = ({
  text,
  fontFamily,
  fontSize,
  letterSpacing,
}: GetCurvedTextArcLengthArguments) => {
  const plainText = getSingleLineText(text);

  const node = renderPlainText({
    text: plainText,
    fontFamily,
    fontSize,
    letterSpacing,
  });

  const { width } = node.getBoundingClientRect();

  node.remove();

  return width;
};

/**
 * Curved Text가 실제로 차지하는 사각형 사이즈를 구합니다.
 * @param param0
 * @returns
 */
export const getCurvedTextAreaSize = ({
  radius,
  angle,
  singleLineHeight,
}: GetCurvedTextCircleSizeArguments) => {
  const width = getChord(radius, Math.min(180, angle), true);
  const height = getSagitta(radius, Math.min(360, angle), true) + singleLineHeight;

  return {
    width,
    height,
  };
};

export const getCurvedTextPlainTextSize = ({
  text,
  fontFamily,
  fontSize,
}: GetCurvedTextPlainTextSizeArguments) => {
  const singleLineText = getSingleLineText(text);

  const node = renderPlainText({ text: singleLineText, fontFamily, fontSize });
  const { width, height } = node.getBoundingClientRect();

  node.remove();

  return { width, height };
};

export const getCurvedTextStartPoint = ({
  curveDirection,
  textAreaCenterX,
  textAreaCenterY,
  textAreaHeight,
  singleLineHeight,
}: GetCurvedTextStartPointArguments) => {
  const y = curveDirection === CURVED_TEXT_DIRECTION_UP ?
    textAreaCenterY - (textAreaHeight - singleLineHeight) / 2 :
    textAreaCenterY + (textAreaHeight - singleLineHeight) / 2;

  return { x: textAreaCenterX, y };
};

export const getCurvedTextCircleCenterPoint = ({
  startPointX,
  startPointY,
  curveDirection,
  radius,
}: GetCurvedTextCircleCenterPointArguments) => {
  return curveDirection === CURVED_TEXT_DIRECTION_UP ?
    { x: startPointX, y: startPointY + radius } :
    { x: startPointX, y: startPointY - radius };
};

export const getCurvedTextStep = (width: number, height: number): number => {
  const ratio = width / height;
  const step = (ratio / 6.0) * (360.0 / 100.0);

  return step;
};

export const convertLetterSpacingToKerning = (letterSpacing: number) => {
  const centerValue = (MIN_KERNING_SLIDER_VALUE + MAX_KERNING_SLIDER_VALUE) / 2;

  if (centerValue < letterSpacing) return (letterSpacing - centerValue) * 2;
  if (centerValue > letterSpacing) return (centerValue - letterSpacing) * -1;
  return 0;
};
