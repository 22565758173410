/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { SwitchCase } from '@lws/common';
import { TemplateStickerObject, TemplateTextObject } from '@lws/types';

import { StickerObject } from '../StickerObject';
import { TextObject } from '../TextObject';
import { ObjectLayerProps } from './ObjectLayer.types';

export const ObjectLayer = ({
  objects = [],
}: ObjectLayerProps) => {
  return (
    <div
      className="ObjectLayer"
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
      `}
    >
      {
        objects.map((object, key) => {
          return (
            <SwitchCase
              key={`${object.parameters.primaryKey}-${key}`}
              value={object.type}
              caseBy={{
                'CLStickerView': <StickerObject object={object as TemplateStickerObject} />,
                'CLTextView': <TextObject object={object as TemplateTextObject} />,
              }}
            />
          );
        })
      }
    </div>
  );
};
