import { Button, css, Typography } from '@mui/material';
import { memo } from 'react';

import { styles } from '~/src/constants/styles';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { ComponentProps } from '~/src/types/common';

import { Icon } from '../../../Icon/v2';

interface OnboardingButtonProps extends ComponentProps {
  disabled?: boolean;
  onNext?: () => void;
  onFinish?: () => void;
}

export const OnboardingModalStepButton = memo((props: OnboardingButtonProps) => {
  const {
    disabled,
    onNext,
    onFinish,
    ...others
  } = props;

  const laptop = useMediaQuery('laptop');

  return (
    <Button
      {...others}
      variant="contained"
      color="primary"
      disabled={disabled}
      fullWidth
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px 14px 24px;
        border-radius: var(--Medium, 8px);
        // TODO: boon-ui 통합 필요
        background: var(--color-Brand-600, #FF8E26);
        text-transform: capitalize;
        &:hover {
          background: var(--color-Brand-600, #FF8E26);
        }
        &:disabled {
          background: var(--color-Gray-100, #D9D9D9);
        }
        ${laptop.down.mq} {
          padding: 14px 16px;
        }
      `}
      disableElevation
      onClick={onFinish ?? onNext}
    >
      <Typography
        css={css`
          ${styles['Text/body_18_sb']};
          margin-right: 8px;
        `}
      >
        {/* // TODO: 다국어 처리 */}
        {onFinish ? 'Finish' : 'Continue'}
      </Typography>
      <Icon
        width={24}
        height={24}
        css={css`
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translate(0, -50%);
          display: flex;
          ${disabled && css`
            path {
              stroke: black;
              stroke-opacity: 0.54;
            }
          `}
        `}
      >
        ic_arrow_right
      </Icon>
    </Button>
  );
});
