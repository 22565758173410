import { useState } from 'react';

import { LoaderProps } from './Loader.types';
import { useDetectElementInViewport } from './useDetectElementInViewport';

export const Loader = ({
  items,
  threshold = 30,
  callback,
}: LoaderProps) => {
  const [loaderElement, setLoaderElement] = useState<HTMLDivElement>();

  useDetectElementInViewport({
    items,
    element: loaderElement,
    callback,
  });

  return (
    <div
      ref={(element) => element && setLoaderElement(element)}
      className="Loader"
      style={{
        display: 'block',
        width: 10,
        height: threshold,
        position: 'absolute',
        left: '50%',
        bottom: 0,
        pointerEvents: 'none',
        opacity: 0,
        transform: 'translateX(-50%)',
      }}
    />
  );
};
