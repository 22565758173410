import { Trans } from 'next-i18next';

import { FAQAccordionContentProps } from './FAQAccordionContent.types';

export const FAQAccordionContent = ({
  i18nextKey,
}: FAQAccordionContentProps) => {
  return (
    <Trans
      i18nKey={i18nextKey}
      components={{
        support: (
          <a
            href="mailto:support@pixo.co"
            target="_blank"
            rel="noreferrer"
          />
        ),
        newline: (
          <>
            <br />
            <br />
          </>
        ),
      }}
    />
  );
};
