import { css, Typography } from '@mui/material';
import { memo } from 'react';

import { styles } from '~/src/constants/styles';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { ComponentProps } from '~/src/types/common';

interface OnboardingModalMetaProps extends ComponentProps {
  title: string;
  description: string;
}

export const OnboardingModalMeta = memo((props: OnboardingModalMetaProps) => {
  const {
    title,
    description,
    ...others
  } = props;

  const laptop = useMediaQuery('laptop');

  return (
    <div
      {...others}
      css={css`
        flex: 1;
      `}
    >
      <Typography
        css={css`
          margin-bottom: 12px;
          // TODO: boon-ui 통합 필요
          color: var(--text-color-primary, #080808);
          ${styles['Title/title_36_sb']}
          ${laptop.down.mq} {
            ${styles['Title/title_32_sb']}
          }
        `}
      >
        {title}
      </Typography>
      <Typography
        css={css`
          // TODO: boon-ui 통합 필요
          color: var(--text-color-contrast, #757575);
          ${styles['Text/body_16_r']};
        `}
      >
        {description}
      </Typography>
    </div>
  );
});
