import { TemplateBackgroundGradient } from '@lws/types';

import { BACKGROUND_TYPE_GRADIENT, BACKGROUND_TYPE_SOLID } from '../../constants/background';

export const getBackgroundDataType = (background: TemplateBackgroundGradient | string) => {
  if (typeof background === 'object') return BACKGROUND_TYPE_GRADIENT;

  try {
    JSON.parse(background);

    return BACKGROUND_TYPE_GRADIENT;
  } catch {
    return BACKGROUND_TYPE_SOLID;
  }
};
