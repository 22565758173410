import { Global } from '@emotion/react';
import { css, useTheme } from '@mui/material';
import { Router } from 'next/router';
import Nprogress from 'nprogress';
import { useEffect } from 'react';

export const RoutingProgress = () => {
  const theme = useTheme();

  useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      Nprogress.start();
    });

    Router.events.on('routeChangeComplete', () => {
      Nprogress.done();
    });

    Router.events.on('routeChangeError', () => {
      Nprogress.remove();
    });
  }, []);

  return (
    <Global
      styles={css`
        #nprogress {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 10000;
          .bar {
            background-color: ${theme.app.colors['primary-color-01']} !important;
            height: 3px;
            background-color: skyblue;
          }
        }
      `}
    />
  );
};
