import { css } from '@emotion/react';
import { Modal } from '@mui/material';
import { useEffect } from 'react';

import { LottieLoadingIndicator } from '~/src/components/app/LottieLoadingIndicator';
import { ComponentProps } from '~/src/types/common';

export interface LoadingOverlayProps {
  active?: boolean;
  closeClickModal?: boolean;
  modal?: ComponentProps;
  onOpen?: () => void;
  onClose?: () => void;
}

export const LoadingOverlay = (props: LoadingOverlayProps) => {
  const active = props.active ?? false;

  useEffect(() => {
    if (props.active) props.onOpen && props.onOpen();
  }, [
    props,
  ]);

  return (
    <Modal
      open={active}
      onClose={props.onClose}
      { ...props.modal }
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          width: 100%;
          height: 100%;
        `}
      >

        <LottieLoadingIndicator
          type="ball-balance"
          style={{width: '50px', height: '50px', margin: 'auto'}}
        />
      </div>
    </Modal>
  );
};
