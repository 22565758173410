import { css, Typography } from '@mui/material';

import { ComponentProps } from '~/src/types/common';

import { Nl2br } from '../../../Nl2br';

type OnboardingRequestTitleProps = ComponentProps;

export const OnboardingRequestTitle = (props: OnboardingRequestTitleProps) => {
  return (
    <Typography
      {...props}
      css={css`
        // TODO: boon-ui 통합 필요
        color: var(--text-color-primary, #080808);
        text-align: center;

        margin-bottom: 16px;
      `}
    >
      {/* // TODO: 다국어 처리 */}
      <Nl2br>
        {'Do you have a logo you\'re looking for?'}
      </Nl2br>
    </Typography>
  );
};
