import classNames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef, HTMLAttributes, ReactNode, Ref, useEffect, useState } from 'react';

export interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  href: string;
  children?: ReactNode;
  exact?: boolean;
  target?: string;
  shallow?: boolean;
}

export const Link = forwardRef(({ href, exact, ...others }: LinkProps, ref: Ref<HTMLAnchorElement>) => {
  const router = useRouter();

  const [isCurrent, setIsCurrent] = useState<boolean>(false);

  useEffect(()=>{
    const isCurrent: boolean = (() => {
      if (exact) {
        return router.asPath === href;
      } else {
        return router.asPath.includes(href);
      }
    })();

    setIsCurrent(isCurrent);
  }, [href, exact, router]);

  return (
    <NextLink
      { ...others }
      ref={ref}
      className={classNames(
        'Link',
        { 'Link--Active': isCurrent },
        others.className
      )}
      href={href}
    >
      { others.children }
    </NextLink>
  );
});
