import { css, Dialog as MuiDialog } from '@mui/material';
import { ReactNode } from 'react';

import { ComponentProps } from '~/src/types/common';

import { DialogContainer } from '../DialogContainer';

export interface DialogProps extends ComponentProps {
  open: boolean;
  showModal?: boolean;
  children?: ReactNode;
  showCloseButton?: boolean;
  onClose?: () => any;
}

export const Dialog = (props: DialogProps) => {
  return (
    <MuiDialog
      className={props.className}
      style={props.style}
      open={props.open}
      onClose={props.onClose}
      css={css`
        .MuiDialog-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          overflow: auto;
          .MuiPaper-root {
            overflow: visible;
            margin: auto;
          }
        }
      `}
    >
      {
        <DialogContainer
          onClose={props.onClose}
          showCloseButton={props.showCloseButton}
        >
          { props.children }
        </DialogContainer>
      }
    </MuiDialog>
  );
};
