import { Fragment } from 'react';

import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { useReviews } from '~/src/hooks/v1/Common/useReviews';

import { GridReviewList } from './GridReviewList';
import { SwiperReviewList } from './SwiperReviewList';
import { UsersReviewListProps } from './UsersReviewList.types';

export const UsersReviewList = ({
  reviews: reviewsWithoutAvatar,
}: UsersReviewListProps) => {
  const mobile = useMediaQuery('mobile');

  const { reviews } = useReviews({
    reviews: reviewsWithoutAvatar,
  });

  return (
    <Fragment>
      {
        !mobile.only.is
          ? <GridReviewList reviews={reviews} />
          : <SwiperReviewList reviews={reviews} />
      }
    </Fragment>
  );
};
