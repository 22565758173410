import { css, Typography } from '@mui/material';

import { styles } from '~/src/constants/styles';

import { Nl2br } from '../../../Nl2br';

export const OnboardingRequestDescription = () => {
  return (
    <Typography
      css={css`
        color: var(--text-color-contrast, #757575);
        text-align: center;
        ${styles['Text/body_16_r']};
      `}
    >
      {/* // TODO: 다국어 처리 */}
      <Nl2br>
        {'Please let us know which logo\nyou want and we will help you quickly.'}
      </Nl2br>
    </Typography>
  );
};
