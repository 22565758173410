import { LottieLoadingIndicator } from '../LottieLoadingIndicator';
import { LottieLoadingOverlayProps } from './LottieLoadingOverlay.types';

export const LottieLoadingOverlay = ({
  indicatorType = 'ball-balance',
  indicatorWidth = 64,
  indicatorHeight = 64,
}: LottieLoadingOverlayProps) => {
  return (
    <div
      className="LottieLoadingOverlay"
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        width: '100%',
        height: '100%',
      }}
    >
      <LottieLoadingIndicator
        type={indicatorType}
        style={{
          width: indicatorWidth,
          height: indicatorHeight,
        }}
      />
    </div>
  );
};
