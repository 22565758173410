import { useContext } from 'react';

import { OnboardingContext } from '../../OnboardingProvider/OnboardingProvider';

export const useOnboardingController = () => {
  const onboardingRequestContext = useContext(OnboardingContext);

  if (onboardingRequestContext === undefined) throw new Error('OnboardingRequestProvider is not defined.');

  const {
    onboardingRequestPopup,
    onboardingModal,
    onboardingExitDialog,
    updateOnboardingStep,
    onStartOnboarding,
    onShowOnboardingRequestPopup,
  } = onboardingRequestContext;

  return {
    onboardingRequestPopup,
    onboardingModal,
    onboardingExitDialog,
    updateOnboardingStep,
    onStartOnboarding,
    onShowOnboardingRequestPopup,
  };
};
