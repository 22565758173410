import { useMemo } from 'react';

import { UseAlignmentArguments } from './useAlignment.types';

export const useAlignment = ({
  object,
}: UseAlignmentArguments) => {
  const alignment = useMemo(() => {
    if (object?.textAlignment === 0) return 'left';
    if (object?.textAlignment === 2) return 'right';
    return 'center';
  }, [object?.textAlignment]);

  const alignmentMarginSpace = useMemo(() => {
    if (
      object.frameWidth === undefined ||
      object.scale === undefined ||
      object.parameters.singleLineHeight === undefined
    ) return 0;

    const space = (object.parameters.singleLineHeight / .6 - 10);

    if (alignment === 'left') return -space;
    if (alignment === 'right') return space;
    return 0;
  }, [alignment, object]);

  return {
    alignment,
    alignmentMarginSpace,
  };
};
