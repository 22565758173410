import { MIXED_OBJECT_TYPE, NONE_OBJECT_TYPE, STICKER_OBJECT_TYPE, TEXT_OBJECT_TYPE } from '@lws/common';
import { TemplateObject, TemplateTextObject } from '@lws/types';
import { createSelector } from '@reduxjs/toolkit';

import { TextObjectManager } from '~/src/helpers/TextObjectManager';
import { OBJECT_TYPE } from '~/src/types/editor-policy';

import { APP_STATE } from '../../types';
import { ObjectAdapter } from './slice';

export const objectsSlicePresentSelector =
  (state: APP_STATE) =>
    state.editor.undoable.present.objects;

/**
 * Object Adapter Selectors
 */
const objectsSelectors = ObjectAdapter.getSelectors();

/**
 * 오브젝트 전체를 반환하는 Selector
 */
export const allObjectsSelector = createSelector(
  objectsSlicePresentSelector,
  (state) => {
    return objectsSelectors.selectAll(state);
  }
);

export const preselectedObjectsSelector = createSelector(
  objectsSlicePresentSelector,
  (state) => state.preselectedObjects
);

/**
 * 오브젝트 id와 일치하는 요소 하나를 불러오는 Selector
 */
export const objectFromIdSelector = (objectId: string) => createSelector(
  objectsSlicePresentSelector,
  (state) => {
    return objectsSelectors.selectById(state, objectId);
  }
);

/**
 * 선택한 오브젝트를 불러오는 Selector
 */
export const selectedObjectsSelector = createSelector(
  objectsSlicePresentSelector,
  (state) => {
    const result: TemplateObject[] = [];
    const ids = state.selectedObjects ?? [];

    ids.forEach((id) => {
      const target = objectsSelectors.selectById(state, id);

      target && result.push(target);
    });

    return result;
  }
);

/**
 * 선택한 오브젝트 하나만 불러오는 Selector
 */
export const firstSelectedObjectSelector = createSelector(
  objectsSlicePresentSelector,
  (state) => {
    const id = state.selectedObjects.at(0);

    if (id === undefined) return undefined;

    return objectsSelectors.selectById(state, id);
  }
);

/**
 * 선택한 오브젝트의 타입을 불러오는 Selector
 */
export const selectedObjectTypeSelector = createSelector(
  objectsSlicePresentSelector,
  (state) => {
    const ids = state.selectedObjects;
    let type: OBJECT_TYPE = NONE_OBJECT_TYPE;

    for (const id of ids) {
      const object = state.entities[id];

      if (object === undefined) continue;

      if (type !== object.type && type !== NONE_OBJECT_TYPE) {
        type = MIXED_OBJECT_TYPE;
        break;
      }

      switch(object.type) {
      case STICKER_OBJECT_TYPE:
        type = STICKER_OBJECT_TYPE;
        break;

      case TEXT_OBJECT_TYPE:
        type = TEXT_OBJECT_TYPE;
        break;
      }
    }

    return type;
  }
);

/**
 * 템플릿에 적용 된 모든 오브젝트 중에서 텍스트 오브젝트에 적용 된 폰트 이름만을 추출합니다.
 */
export const fontNamesSelector = createSelector(
  objectsSlicePresentSelector,
  ({ ids, entities }) => {
    const fontNames: string[] = [];

    for (const id of ids) {
      const object = entities[id];

      if (object?.type === 'CLTextView') {
        const { fontName } = new TextObjectManager(object as TemplateTextObject);
        const isUnique = fontName ? !fontNames.includes(fontName) : true;

        if (fontName && isUnique) fontNames.push(fontName);
      }
    }

    return fontNames;
  }
);

export const selectedObjectFromIdSelector = (uniqueId: string) => createSelector(
  objectsSlicePresentSelector,
  (state) => {
    const obj = objectsSelectors.selectById(state, uniqueId);
    const selectedId = state.selectedObjects.indexOf(uniqueId);

    if (selectedId === -1) return undefined;
    return obj;
  }
);
