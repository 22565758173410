import { LGSHomePolicy } from '~/src/types/home-policy';
import { LGSEditorPolicy } from '~/src/types/policy';

import { APIClient } from '../APIClient';
import { ProductPlan } from './PolicyAPI.types';

export class PolicyAPI extends APIClient {
  getHome() {
    return this
      .axios
      .get<LGSHomePolicy>(
        `/${this.appName}/home/policy`,
        {
          headers: {
            /** 국제화 지원 전 까지는 영어 고정 */
            'Accept-Language': 'en-US',
          },
        }
      ).then((response) => {
        return response;
      });
  }

  getEditor() {
    return this
      .axios
      .get<LGSEditorPolicy>(
        `/${this.appName}/editor/policy`,
        {
          headers: {
            /** 국제화 지원 전 까지는 영어 고정 */
            'Accept-Language': 'en-US',
          },
        }
      ).then((response) => {
        return response;
      });
  }

  getPlans() {
    interface PlansResponse {
      plans: ProductPlan[];
    }

    return this.axios.get<PlansResponse>(`/${this.appName}/payments/policy`);
  }
}

export const policyAPIClient = new PolicyAPI;

export default policyAPIClient;
