import { useQuery } from '@tanstack/react-query';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { useEffect, useRef } from 'react';

import { AuthAPI } from '~/src/api/common/AuthAPI';
import { ClientError, ExpiredError } from '~/src/errors/ClientErrors';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { UserPayload } from '~/src/types/auth';

interface AuthCookies {
  idToken: string;
  accessToken: string;
  expiresIn: number;
  tokenType: string;
}

const cookieOptions = {
  path: '/',
};

const setAuthCookies = ({
  idToken,
  accessToken,
  expiresIn,
  tokenType,
}: AuthCookies) => {
  setCookie('id-token', idToken, cookieOptions);
  setCookie('access-token', accessToken, cookieOptions);
  setCookie('expires-in', expiresIn, cookieOptions);
  setCookie('token-type', tokenType, cookieOptions);
};

const clearAuthCookies = () => {
  deleteCookie('id-token', cookieOptions);
  deleteCookie('access-token', cookieOptions);
  deleteCookie('expires-in', cookieOptions);
  deleteCookie('token-type', cookieOptions);
};

export const useAuth = () => {
  const authAPI = useRef<AuthAPI>();
  const idToken = getCookie('id-token', cookieOptions);
  const refreshToken = getCookie('refresh-token', cookieOptions);

  useEffect(() => {
    authAPI.current = new AuthAPI(location.protocol + '//' + location.host);
  }, []);

  const query = useQuery({
    queryKey: ['user', idToken],
    queryFn: async () => {
      try {
        const user: Partial<UserPayload> = typeof idToken === 'string'
          ? await authAPI.current?.getMe(idToken)
          : undefined;

        AmplitudeTaxonomyClient.setUserProperties({
          userId: user.userId,
          login_status: true,
          login_type: user.provider,
        });

        return user;
      } catch (err) {
        try {
          const tokens = typeof refreshToken === 'string'
            ? await authAPI.current?.refreshTokens(refreshToken)
            : undefined;

          if (tokens === undefined) throw new ExpiredError(['refreshToken']);

          setAuthCookies({
            idToken: tokens.id_token,
            accessToken: tokens.access_token,
            expiresIn: tokens.expires_in,
            tokenType: tokens.token_type,
          });

          query.refetch();
        } catch (err) {
          /**
           * id-token이 만료 되고 refresh-token마저 만료 되었을 경우 로그아웃이 제대로 되는지 확인하기 위한 구분점.
           */
          if (err instanceof ClientError) {
            console.log(err);
          }
          clearAuthCookies();

          AmplitudeTaxonomyClient.setUserProperties(undefined);

          return undefined;
        }
      }
    },
    enabled: Boolean(idToken),
  });

  return {
    isInitialLoading: query.isInitialLoading,
    isError: query.isError,
    isSuccess: query.isSuccess,
    ...query.data,
  };
};
