import { useContext } from 'react';

import { PurchaseWindowContext } from './PurchaseWindowProvider';

export const usePurchaseWindowContext = () => {
  const context = useContext(PurchaseWindowContext);

  if (context === undefined) throw new Error('PurchaseWindowContext is undefined.');

  return {
    ...context,
  };
};
