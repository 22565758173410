import { css, Grid, IconButton } from '@mui/material';
import { memo } from 'react';

import { Logo } from '~/src/components/business/Logo';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { ComponentProps } from '~/src/types/common';

import { Icon } from '../../../Icon/v2';

interface OnboardingModalHeaderProps extends ComponentProps {
  onPrev?: () => void;
  onExit?: () => void;
}

export const OnboardingModalHeader = memo((props: OnboardingModalHeaderProps) => {
  const {
    onPrev,
    onExit,
    ...others
  } = props;

  const laptop = useMediaQuery('laptop');

  return (
    <div
      {...others}
      css={css`
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 56px;
        padding-right: 56px;
        background-color: white;
        z-index: 1;
        ${laptop.down.mq} {
          padding-left: 20px;
          padding-right: 20px;
        }
      `}
    >
      <Grid
        container
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Grid
          item
          desktop={1}
          display={'flex'}
          justifyContent={'flex-start'}
          marginLeft={'-8px'}
        >
          {onPrev && (
            <IconButton
              onClick={onPrev}
            >
              <Icon
                width={24}
                height={24}
              >
                ic_arrow_left
              </Icon>
            </IconButton>
          )}
        </Grid>
        <Grid
          item
          desktop={10}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Logo
            imageProps={{
              css: css`
                width: auto;
                height: 32px;
              `,
            }}
          />
        </Grid>
        <Grid
          item
          desktop={1}
          display={'flex'}
          justifyContent={'flex-end'}
          marginRight={'-8px'}
        >
          {onExit && (
            <IconButton
              onClick={onExit}
            >
              <Icon
                width={24}
                height={24}
              >
                ic_button_close
              </Icon>
            </IconButton>
          )}
        </Grid>
      </Grid>
    </div>
  );
});
