export const BUTTON_TEST_ID = 'Button';
export const BUTTON_LOADING_TEST_ID = 'Button__Loading';
export const BUTTON_PREFIX_ICON_TEST_ID = 'Button__Icon--Prefix';
export const BUTTON_SUFFIX_ICON_TEST_ID = 'Button__Icon--Suffix';

export const BUTTON_SIZE_SMALL = 'small';
export const BUTTON_SIZE_MEDIUM = 'medium';
export const BUTTON_SIZE_LARGE = 'large';
export const BUTTON_SIZE_X_LARGE = 'x-large';

export const BUTTON_COLOR_PRIMARY = 'primary';
export const BUTTON_COLOR_SECONDARY = 'secondary';
export const BUTTON_COLOR_MONO = 'mono';

export const BUTTON_RADIUS_SQUARE = 'square';
export const BUTTON_RADIUS_ROUND = 'round';

export const BUTTON_VARIANT_CONTAINED = 'contained';
export const BUTTON_VARIANT_OUTLINE = 'outlined';
export const BUTTON_VARIANT_TEXT = 'text';

export const BUTTON_STATUS_ENABLE = 'enable';
export const BUTTON_STATUS_HOVER = 'hover';
export const BUTTON_STATUS_SELECT = 'select';
export const BUTTON_STATUS_DISABLE = 'disable';
export const BUTTON_STATUS_PRESSED = 'pressed';

export const BUTTON_DEFAULT_PREFIX_ICON_WIDTH = 24;
export const BUTTON_DEFAULT_PREFIX_ICON_HEIGHT = 24;
export const BUTTON_DEFAULT_SUFFIX_ICON_WIDTH = 20;
export const BUTTON_DEFAULT_SUFFIX_ICON_HEIGHT = 20;
