

import { Action, configureStore, createAction, ThunkAction } from '@reduxjs/toolkit';
import { deserialize, serialize } from 'json-immutable';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { homePolicySlice } from '~/src/store/home-policy/HomePolicySlice';

import { browserSlice } from './browser/BrowserSlice';
import { EditorReducer } from './editor/EditorReducer';
import { myWorkSlice } from './my-work/MyWorkSlice';

export const makeStore = () => {
  const store = configureStore({
    reducer: {
      myWork: myWorkSlice.reducer,
      editor: EditorReducer,
      homePolicy: homePolicySlice.reducer,
      browser: browserSlice.reducer,
    },
    devTools: true,
  });

  return store;
};

export type APP_STORE = ReturnType<typeof makeStore>;
export type APP_STATE = ReturnType<APP_STORE['getState']>;
export type APP_THUNK<ReturnType = void> = ThunkAction<ReturnType, APP_STATE, unknown, Action>;

export const hydrate = createAction<APP_STATE>(HYDRATE);

export const wrapper = createWrapper(makeStore, {
  debug: false, // process.env.NODE_ENV === 'development' || false,
  serializeState: (state) => {
    try {
      return serialize(state);
    } catch {
      return state;
    }
  },
  deserializeState: (state) => {
    try {
      return deserialize(state);
    } catch {
      return state;
    }
  },
});

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<APP_STATE> = useSelector;

export type AppDispatch = ReturnType<typeof makeStore>['dispatch'];
