/** @jsxImportSource @emotion/react */

import { getTextType, SwitchCase } from '@lws/common';

import { TEXT_OBJECT_CURVED_TYPE, TEXT_OBJECT_PLAIN_TYPE } from '../../constate';
import { CurvedText } from './CurvedText';
import { ObjectPositioner } from './ObjectPositioner';
import { Text } from './Text';
import { TextObjectProps } from './TextObject.types';
import { TextStyler } from './TextStyler';

export const TextObject = ({
  object,
}: TextObjectProps) => {
  const textType = getTextType(object);

  return (
    <ObjectPositioner
      object={object}
    >
      <SwitchCase
        value={textType}
        caseBy={{
          [TEXT_OBJECT_PLAIN_TYPE]: (
            <TextStyler
              object={object}
            >
              <Text
                object={object}
              />
            </TextStyler>
          ),
          [TEXT_OBJECT_CURVED_TYPE]: (
            <CurvedText
              object={object}
            />
          ),
        }}
      />
    </ObjectPositioner>
  );
};
