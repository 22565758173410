import { useCallback, useEffect, useRef } from 'react';

import { UsePreviewerResizeArguments } from './usePreviewerResize.types';

export const usePreviewerResize = ({
  containerElement,
  previewerElement,
  deps,
}: UsePreviewerResizeArguments) => {
  const observer = useRef<ResizeObserver>();

  const resizePreviewerElement = useCallback((
    containerElement: HTMLElement,
    previewerElement: HTMLElement
  ) => {
    const {
      clientWidth: targetWidth,
      clientHeight: targetHeight,
    } = previewerElement;

    const containerTargetWidth = containerElement?.clientWidth;
    const containerTargetHeight = containerElement?.clientHeight;

    const targetSize = Math.max(targetWidth, targetHeight);
    const containerTargetSize = Math.max(containerTargetWidth, containerTargetHeight);
    const scale = containerTargetSize / targetSize;

    if (previewerElement.style) {
      previewerElement.style.setProperty('position', 'absolute');
      previewerElement.style.setProperty('transform', `scale(${scale}) translateX(-50%) translateY(-50%)`);
      previewerElement.style.setProperty('left', '50%');
      previewerElement.style.setProperty('top', '50%');
    }
  }, []);

  const observeContainerElement = useCallback<ResizeObserverCallback>((entries) => {
    entries.forEach((entry) => {
      if (containerElement && entry.target) {
        resizePreviewerElement(containerElement, entry.target as HTMLElement);
      }
    });
  }, [
    containerElement,
    resizePreviewerElement,
  ]);

  useEffect(() => {
    if (observer.current === undefined) {
      observer.current = new ResizeObserver(observeContainerElement);
    }

    if (previewerElement) {
      observer.current.observe(previewerElement);
    }

    return () => {
      if (observer.current && previewerElement) {
        observer.current?.unobserve(previewerElement);
      }
    };
  }, [
    containerElement,
    previewerElement,
    observeContainerElement,
  ]);

  useEffect(() => {
    if (containerElement && previewerElement) {
      resizePreviewerElement(containerElement, previewerElement);
    }
  }, [
    containerElement,
    previewerElement,
    resizePreviewerElement,
    deps,
  ]);
};
