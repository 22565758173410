import { createContext, useEffect, useState } from 'react';

import { RendererContextValues, RendererProviderProps } from './RendererProvider.types';

export const RendererContext = createContext<RendererContextValues | undefined>(undefined);

export const RendererProvider = ({
  width: widthProp,
  height: heightProp,
  children,
}: RendererProviderProps) => {
  const [width, setWidth] = useState(widthProp);
  const [height, setHeight] = useState(heightProp);

  useEffect(() => setWidth(widthProp), [widthProp]);
  useEffect(() => setHeight(heightProp), [heightProp]);

  return (
    <RendererContext.Provider
      value={{ width, height }}
    >
      { children }
    </RendererContext.Provider>
  );
};
