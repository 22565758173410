import { css } from '@emotion/react';
import { styled } from '@mui/material';
import classNames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';

import { ComponentProps } from '~/src/types/common';

import { Icon } from '../Icon/v2';


interface DialogContainerProps extends ComponentProps {
  children?: ReactNode;
  showCloseButton?: boolean;
  onClose?: () => void;
}

export const DialogContainer = (props: DialogContainerProps) => {
  return (
    <div
      className={classNames(
        'DialogContainer',
        props.className
      )}
      style={props.style}
      css={css`
        background-color: #FFFFFF;
        padding: 0;
      `}
    >
      <DialogHeader
        showCloseButton={props.showCloseButton}
        onClose={props.onClose}
      />
      <DialogBody
        css={css`
          margin-top: 6px;
        `}
      >
        { props.children }
      </DialogBody>
    </div>
  );
};

interface DialogHeaderProps extends ComponentProps {
  showCloseButton?: boolean;
  onClose?: () => void;
}

const DialogHeaderContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 20px 16px;
`;

const DialogHeader = (props: DialogHeaderProps) => {
  const showCloseButton = props.showCloseButton ?? true;

  return (
    <DialogHeaderContainer
      className={classNames(
        'Dialog__Header',
        props.className
      )}
      style={props.style}
    >
      {
        showCloseButton &&
          <CloseButton
            onClick={props.onClose}
          />
      }
    </DialogHeaderContainer>
  );
};

/** 닫기 버튼 */
interface CloseButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const CloseButton = (props: CloseButtonProps) => {
  return (
    <button
      className="Dialog__CloseButton"
      onClick={props.onClick}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: transparent;
        border: none;
        width: 24px;
        height: 24px;
      `}
    >
      <Icon
        width={20}
        height={20}
      >
        ic_close_default
      </Icon>
    </button>
  );
};

interface DialogBodyProps extends ComponentProps {
  children?: ReactNode;
}

const DialogBodyContainer = styled('div')`
  width: 100%;
`;

const DialogBody = (props: DialogBodyProps) => {
  return (
    <DialogBodyContainer
      className={classNames(
        'Dialog__Body',
        props.className
      )}
    >
      { props.children }
    </DialogBodyContainer>
  );
};
