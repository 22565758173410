import { css } from '@emotion/react';

import { Icon } from '~/src/components/app/Icon/v2';
import { Logo } from '~/src/components/business/Logo';

interface PurchaseDialogHeaderProps {
  onClickCloseIcon?: () => void;
}

export const PurchaseWindowHeader = (props: PurchaseDialogHeaderProps) => {
  const { onClickCloseIcon } = props;

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 50%;
          left: 24px;
          transform: translate(0, -50%);
          display: flex;
          cursor: pointer;
        `}
        onClick={onClickCloseIcon}
      >
        <Icon
          width={24}
          height={24}
        >
          ic_close_arrow
        </Icon>
      </div>
      <Logo
        width={218}
        height={33}
      />
    </div>
  );
};
