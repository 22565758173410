import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { RebrandModal } from './RebrandModal';
import { RebrandModalContextArguments } from './RebrandModalProvider.types';

export const RebrandModalContext = createContext<RebrandModalContextArguments | undefined>(undefined);

const LOCALSTORAGE_KEY = 'last-closed-rebrand-modal';

/**
 * 접속한 유저가 어떤 방식으로든 Modal을 닫게 되면 다음부터는 표시 되지 않습니다.
 */
export const RebrandModalProvider = ({
  children,
}: PropsWithChildren) => {
  const [show, setShow] = useState(false);

  const close = useCallback(() => {
    localStorage.setItem(LOCALSTORAGE_KEY, (new Date()).toISOString());
    setShow(false);
  }, []);

  useEffect(() => {
    const hasClosed = localStorage.getItem(LOCALSTORAGE_KEY);

    if (hasClosed === null) {
      setShow(true);
    }
  }, []);

  return (
    <RebrandModalContext.Provider
      value={{
        show,
        close,
      }}
    >
      <RebrandModal
        show={show}
        onClose={close}
      />
      { children }
    </RebrandModalContext.Provider>
  );
};
