import Url from 'url-parse';

export class URIParser {
  static getResource(scheme: string) {
    const url = new Url(scheme);
    const { protocol, auth, host, pathname } = url;

    if (!protocol.includes('pixo-cms')) throw new Error('There is no pixo-cms URI in Protocol data');
    if (auth !== 'resource') throw new Error('It is not Resource data.');
    if (host === 'embedded') {
      const [type, fontName] = pathname.split('/').filter((item) => item);

      if (type === 'font') return fontName;
    }
  }
  static getCategoryId(value: string) {
    const url = new Url(value);
    const pathname = url.pathname.split('/')[1];

    return pathname;
  }
}
