import { isColor, parsePixoAppURI } from '@lws/common';

import { GetFillModeStickerObjectArguments, GetStickerObjectTextureSizeArguments } from './types';

export const getFillModeStickerObject = ({
  color,
  decorators,
}: GetFillModeStickerObjectArguments) => {
  if (color && isColor(color)) return 'color';
  if (decorators && getTextureResourceFromDecorators(decorators)) return 'texture';

  return 'none';
};

export const getImageIdFromContentId = (contentId: string) => {
  const { type, id } = parsePixoAppURI(contentId);

  if (type === 'symbol') return id;

  return undefined;
};

export const getStickerObjectTextureSize = ({
  objectWidth,
  objectHeight,
  textureWidth,
  textureHeight,
  maskScale,
}: GetStickerObjectTextureSizeArguments) => {
  let resultWidth = objectWidth;
  let resultHeight = objectHeight;

  if (objectWidth >= objectHeight) {
    resultWidth = objectWidth * maskScale;
    resultHeight = objectWidth * (textureHeight / textureWidth) * maskScale;
  } else {
    resultWidth = objectHeight * (textureWidth / textureHeight) * maskScale;
    resultHeight = objectHeight * maskScale;
  }

  return {
    width: resultWidth,
    height: resultHeight,
  };
};


export const getTextureResourceFromDecorators = (decorators: string[]) => {
  for (const decorator of decorators) {
    const { type, id } = parsePixoAppURI(decorator);

    if (type === 'texture') return id;
  }

  return undefined;
};


export const isSVG = (fileURI: string): Promise<boolean> => {
  return new Promise((resolve) => {
    return fetch(fileURI)
      .then((res) => {
        const { headers } = res;
        const contentType = headers.get('Content-Type');

        resolve(contentType?.includes('svg') ?? false);
      })
      .catch(() => {
        resolve(false);
      });
  });
};
