import { API_METHOD_GET } from '~/src/constants/API';
import { NewTokenResponsePayload } from '~/src/utils/cognito';

import { InternalAPI } from '../InternalAPI';
import { GetTokensParams } from './SigninAPI.types';

export class AuthAPI extends InternalAPI {
  getTokens({ code, redirectUri }: GetTokensParams) {
    const queries = new URLSearchParams({
      code,
      redirectUri,
    });
    return this.post(
      `/api/auth/signin?${queries}`
    );
  }

  refreshTokens(refreshToken: string): Promise<NewTokenResponsePayload> {
    const queries = new URLSearchParams({
      refreshToken,
    });
    return this.post(
      `/api/auth/tokens?${queries}`
    );
  }

  getMe(idToken: string) {
    return this.get(
      '/api/auth/me',
      {
        method: API_METHOD_GET,
        headers: {
          'Authorization': `Bearer ${idToken}`,
        },
      }
    );
  }
}
