import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled
} from '@mui/material';

import { DialogProps } from './Modal.types';

export const Dialog = ({
  className,
  style,
  sx,
  open,
  title,
  message,
  actions,
  onClose,
}: DialogProps) => {
  return (
    <MuiDialog
      className={className}
      style={style}
      open={open}
      sx={{
        ...sx,
        '.MuiPaper-root': {
          borderRadius: '16px',
        },
      }}
      onClose={onClose}
    >
      <AlertTitle>
        { title }
      </AlertTitle>
      <AlertContent>
        { message }
      </AlertContent>
      <AlertActions>
        { actions }
      </AlertActions>
    </MuiDialog>
  );
};

const AlertTitle = styled(DialogTitle)`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  padding: 32px 24px 0;
`;

const AlertContent = styled(DialogContent)`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #555555;
  padding: 12px 24px 24px !important;
`;

const AlertActions = styled(DialogActions)`
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 8px;
  padding: 0 24px 24px;
  & > * {
    margin-left: 0 !important;
  }
`;
