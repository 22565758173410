import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StickerObjectManager } from '~/src/helpers/StickerObjectManager';
import { Rect } from '~/src/types/editor-policy';

import { APP_STATE } from '../../types';
import { addStickerObjectThunk, overwriteMyWorkFromTemplateThunk } from '../thunk/EditorThunk';

const initialState = {
  rect: undefined as Rect | undefined,
  angle: 0,
};

export type ControllerSliceState = typeof initialState;

export const controllerSlice = createSlice({
  name: 'ControllerSlice',
  initialState,
  reducers: {
    initializeRect: (state, action: PayloadAction<Rect | undefined>) => {
      state.rect = action.payload;
    },
    initializeAngle: (state, action: PayloadAction<number>) => {
      state.angle = action.payload;
    },
    setRect: (state, action: PayloadAction<Rect | undefined>) => {
      state.rect = action.payload;
    },
    setAngle: (state, action: PayloadAction<number>) => {
      state.angle = action.payload;
    },
    reset: (state) => {
      state.angle = 0;
      state.rect = undefined;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addStickerObjectThunk.fulfilled, (state, action) => {
        const object =
          new StickerObjectManager(action.payload.object)
            .setCanvasWidth(action.payload.canvasWidth)
            .setCanvasHeight(action.payload.canvasHeight);

        const rect = object.getZeroAngleRect();

        state.rect = rect;
        state.angle = 0;
      })
      .addCase(overwriteMyWorkFromTemplateThunk.fulfilled, (state) => {
        state.rect = undefined;
        state.angle = 0;
      });
  },
});

const controllerSliceSelector = (state: APP_STATE) => state.editor.undoable.present.controller;

export const controllerRectSelector = createSelector(controllerSliceSelector, (state) => state.rect);
export const controllerAngleSelector = createSelector(controllerSliceSelector, (state) => state.angle);
