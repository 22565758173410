import { useQuery } from '@tanstack/react-query';
import { createContext, PropsWithChildren } from 'react';

import { policyAPIClient } from '~/src/api/v1/PolicyAPI';

import { PlansContextArguments } from './PlansProvider.types';
import { useLocalizedPlans } from './useLocalizedPlans';

export const PlansContext = createContext<PlansContextArguments | undefined>(undefined);

export const PlansProvider = ({ children }: PropsWithChildren) => {
  const query = useQuery({
    queryKey: ['plans'],
    queryFn: async () => (await policyAPIClient.getPlans()).data.plans,
  });

  const {
    isLoading,
    isFetching,
    isSuccess,
    isFetched,
    isError,
    data,
  } = query;

  const { localizedPlans } = useLocalizedPlans(data ?? []);

  return (
    <PlansContext.Provider
      value={{
        isLoading,
        isFetched,
        isSuccess,
        isFetching,
        isError,
        plans: data,
        localizedPlans: localizedPlans,
      }}
    >
      { children }
    </PlansContext.Provider>
  );
};
