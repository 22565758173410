import { combineReducers, createSelector } from '@reduxjs/toolkit';
import undoable, { excludeAction } from 'redux-undo';

import { batchGroupBy } from '../helpers/Batch';
import { APP_STATE } from '../types';
import { controllerSlice } from './controller/slice';
import { editObjectsSlice } from './edit-objects/slice';
import { objectsSlice } from './objects/slice';
import { resourceMetaSlice } from './resource-meta/slice';
import { resourcesSlice } from './resources/slice';
import { templateSlice } from './template/slice';
import { zoomSlice } from './zoom/slice';

const UndoableReducer = undoable(combineReducers({
  template: templateSlice.reducer,
  objects: objectsSlice.reducer,
  controller: controllerSlice.reducer,
}), {
  limit: 50,
  groupBy: batchGroupBy.init([]),
  filter: excludeAction([
    controllerSlice.actions.initializeRect.type,
    controllerSlice.actions.initializeAngle.type,
    templateSlice.actions.initialize.type,
    objectsSlice.actions.initialize.type,
    objectsSlice.actions.initializeSelectedObjects.type,
    objectsSlice.actions.setPreselectedObjectsAll.type,
    zoomSlice.actions.set.type,
  ]),
});

export const EditorReducer = combineReducers({
  undoable: UndoableReducer,
  editObjects: editObjectsSlice.reducer,
  zoom: zoomSlice.reducer,
  resources: resourcesSlice.reducer,
  meta: resourceMetaSlice.reducer,
});

const undoableReducerSelector = (state: APP_STATE) => state.editor.undoable;

export const pastEditorSelector = createSelector(undoableReducerSelector, (state) => state.past);
export const futureEditorSelector = createSelector(undoableReducerSelector, (state) => state.future);
