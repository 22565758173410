import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { ComponentProps } from '~/src/types/common';

import { UsersReviewList } from '../../../UsersReviewList';

type PlanReviewProps = ComponentProps;

export const PlanReview = (props: PlanReviewProps) => {
  const { t } = useTranslation();
  const laptop = useMediaQuery('laptop');
  const mobile = useMediaQuery('mobile');

  return (
    <article
      className={classNames(
        'PlanReview',
        props.className
      )}
      data-testid="PlanReview"
      css={css`
        overflow: hidden;
        text-align: center;
      `}
    >
      <Typography
        variant={laptop.down.is ? 'h4' : 'h3'}
        data-testid="PlanReview__Title"
        css={css`
          max-width: 700px;
          margin: auto;
          line-height: 33px;
        `}
      >
        {t('common:review.title')}
      </Typography>
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          column-gap: 24px;
          row-gap: 24px;
          margin-top: 60px;
          ${laptop.down.mq} {
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin-top: 35px;
            overflow-x: scroll;
          }
          ${mobile.only.mq} {
            width: 100vw;
          }
        `}
      >
        <UsersReviewList
          reviews={t('common:review.reviews', { returnObjects: true })}
        />
      </div>
    </article>
  );
};
