import { createSelector } from '@reduxjs/toolkit';

import { APP_STATE } from '../../types';

const templateSelector = (state: APP_STATE) => state.editor.undoable.present.template.data;

/**
 * 템플릿 전체 데이터를 선택하는 Selector
 */
export const templateDataSelector = createSelector(templateSelector, (state) => state);

/**
 * 디바이스 Scale을 선택하는 Selector
 */
export const deviceScaleSelector = createSelector(templateSelector, (state) => state?.deviceScale ?? 1);

/**
 * imageMaxSize 값을 선택하는 Selector
 */
export const imageMaxSizeSelector = createSelector(templateSelector, (state) => state?.imageMaxSize ?? 500 * 1.25);

/**
 * canvasWidth 값을 선택하는 Selector
 */
export const canvasWidthSelector = createSelector(templateSelector, (state) => state?.canvasWidth ?? 500);

/**
 * canvasHeight 값을 선택하는 Selector
 */
export const canvasHeightSelector = createSelector(templateSelector, (state) => state?.canvasHeight ?? 500);

/**
 * textures 값을 선택하는 Selector
 */
export const texturesSelector = createSelector(templateSelector, (state) => state?.textures);

/**
 * backgroundTexture를 선택하는 Selector
 */
export const backgroundTextureSelector = createSelector(templateSelector, (state) => state?.contentId);

/**
 * backgroundColor 값을 선택하는 Selector
 */
export const backgroundColorSelector = createSelector(templateSelector, (state) => {
  if (typeof state?.background === 'string') return state.background;

  return undefined;
});

/**
 * background의 Gradient 값을 선택하는 Selector
 */
export const backgroundGradientSelector = createSelector(templateSelector, (state) => {
  if (typeof state?.background === 'object') return state.background;

  return undefined;
});
