import {PlainTextManager } from '@lws/common';
import { TemplateObjectAlignment, TemplateTextObject } from '@lws/types';
import uuid from 'react-uuid';

import { degreeToRadian, getTextFrameHeight, getTextFrameWidth } from '../../utils/editor/template';
import { getFontFromDecoratorIds, getTextBoundingRectFromTextObject } from '../../utils/editor/text';
import { ObjectManager } from '../ObjectManager';

export class TextObjectManager extends ObjectManager<TemplateTextObject> {
  private defaultFontSize = 50;

  constructor(object?: Partial<TemplateTextObject>) {
    const defaultObject: Partial<TemplateTextObject> = {
      type: 'CLTextView',
      textAlignment: 1,
      hexColor: '#000000',
      alpha: 1,
      centerX: 0.5,
      centerY: 0.5,
      maskImageCenterX: 0,
      maskImageCenterY: 0,
      maskImageScale: 0,
      scale: 1,
      arg: 0,
      hue: 0,
      brightness: 0,
      lineSpacing: 1,
      kerning: 0,
      decoratorIds: [
        'pixo-cms://resource@embedded/font/AbhayaLibre-ExtraBold',
      ],
      parameters: {
        primaryKey: uuid(),
        singleLineHeight: 59,
      },
    };

    super(object ? { ...defaultObject, ...object } : defaultObject);
  }

  public setSingleLineHeight(singleLineHeight: number) {
    if (this.object === undefined) return this;
    if (this.object.parameters === undefined) return this;

    this.object = {
      ...this.object,
      parameters: {
        ...this.object.parameters,
        singleLineHeight,
      },
    };

    return this;
  }

  public initializeFrame() {
    const {
      scale,
      fontSize,
      parameters,
    } = this.object;

    if (scale === undefined || fontSize === undefined || parameters === undefined) return this;

    const boundingRect = getTextBoundingRectFromTextObject(this.object as TemplateTextObject);
    const frameWidth = getTextFrameWidth(boundingRect.width, scale, parameters.singleLineHeight, fontSize);
    const frameHeight = getTextFrameHeight(boundingRect.height, scale);

    this.object.frameWidth = frameWidth;
    this.object.frameHeight = frameHeight;

    return this;
  }

  public setFontScaleFromFontSize(fontSize: number) {
    const fontScale = fontSize / this.defaultFontSize;

    this.object = {
      ...this.object,
      scale: fontScale,
      fontSize,
    };

    return this;
  }

  public setFontScale(fontScale: number) {
    const fontSize = this.defaultFontSize * fontScale;

    this.object = {
      ...this.object,
      scale: fontScale,
      fontSize,
    };

    return this;
  }

  public setArg(angle: number, isDegree: boolean) {
    const arg = isDegree ? degreeToRadian(angle) : angle;

    this.object.arg = arg;

    return this;
  }

  public setText(text: string) {
    this.object = {
      ...this.object,
      text,
    };

    return this;
  }

  public moveX(distance: number) {
    const { canvasWidth } = this;

    if (canvasWidth === undefined) return this;

    const centerPointX = this.getCenterPointX() + distance;

    this.object = {
      ...this.object,
      centerX: centerPointX / canvasWidth,
    };

    return this;
  }

  public moveY(distance: number) {
    const { canvasHeight } = this;

    if (canvasHeight === undefined) return this;

    const centerPointY = this.getCenterPointY() + distance;

    this.object = {
      ...this.object,
      centerY: centerPointY / canvasHeight,
    };

    return this;
  }

  public setFontDecoratorFromFontName(fontName: string) {
    const decoratorIds = this.object.decoratorIds ? [...this.object.decoratorIds] : [];
    const decorator = `pixo-cms://resource@embedded/font/${fontName}`;
    const existDecoratorIndex = decoratorIds.findIndex((decorator) => decorator.includes('resource@embedded/font'));
    const replaceIndex = existDecoratorIndex === -1 ? decoratorIds.length : existDecoratorIndex;

    decoratorIds.splice(replaceIndex, 1, decorator);

    this.object.decoratorIds = decoratorIds;

    return this;
  }

  public setAlignment(alignment: TemplateObjectAlignment) {
    this.object = {
      ...this.object,
      textAlignment: alignment,
    };
  }

  public setLineSpacing(lineSpacing: number) {
    this.object = {
      ...this.object,
      lineSpacing,
    };

    return this;
  }

  public setKerning(kerning: number) {
    this.object = {
      ...this.object,
      kerning,
    };

    return this;
  }

  public setDecoratorIdFromFontName(fontName: string) {
    const decoratorId = `pixo-cms://resource@embedded/font/${fontName}`;

    this.object = {
      ...this.object,
      decoratorIds: [
        ...(this.object.decoratorIds ?? []),
        decoratorId,
      ],
    };

    return this;
  }

  public get fontName() {
    const fontName = getFontFromDecoratorIds(this.object.decoratorIds ?? [])?.fontName;

    return fontName;
  }

  public get lineSpacingStyle() {
    const { lineSpacing, parameters } = this.object;

    if (lineSpacing === undefined || parameters?.singleLineHeight === undefined) return 0;

    const optimizedLineHeight = lineSpacing / 3;
    const mutiplier = 3;
    const multiple = optimizedLineHeight * mutiplier;
    const _lineHeight = (multiple - 1) * parameters.singleLineHeight;

    return _lineHeight;
  }

  public get letterSpacingStyle() {
    const { kerning, scale } = this.object;

    if (kerning === undefined || scale === undefined) return 0;

    return PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);
  }

  public get rect() {
    return getTextBoundingRectFromTextObject(this.object as TemplateTextObject);
  }
}
