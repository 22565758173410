import { css } from '@emotion/react';

/**
 * MEMO : 온보딩 & 로고 프리뷰에 사용되는 스타일입니다.
 * 디자인 시스템이 통합되지 않아 일부를 인라인 스타일 형태로 사용하고 있스빈다.
 * TODO: boon-ui 패키지가 추가되면 이 부분을 boon-ui로 수정하기
 * TODO: boon-ui 패키지가 추가되면 아래 상수를 사용 중인 모든 곳에서 스타일 수정하기
 */

export const styles = {
  'caption_12_r': css`
    /* caption_12_r */
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  `,
  'Text/caption_14_r': css`
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  `,
  'Text/body_16_r': css`
    /* Text/body_16_r */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  `,
  'Text/body_18_r': css`
    /* Text/body_18_r */
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  `,
  'Text/body_16_sb': css`
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  `,
  'Text/body_18_sb': css`
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
  `,
  'Title/title_24_sb': css`
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
  `,
  'Title/title_32_sb': css`
    /* Title/title_32_sb */
    font-family: Pretendard;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 41.6px */
  `,
  'Title/title_36_sb': css`
    /* Title/title_36_sb */
    font-family: Pretendard;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 46.8px */
  `,
};

export const animations = {
  shake: css`
    @keyframes shake {
      0% { transform: translate(1px, 0); }
      10% { transform: translate(-1px, 0); }
      20% { transform: translate(-3px, 0); }
      30% { transform: translate(3px, 0); }
      40% { transform: translate(1px, 0); }
      50% { transform: translate(-1px, 0); }
      60% { transform: translate(-3px, 0); }
      70% { transform: translate(3px, 0); }
      80% { transform: translate(-1px, 0); }
      90% { transform: translate(1px, 0); }
      100% { transform: translate(1px, 0); }
    }
  `,
};
