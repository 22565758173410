import { css, Dialog, Typography } from '@mui/material';

import { styles } from '../../../constants/styles';
import { Button } from '../Button/v2';
import { useOnboardingController } from '../OnboardingRequest/hooks/useOnboardingController';

interface OnboardingExitDialogProps {
  onCancel: () => void;
  onConfirm: () => void;
}

/**
 * 온보딩을 진행 중인 유저가 온보딩을 종료하기 위해 확인받는 Dialog를 보여주는 컴포넌트
 */
export const OnboardingExitDialog = (props: OnboardingExitDialogProps) => {
  const { onCancel, onConfirm } = props;
  const { onboardingExitDialog } = useOnboardingController();
  const { open, onClose } = onboardingExitDialog;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableEnforceFocus
      PaperProps={{
        sx: {
          maxWidth: '320px',
          padding: '32px 24px 24px 24px',
          borderRadius: '16px',
        },
      }}
    >
      <div>
        <div
          className="body"
          css={css`
            margin-bottom: 24px;
          `}
        >
          <Typography
            css={css`
              margin-bottom: 12px;
              /* // TODO: boon-ui 통합 필요 */
              color: var(--color-Gray-black, #000);
              text-align: center;
              ${styles['Title/title_24_sb']};
            `}
          >
            {/* // TODO: 다국어 처리 */}
            Are you Sure?
          </Typography>
          <Typography
            css={css`
              color: #555;
              text-align: center;
              ${styles['Text/caption_14_r']};
            `}
          >
            {/* // TODO: 다국어 처리 */}
            You are just few seconds away from your logo.  All your selections will be deleted when you exit.
          </Typography>
        </div>
        <div
          className="actions"
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 8px;
          `}
        >
          <Button
            variant="contained"
            color="plain"
            fullWidth
            css={css`
              height: 48px;
              border-radius: 8px;
            `}
            onClick={onCancel}
          >
            <Typography
              css={css`
                color: var(--grayColor-gray_700, #3D3D3D);
                text-align: center;
                ${styles['Text/body_16_sb']};
              `}
            >
              {/* // TODO: 다국어 처리 */}
              Cancel
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            css={css`
              height: 48px;
              border-radius: 8px;
            `}
            onClick={onConfirm}
          >
            <Typography
              css={css`
                // TODO: boon-ui 통합 필요
                color: var(--color-Gray-white, #FFF);
                text-align: center;
                ${styles['Text/body_16_sb']};
              `}
            >
              {/* // TODO: 다국어 처리 */}
              I'm sure
            </Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
