import { css } from '@emotion/react';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { ComponentProps, PartnershipLogo } from '~/src/types/common';


export interface PlanPartnershipProps extends ComponentProps {
  partnerships: PartnershipLogo[];
}

export const PlanPartnership = (props: PlanPartnershipProps) => {
  const { partnerships } = props;

  const { t } = useTranslation();
  const tablet = useMediaQuery('tablet');

  return (
    <article
      className={classNames(
        'PlanPartnership',
        props.className
      )}
      data-testid="PlanPartnership"
      css={css`
        text-align: center;
      `}
    >
      <Typography
        variant="body6"
        data-testid="PlanPartnership__Title"
      >
        {t('common:partnership.title')}
      </Typography>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          column-gap: 12px;
          row-gap: 12px;
          margin-top: 16px;
        `}
      >
        {partnerships.map((partnership, key) => {
          return (
            <div
              key={key}
              css={css`
                height: 48px;
                ${tablet.down.mq} {
                  height: 36px;
                }
              `}
            >
              <div
                css={css`
                  height: 100%;
                  padding: 8px 12px;
                `}
              >
                <Image
                  src={partnership.src}
                  alt={partnership.alt}
                  width={0}
                  height={0}
                  sizes="100vw"
                  style={{ width: 'auto', height: '100%' }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </article>
  );
};
