import { APIClient } from '../APIClient';
import {GetAuthenticationToken, GetMyOneShotPaymentResponse, GetMyPlanResponse, GetMyYearlyPaymentResponse } from './PaymentsAPI.types';

export const PAYMENT_BILLING_HISTORY_LIMIT = 10;

export class PaymentsAPI extends APIClient {
  /**
   * 결제창 호출 전 인증 토큰을 발급받는 API
   * @returns 인증 토큰 정보를 반환함 {GetAuthenticationToken}
   */
  async getAuthToken() {
    const apiEndPoint = `/${this.appName}/payments/token`;

    const res = await this.axios.get<GetAuthenticationToken>(
      apiEndPoint
    );

    return res.data;
  }

  /**
   * 내 결제 정보를 가져오는 API
   * @param userId 유저 ID
   * @returns 유저 결제 정보를 반환함 {GetMyPlanResponse}
   */
  async getMyPlan(userId: string) {
    const res = await this.axios.get<GetMyPlanResponse>(
      `/${this.appName}/users/${userId}/payments/plan`,
      {
        headers: {
          'Cache-Control': 'max-age=0',
        },
      }
    );

    return res.data;
  }

  /**
   * 연간 결제를 불러오는 API
   * @param userId 유저 ID
   * @returns 유저 연간 결제 정보를 반환함 {GetMyYearlyPaymentResponse}
   */
  async getMyYearlyPlan(params: { userId: string; lastSubscriptionId?: number; }) {
    const { userId, lastSubscriptionId } = params;
    const url = new URL(`${this.baseURL}/${this.appName}/users/${userId}/subscriptions`);

    url.searchParams.set('limit', `${PAYMENT_BILLING_HISTORY_LIMIT}`);
    lastSubscriptionId && url.searchParams.set('lastSubscriptionId', `${lastSubscriptionId}`);

    const res = await this.axios.get<GetMyYearlyPaymentResponse>(url.toString());

    return res.data;
  }

  /**
   * 단건 결제를 불러오는 API
   * @param userId 유저 ID
   * @returns 유저 단건 결제 정보를 반환함 {GetMyOneShotPaymentResponse}
   */
  async getMyOneShotPlan(params: { userId: string; lastOrderId?: number; }) {
    const { userId, lastOrderId } = params;
    const url = new URL(`${this.baseURL}/${this.appName}/users/${userId}/orders`);

    url.searchParams.set('limit', `${PAYMENT_BILLING_HISTORY_LIMIT}`);
    lastOrderId && url.searchParams.set('lastOrderId', `${lastOrderId}`);

    const res = await this.axios.get<GetMyOneShotPaymentResponse>(url.toString());

    return res.data;
  }

  /**
   * 구독 취소하는 API
   * @param userId 유저 ID
   * @param subscriptionId 구독 ID
   * @returns
   */
  async cancelYearlyPlan(userId: string, subscriptionId: string) {
    // API 요구사항에 encodeURIComponent 하여 보내야 한다고 명시되어 있음
    const encodedSubscriptionId = window.encodeURIComponent(subscriptionId);
    const res = await this.axios.delete<void>(
      `${this.appName}/users/${userId}/subscriptions/${encodedSubscriptionId}`
    );

    return res.data;
  }
}

export const paymentsAPIClient = new PaymentsAPI;

export default paymentsAPIClient;
