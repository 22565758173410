import { useCallback, useMemo } from 'react';

import { myWorksAPIClient } from '~/src/api/v2/MyWorksAPI';
import { useAppSelector } from '~/src/store';
import { allObjectsSelector } from '~/src/store/editor/objects/selectors';
import { allResourcesSelector } from '~/src/store/editor/resources/slice';
import { templateDataSelector } from '~/src/store/editor/template/selectors';
import { myWorkDataSelector, relatedCategorySelector } from '~/src/store/my-work/selectors';

export const useSaveMyWork = () => {
  const currentMyWork = useAppSelector(myWorkDataSelector);
  const template = useAppSelector(templateDataSelector);
  const objects = useAppSelector(allObjectsSelector);
  const resources = useAppSelector(allResourcesSelector);
  const relatedCategoryId = useAppSelector(relatedCategorySelector);

  const myWorkId = useMemo(() => {
    return currentMyWork?.myWorkId;
  }, [currentMyWork?.myWorkId]);

  const save = useCallback((userId: string) => {
    if (userId === undefined) throw new Error('userId is not defined.');
    if (myWorkId === undefined) throw new Error('myWorkId is not defined.');
    if (template === undefined) throw new Error('template is not defined.');

    const stringTemplate = JSON.stringify({
      ...template,
      objects,
    });

    return myWorksAPIClient.save(
      userId,
      myWorkId,
      {
        template: stringTemplate,
        resources,
        metadata: {
          relatedCategoryId: relatedCategoryId?.id,
        },
      }
    );
  }, [
    myWorkId,
    objects,
    template,
    resources,
    relatedCategoryId,
  ]);

  return { save };
};
