import axios from 'axios';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';

import { CognitoClient } from '~/src/utils/cognito';

export const API_TIMEOUT = 1000 * 20;

export class APIClient {
  axios;
  appName = process.env.NEXT_PUBLIC_APP_NAME_V2;
  baseURL = process.env.NEXT_PUBLIC_API_HOST_V2;
  timeout = API_TIMEOUT;

  constructor() {
    this.axios = axios.create({
      baseURL: this.baseURL,
      timeout: this.timeout,
    });

    this.axios.interceptors.request.use(async (config) => {
      const options = { path: '/' };
      const tokenType = getCookie('token-type', options) as string;
      const idToken = getCookie('id-token', options) as string;
      const refreshToken = getCookie('refresh-token', options) as string;

      config.headers['x-api-key'] = process.env.NEXT_PUBLIC_API_KEY;

      try {
        await CognitoClient.getPayloadFromIdToken(idToken);

        config.headers['Authorization'] = `${tokenType} ${idToken}`;

        return config;
      } catch {
        try {
          if (refreshToken) {
            const {
              id_token,
              access_token,
              expires_in,
              token_type,
            } = await CognitoClient.getAllTokensFromRefreshToken(refreshToken);

            setCookie('id-token', id_token, options);
            setCookie('access-token', access_token, options);
            setCookie('expires-in', expires_in, options);
            setCookie('token-type', token_type, options);

            config.headers['Authorization'] = `${token_type} ${id_token}`;
          }
        } catch {
          deleteCookie('id-token', options);
          deleteCookie('access-token', options);
          deleteCookie('expires-in', options);
          deleteCookie('token-type');
        }
      }

      return config;
    });
  }
}

export const apiClient = new APIClient;

export default apiClient;
