import { Fragment } from 'react';

import { SwitchCaseProps } from './SwitchCase.types';

export const SwitchCase = ({
  value,
  caseBy,
  defaultElement,
}: SwitchCaseProps) => {
  if (typeof caseBy !== 'object') throw new Error('caseBy is not object.');

  const stringValue = value?.toString();

  for (const [key, element] of Object.entries(caseBy)) {
    if (key === stringValue) {
      return (
        <Fragment
          key={key}
        >
          { element }
        </Fragment>
      );
    }
  }

  return (<>{ defaultElement }</>);
};
