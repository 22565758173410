import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { TemplateResourceGroup } from '~/src/types/my-work';

import { APP_STATE } from '../../types';
import { addStickerObjectThunk, overwriteMyWorkFromTemplateThunk } from '../thunk/EditorThunk';

interface ResourceAdapter {
  type: string;
  id: string;
  value: string;
}

const resourceAdapter = createEntityAdapter<ResourceAdapter>({
  selectId: (model) => model.id,
});

export const resourcesSlice = createSlice({
  name: 'Resources',
  initialState: resourceAdapter.getInitialState(),
  reducers: {
    setAll: (state: EntityState<ResourceAdapter>, action: PayloadAction<TemplateResourceGroup>) => {
      const adapterPayload = getAdapterPayloadFromMyWorkResource(action.payload);

      resourceAdapter.setAll(state, adapterPayload);
    },
    setMany: (state: EntityState<ResourceAdapter>, action: PayloadAction<TemplateResourceGroup>) => {
      const adapterPayload = getAdapterPayloadFromMyWorkResource(action.payload);

      resourceAdapter.setMany(state, adapterPayload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addStickerObjectThunk.fulfilled, (state, action) => {
        resourceAdapter.setOne(state, {
          id: action.payload.id,
          type: 'symbol',
          value: action.payload.resource,
        });
      })
      .addCase(overwriteMyWorkFromTemplateThunk.fulfilled, (state, action) => {
        // 리소스는 히스토리에 추가 되지 않으므로 setAll 대신 setMany
        resourceAdapter.setMany(state, getAdapterPayloadFromMyWorkResource(action.payload.resources));
      });
  },
});

const getAdapterPayloadFromMyWorkResource = (payload: TemplateResourceGroup) => {
  const adapterPayload: ResourceAdapter[] = [];
  const resources = Object.entries(payload);

  for (const [type, object] of resources) {
    const items = Object.entries<string>(object);

    items.forEach(([id, value]) => {
      adapterPayload.push({ type, id, value });
    });
  }

  return adapterPayload;
};

const getMyWorkResourcesFromAdapterPayload = (payload: ResourceAdapter[]) => {
  const resources: TemplateResourceGroup = {
    font: {},
    image: {},
    texture: {},
    symbol: {},
  };

  for (const adapter of payload) {
    if (resources[adapter.type] === undefined) resources[adapter.type] = {};

    resources[adapter.type][adapter.id] = adapter.value;
  }

  return resources;
};

const resourcesSelector = resourceAdapter.getSelectors<APP_STATE>((state) => state.editor.resources);

export const allResourcesSelector = createSelector((state: APP_STATE) => state, (state: APP_STATE) => {
  const allItems = resourcesSelector.selectAll(state);

  return getMyWorkResourcesFromAdapterPayload(allItems);
});

export const resourceSelector = (resourceId: string) => (state: APP_STATE) => {
  return resourcesSelector.selectById(state, resourceId);
};
