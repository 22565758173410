import { css } from '@emotion/react';
import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { Button } from '~/src/components/app/Button/v2';

export const MobileLimitAlert = () => {
  const theme = useTheme();
  const [mounted, setMounted] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(isMobile);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!(mounted && isMobile && isShow)) {
    return null;
  }

  return (
    <div
      className={classNames(
        'CommonAlert'
      )}
      css={css`
          position: fixed;
          left: 50%;
          bottom: 30px;
          background-color: #1C1C1C;
          border-radius: 10px;
          padding: 16px 18px;
          max-width: 320px;
          min-width: 240px;
          z-index: 999;
          transform: translate(-50%, 0);
          width: 100%;
        `}
    >

      <div
        className="CommonAlert__Content"
        css={css`
            position: relative;
            z-index: 2;
          `}
      >
        <Typography
          variant="body8"
          css={css`
              margin-bottom: 8px;
              color: ${theme.app.colors['neutral-color-10']};
            `}
        >
          Editing is only possible on desktop. Please connect to the desktop
        </Typography>
      </div>
      <div
        className="CommonAlert__Buttons"
        css={css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
          `}
      >
        <Button
          variant="outlined"
          size="small"
          css={css`
              border-color: #707070;
              color: ${theme.app.colors['neutral-color-10']};
            `}
          onClick={() => {setIsShow(false);}}
        >
          Got it
        </Button>
      </div>
    </div>
  );
};
