import { css, Grid } from '@mui/material';

import { OnboardingBrandNameTextField } from '~/src/components/business/Onboarding/OnboardingBrandNameTextField';
import { OnboardingColorGrid } from '~/src/components/business/Onboarding/OnboardingColorGrid';
import { OnboardingIndustryGrid } from '~/src/components/business/Onboarding/OnboardingIndustryGrid';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

import { OnboardingModalMeta } from '../OnboardingModalMeta/OnboardingModalMeta';
import { OnboardingModalStepButton } from '../OnboardingModalStepButton/OnboardingModalStepButton';
import { OnboardingStepBrandNameProps, OnboardingStepColorProps, OnboardingStepIndustryProps, OnboardingStepProps  } from './OnboardingStep.types';

export const OnboardingStep = (props: OnboardingStepProps) => {
  const {
    children,
    title,
    description,
    disabledNext,
    onNext,
    onFinish,
  } = props;

  const laptop = useMediaQuery('laptop');

  return (
    <>
      <Grid
        container
        columns={{
          desktop: 12,
          laptop: 12,
          tablet: 8,
          mobile: 4,
        }}
        rowSpacing={'16px'}
        className="body"
        justifyContent={'center'}
      >
        <Grid
          item
          desktop={6}
          laptop={7}
          tablet={8}
          mobile={4}
        >
          <OnboardingModalMeta
            title={title}
            description={description}
          />
        </Grid>
        <Grid
          item
          desktop={2}
          laptop={3}
          tablet={8}
          mobile={4}
          css={css`
            ${laptop.down.mq} {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              z-index: 1;
            }
          `}
        >
          <OnboardingModalStepButton
            css={css`
              ${laptop.down.mq} {
                justify-content: center;
                border-radius: 0;
              }
            `}
            disabled={disabledNext}
            onNext={onNext}
            onFinish={onFinish}
          />
        </Grid>
        <Grid
          item
          desktop={8}
          laptop={10}
          tablet={8}
          mobile={4}
          css={css`
            display: flex;
            margin-top: 80px;
            ${laptop.down.mq} {
              margin-top: 58px;
            }
          `}
        >
          {children}
        </Grid>
      </Grid>
    </>
  );
};

OnboardingStep.BrandName = (props: OnboardingStepBrandNameProps) => {
  return (
    <OnboardingStep
      {...props}
    >
      <OnboardingBrandNameTextField
        {...props}
      />
    </OnboardingStep>
  );
};

OnboardingStep.Color = (props: OnboardingStepColorProps) => {
  return (
    <OnboardingStep
      {...props}
    >
      <OnboardingColorGrid
        {...props}
      />
    </OnboardingStep>
  );
};

OnboardingStep.Industry = (props: OnboardingStepIndustryProps) => {
  return (
    <OnboardingStep
      {...props}
    >
      <OnboardingIndustryGrid
        {...props}
      />
    </OnboardingStep>
  );
};
