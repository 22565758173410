import Head from 'next/head';

import { AppHeadProps } from './AppHead.types';

export const AppHead: React.FC<AppHeadProps> = (props) => {
  const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

  return (
    <Head>
      <link
        rel="preconnect"
        href="https://fonts.googleapis.com"
        key={'fonts-googleapis'}
      />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        key={'fonts-gstatic'}
      />
      <link
        rel="preconnect"
        href="https://cdn.jsdelivr.net"
        key={'jsdelivr'}
      />
      <link
        rel="preload"
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        as="style"
      />
      <link
        key={'font-poppins'}
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      />
      <link
        key={'pretendard'}
        rel="stylesheet"
        as="style"
        crossOrigin="anonymous"
        href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css"
      />
      <meta
        key={'viewport'}
        name="viewport"
        content="user-scalable=no, width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
      />
      {
        process.env.NODE_ENV === 'production'
          && (
            <script
              dangerouslySetInnerHTML={{
                __html: `
                (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:3783061,hjsv:6};
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
              }}
            />
          )
      }
      {
        // TODO: 추후 Next.js 모듈로 변경할 것.
        // https://nextjs.org/docs/messages/next-script-for-ga
        process.env.NODE_ENV === 'production'
          && GOOGLE_TAG_MANAGER_ID && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
                `,
            }}
          />
        )
      }
      {props.children}
    </Head>
  );
};
