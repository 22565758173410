import { Fragment, useCallback, useEffect, useState } from 'react';
import InlineSVG from 'react-inlinesvg';

import { ICON_TEST_ID } from './Icon.constants';
import { IconProps } from './Icon.types';

export const Icon = ({
  className,
  icon,
  width = 24,
  height = 24,
  fill,
  stroke,
}: IconProps) => {
  const [iconPath, setIconPath] = useState<string>();

  useEffect(() => {
    import(`../../assets/icons/${icon}.svg`)
      .then((res) => res.default?.src ? setIconPath(res.default.src) : setIconPath(undefined));
  }, []);

  const parseSVG = useCallback((svg: string) => {
    if (fill === undefined || stroke === undefined) return svg;

    const parser = new DOMParser();
    const xml = parser.parseFromString(svg, 'image/svg+xml');
    const elements = xml.querySelectorAll('path');

    elements.forEach((element) => {
      if (fill) element.setAttribute('fill', fill);
      if (stroke) element.setAttribute('stroke', stroke);
    });

    return xml.documentElement.outerHTML;
  }, []);

  return (
    <Fragment>
      {
        iconPath
          ? (
            <InlineSVG
              data-testid={ICON_TEST_ID}
              className={className}
              src={iconPath}
              width={width}
              height={height}
              preProcessor={parseSVG}
            />
          )
          : (
            <Fragment />
          )
      }
    </Fragment>
  );
};
