import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { userAPIClient } from '~/src/api/v2/UserAPI';
import { UserStorage } from '~/src/storage/UserStorage';

import { useAuth } from '../../v2/Account';
import { OnboardingType } from './useOnboardingData.types';

/**
 * 로그인/미로그인 여부에 따라 저장소를 다르게 하여 온보딩 정보를 반환하는 Hook
 * 로그인 : User API에 요청을 보내 온보딩 정보를 가져옴
 * 미로그인 : LocalStorage API를 통해 온보딩 정보를 가져옴
 * @returns 온보딩 정보
 */
export const useOnboardingData = () => {
  const { userId, isInitialLoading } = useAuth();

  const onboardingQuery = useQuery({
    queryKey: ['onboarding', userId],
    queryFn: () => {
      if (userId) return userAPIClient.getUser({ userId });
      return UserStorage.get();
    },
    enabled: isInitialLoading === false,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const onboardingData = useMemo<OnboardingType>(() => {
    return {
      isOnboardingRequestPopupOpened: onboardingQuery.data?.isOnboardingRequestPopupOpened,
      onboardingStep: onboardingQuery.data?.onboardingStep,
    };
  }, [onboardingQuery.data]);

  return {
    onboardingQuery,
    onboardingData,
  };
};
