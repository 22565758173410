import { css } from '@emotion/react';
import { isColor } from '@lws/common';
import { Renderer } from '@lws/template-renderer';
import { useState } from 'react';

import { useAppSelector } from '~/src/store';
import { allObjectsSelector } from '~/src/store/editor/objects/selectors';
import { allResourceMetaSelector } from '~/src/store/editor/resource-meta/slice';
import { allResourcesSelector } from '~/src/store/editor/resources/slice';
import { backgroundColorSelector, backgroundGradientSelector, backgroundTextureSelector, canvasHeightSelector, canvasWidthSelector } from '~/src/store/editor/template/selectors';
import { zoomSelector } from '~/src/store/editor/zoom/slice';

import { usePreviewerResize } from './usePreviewerResize';

export const MyWorkTemplatePreviewer = () => {
  const width = useAppSelector(canvasWidthSelector);
  const height = useAppSelector(canvasHeightSelector);
  const objects = useAppSelector(allObjectsSelector);
  const resources = useAppSelector(allResourcesSelector);
  const meta = useAppSelector(allResourceMetaSelector);

  const texture = useAppSelector(backgroundTextureSelector);
  const background = useAppSelector(backgroundColorSelector);
  const gradientBackground = useAppSelector(backgroundGradientSelector);

  const zoom = useAppSelector(zoomSelector);

  const [containerElement, setContainerElement] = useState<HTMLElement>();
  const [previewerElement, setPreviewerElement] = useState<HTMLElement>();

  usePreviewerResize({
    containerElement,
    previewerElement,
    deps: [zoom],
  });

  const defaultBackgroundColor = '#EFEFEF';

  return (
    <div
      ref={(element) => setContainerElement(element ?? undefined)}
      className="MyWorkTemplatePreviewer"
      css={css`
        width: 100%;
        height: 100%;
        background-color: ${background ? isColor(background) ? background : defaultBackgroundColor : defaultBackgroundColor};
      `}
    >
      <Renderer
        ref={(element) => setPreviewerElement(element ?? undefined)}
        width={width}
        height={height}
        objects={objects}
        resources={resources}
        resourceMeta={meta}
        textureBackgroundURI={texture}
        background={gradientBackground ? gradientBackground : background ? background : defaultBackgroundColor}
        style={{
          transformOrigin: 'left top',
          position: 'relative',
          zIndex: 2,
        }}
      />
    </div>
  );
};
