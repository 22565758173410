import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const initialState = {
};

/**
 * @member id Object primary key
 * @see {useEditable.ts}
 */
interface EditObject {
  id: string;
  mode: 'text'
}

export const editObjectsAdapter = createEntityAdapter<EditObject>({
  selectId: (o) => o.id,
});

export type EditObjectsSliceState = typeof initialState;

export const editObjectsSlice = createSlice({
  name: 'EditObjectsSlice',
  initialState: editObjectsAdapter.getInitialState(initialState),
  reducers: {
    setOne: editObjectsAdapter.setOne,
    setMany: editObjectsAdapter.setMany,
    setAll: editObjectsAdapter.setAll,
    removeOne: editObjectsAdapter.removeOne,
    removeMany: editObjectsAdapter.removeMany,
    removeAll: editObjectsAdapter.removeAll,
  },
});
