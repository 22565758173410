import {
  BUTTON_COLOR_MONO,
  BUTTON_COLOR_PRIMARY,
  BUTTON_COLOR_SECONDARY,
  BUTTON_SIZE_LARGE,
  BUTTON_SIZE_MEDIUM,
  BUTTON_SIZE_SMALL,
  BUTTON_SIZE_X_LARGE,
  BUTTON_STATUS_DISABLE,
  BUTTON_STATUS_ENABLE,
  BUTTON_STATUS_HOVER,
  BUTTON_STATUS_PRESSED,
  BUTTON_STATUS_SELECT,
  BUTTON_VARIANT_CONTAINED,
  BUTTON_VARIANT_OUTLINE,
  BUTTON_VARIANT_TEXT
} from '../../constants';
import { COLORS, OPACITY } from '../../styles/colors';
import { SPACINGS } from '../../styles/spacing';
import { TYPOGRAPHY } from '../../styles/typography';

export const ButtonColorStyles = {
  [BUTTON_COLOR_PRIMARY]: {
    [BUTTON_VARIANT_CONTAINED]: {
      [BUTTON_STATUS_ENABLE]: {
        border: COLORS['brand_600'],
        color: COLORS['white'],
        background: COLORS['brand_600'],
      },
      [BUTTON_STATUS_HOVER]: {
        border: COLORS['brand_700'],
        color: COLORS['white'],
        background: COLORS['brand_700'],
      },
      [BUTTON_STATUS_PRESSED]: {
        border: COLORS['brand_600'],
        color: COLORS['white'],
        background: COLORS['brand_600'],
      },
      [BUTTON_STATUS_SELECT]: {
        border: COLORS['brand_900'],
        color: COLORS['white'],
        background: COLORS['brand_900'],
      },
      [BUTTON_STATUS_DISABLE]: {
        border: COLORS['gray_100'],
        color: COLORS['gray_300'],
        background: COLORS['gray_100'],
      },
      loading: {
        border: COLORS['brand_600'],
        color: COLORS['white'],
        background: COLORS['brand_600'],
      },
    },
    [BUTTON_VARIANT_OUTLINE]: {
      [BUTTON_STATUS_ENABLE]: {
        border: COLORS['brand_900'],
        color: COLORS['brand_900'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_HOVER]: {
        border: COLORS['brand_900'],
        color: COLORS['brand_900'],
        background: COLORS['brand_50'],
      },
      [BUTTON_STATUS_PRESSED]: {
        border: COLORS['brand_900'],
        color: COLORS['brand_900'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_SELECT]: {
        border: COLORS['brand_900'],
        color: COLORS['brand_900'],
        background: COLORS['brand_100'],
      },
      [BUTTON_STATUS_DISABLE]: {
        border: COLORS['gray_100'],
        color: COLORS['gray_300'],
        background: COLORS['gray_100'],
      },
      loading: {
        border: COLORS['brand_900'],
        color: COLORS['brand_900'],
        background: COLORS['white'],
      },
    },
    [BUTTON_VARIANT_TEXT]: {
      [BUTTON_STATUS_ENABLE]: {
        border: COLORS['white'],
        color: COLORS['brand_900'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_HOVER]: {
        border: COLORS['brand_100'],
        color: COLORS['brand_900'],
        background: COLORS['brand_100'],
      },
      [BUTTON_STATUS_PRESSED]: {
        border: COLORS['white'],
        color: COLORS['brand_900'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_SELECT]: {
        border: COLORS['brand_100'],
        color: COLORS['brand_900'],
        background: COLORS['brand_100'],
      },
      [BUTTON_STATUS_DISABLE]: {
        border: COLORS['gray_100'],
        color: COLORS['gray_300'],
        background: COLORS['gray_100'],
      },
      loading: {
        border: COLORS['brand_900'],
        color: COLORS['brand_900'],
        background: COLORS['white'],
      },
    },
  },
  [BUTTON_COLOR_SECONDARY]: {
    [BUTTON_VARIANT_CONTAINED]: {
      [BUTTON_STATUS_ENABLE]: {
        border: OPACITY['gray_op_50'],
        color: COLORS['brand_600'],
        background: COLORS['brand_50'],
      },
      [BUTTON_STATUS_HOVER]: {
        border: OPACITY['gray_op_50'],
        color: COLORS['brand_600'],
        background: COLORS['brand_100'],
      },
      [BUTTON_STATUS_PRESSED]: {
        border: OPACITY['gray_op_50'],
        color: COLORS['brand_600'],
        background: COLORS['brand_50'],
      },
      [BUTTON_STATUS_SELECT]: {
        border: OPACITY['gray_op_50'],
        color: COLORS['brand_900'],
        background: COLORS['brand_100'],
      },
      [BUTTON_STATUS_DISABLE]: {
        border: COLORS['gray_100'],
        color: COLORS['gray_300'],
        background: COLORS['gray_100'],
      },
      loading: {
        border: COLORS['brand_50'],
        color: COLORS['white'],
        background: COLORS['brand_50'],
      },
    },
    [BUTTON_VARIANT_OUTLINE]: {
      [BUTTON_STATUS_ENABLE]: {
        border: COLORS['brand_300'],
        color: COLORS['brand_700'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_HOVER]: {
        border: COLORS['brand_300'],
        color: COLORS['brand_700'],
        background: COLORS['brand_50'],
      },
      [BUTTON_STATUS_PRESSED]: {
        border: COLORS['white'],
        color: COLORS['brand_700'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_SELECT]: {
        border: OPACITY['gray_op_50'],
        color: COLORS['brand_700'],
        background: COLORS['brand_50'],
      },
      [BUTTON_STATUS_DISABLE]: {
        border: COLORS['gray_100'],
        color: COLORS['gray_300'],
        background: COLORS['gray_100'],
      },
      loading: {
        border: COLORS['brand_600'],
        color: COLORS['brand_900'],
        background: COLORS['white'],
      },
    },
    [BUTTON_VARIANT_TEXT]: {
      [BUTTON_STATUS_ENABLE]: {
        border: COLORS['white'],
        color: COLORS['brand_700'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_HOVER]: {
        border: COLORS['brand_50'],
        color: COLORS['brand_700'],
        background: COLORS['brand_50'],
      },
      [BUTTON_STATUS_PRESSED]: {
        border: COLORS['white'],
        color: COLORS['brand_700'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_SELECT]: {
        border: COLORS['brand_50'],
        color: COLORS['brand_700'],
        background: COLORS['brand_50'],
      },
      [BUTTON_STATUS_DISABLE]: {
        border: COLORS['gray_100'],
        color: COLORS['gray_300'],
        background: COLORS['gray_100'],
      },
      loading: {
        border: COLORS['white'],
        color: COLORS['brand_900'],
        background: COLORS['white'],
      },
    },
  },
  [BUTTON_COLOR_MONO]: {
    [BUTTON_VARIANT_CONTAINED]: {
      [BUTTON_STATUS_ENABLE]: {
        background: COLORS['gray_50'],
        border: OPACITY['gray_op_50'],
        color: COLORS['gray_700'],
      },
      [BUTTON_STATUS_HOVER]: {
        background: COLORS['gray_100'],
        border: OPACITY['gray_op_50'],
        color: COLORS['gray_700'],
      },
      [BUTTON_STATUS_PRESSED]: {
        background: COLORS['gray_50'],
        border: OPACITY['gray_op_50'],
        color: COLORS['gray_700'],
      },
      [BUTTON_STATUS_SELECT]: {
        background: COLORS['gray_100'],
        border: OPACITY['gray_op_100'],
        color: COLORS['gray_900'],
      },
      [BUTTON_STATUS_DISABLE]: {
        background: COLORS['gray_100'],
        border: OPACITY['gray_op_50'],
        color: COLORS['gray_300'],
      },
    },
    [BUTTON_VARIANT_OUTLINE]: {
      [BUTTON_STATUS_ENABLE]: {
        border: OPACITY['gray_op_300'],
        color: COLORS['gray_700'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_HOVER]: {
        border: OPACITY['gray_op_300'],
        color: COLORS['gray_700'],
        background: COLORS['gray_300'],
      },
      [BUTTON_STATUS_PRESSED]: {
        border: OPACITY['gray_op_300'],
        color: COLORS['gray_700'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_SELECT]: {
        border: OPACITY['gray_op_300'],
        color: COLORS['gray_900'],
        background: COLORS['gray_50'],
      },
      [BUTTON_STATUS_DISABLE]: {
        border: COLORS['gray_100'],
        color: COLORS['gray_300'],
        background: COLORS['gray_100'],
      },
      loading: {
        border: COLORS['brand_900'],
        color: COLORS['brand_900'],
        background: COLORS['white'],
      },
    },
    [BUTTON_VARIANT_TEXT]: {
      [BUTTON_STATUS_ENABLE]: {
        border: COLORS['white'],
        color: COLORS['gray_700'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_HOVER]: {
        border: COLORS['gray_50'],
        color: COLORS['gray_700'],
        background: COLORS['gray_50'],
      },
      [BUTTON_STATUS_PRESSED]: {
        border: COLORS['white'],
        color: COLORS['gray_700'],
        background: COLORS['white'],
      },
      [BUTTON_STATUS_SELECT]: {
        border: COLORS['gray_50'],
        color: COLORS['gray_700'],
        background: COLORS['gray_50'],
      },
      [BUTTON_STATUS_DISABLE]: {
        border: COLORS['gray_100'],
        color: COLORS['gray_300'],
        background: COLORS['gray_100'],
      },
      loading: {
        border: COLORS['brand_900'],
        color: COLORS['brand_900'],
        background: COLORS['white'],
      },
    },
  },
};

export const ButtonSizeStyles = {
  [BUTTON_SIZE_X_LARGE]: {
    typography: TYPOGRAPHY.body_18_sb,
    paddingTop: SPACINGS.spacing_05.px,
    paddingBottom: SPACINGS.spacing_05.px,
    paddingLeft: SPACINGS.spacing_06.px,
    paddingRight: SPACINGS.spacing_06.px,
    height: 56,
  },
  /** FIXME: Left, Right가 디자인에서는 20px인데 20px에 대응되는 Spacing 규격이 존재하지 않아 동영님과 확인 필요 */
  [BUTTON_SIZE_LARGE]: {
    typography: TYPOGRAPHY.body_16_sb,
    paddingTop: SPACINGS.spacing_04.px,
    paddingLeft: SPACINGS.spacing_06.px,
    paddingRight: SPACINGS.spacing_06.px,
    paddingBottom: SPACINGS.spacing_04.px,
    height: 48,
  },
  [BUTTON_SIZE_MEDIUM]: {
    typography: TYPOGRAPHY.body_16_sb,
    paddingTop: SPACINGS.spacing_03.px,
    paddingBottom: SPACINGS.spacing_03.px,
    paddingLeft: SPACINGS.spacing_05.px,
    paddingRight: SPACINGS.spacing_05.px,
    height: 40,
  },
  [BUTTON_SIZE_SMALL]: {
    typography: TYPOGRAPHY.caption_14_m,
    paddingTop: SPACINGS.spacing_03.px,
    paddingBottom: SPACINGS.spacing_03.px,
    paddingLeft: SPACINGS.spacing_05.px,
    paddingRight: SPACINGS.spacing_05.px,
    height: 36,
  },
};
