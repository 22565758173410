import { useContext } from 'react';

import { AlertContext } from './AlertProvider';

export const useAlert = () => {
  const context = useContext(AlertContext);

  if (context === undefined) throw new Error('AlertContext is undefined.');

  return {
    ...context,
  };
};
