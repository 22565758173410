import { css, Drawer } from '@mui/material';

import { styles } from '~/src/constants/styles';

import { useOnboardingController } from '../../hooks/useOnboardingController';
import { OnboardingCloseButton } from '../OnboardingCloseButton/OnboardingCloseButton';
import { OnboardingRequestButton } from '../OnboardingRequestButton/OnboardingRequestButton';
import { OnboardingRequestDescription } from '../OnboardingRequestDescription/OnboardingRequestDescription';
import { OnboardingRequestTitle } from '../OnboardingRequestTitle/OnboardingRequestTitle';
import { OnboardingThumbnail } from '../OnboardingThumbnail/OnboardingThumbnail';

export const OnboardingRequestDrawer = () => {
  const { onboardingRequestPopup, onStartOnboarding } = useOnboardingController();
  const { open, onClose } = onboardingRequestPopup;

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '100%',
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          backgroundColor: 'white',
        },
      }}
    >
      <OnboardingCloseButton onClose={onClose} />
      <OnboardingThumbnail />
      <div
        className="OnboardingPopover__Body"
        css={css`
          padding: 32px 20px 24px;
        `}
      >
        <div
          css={css`
            margin-bottom: 32px;
          `}
        >
          <OnboardingRequestTitle
            css={styles['Title/title_24_sb']}
          />
          <OnboardingRequestDescription />
        </div>
        <OnboardingRequestButton onClick={onStartOnboarding} />
      </div>
    </Drawer>
  );
};

