import { useContext } from 'react';

import { LottieLoadingOverlayContext } from './LottieLoadingProvider';

export const useLottieLoadingOverlay = () => {
  const context = useContext(LottieLoadingOverlayContext);

  if (context === undefined) throw new Error('LottieLoadingProvider is not defined.');

  const {
    show,
    hide,
  } = context;

  return {
    show,
    hide,
  };
};
