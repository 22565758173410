import { css, Grid } from '@mui/material';

import { OnboardingStepBrandNameProps } from '~/src/components/app/OnboardingModal/components/OnboardingStep/OnboardingStep.types';
import { TextField } from '~/src/components/app/TextField';
import { animations, styles } from '~/src/constants/styles';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { ComponentProps } from '~/src/types/common';

export const OnboardingBrandNameTextField = (props: OnboardingStepBrandNameProps & ComponentProps) => {
  const {
    placeholder,
    helperText,
    brandName,
    disabledNext,
    onChangeBrandName,
    onNext,
    onFinish,
    ...others
  } = props;

  const laptop = useMediaQuery('laptop');

  return (
    <Grid
      container
      columns={{
        desktop: 8,
        laptop: 10,
        tablet: 8,
        mobile: 4,
      }}
    >
      <Grid
        item
        desktop={5}
        laptop={6}
        tablet={8}
        mobile={4}
      >
        <TextField
          {...others}
          fullWidth
          css={css`
            .MuiOutlinedInput-notchedOutline {
              // TODO: boon-ui 통합 필요
              border-color: var(--color-Brand-600, #FF8E26) !important;
            }
            .MuiOutlinedInput-input {
              // TODO: boon-ui 통합 필요
              color: var(--text-color-primary, #080808);
              ${styles['Title/title_32_sb']}
              font-weight: 400;
              ${laptop.down.mq} {
                font-size: 20px;
              }
            }
            .MuiFormHelperText-root {
              margin-top: 8px;
              margin-left: 4px;
              color: #DE1135;
              ${styles['Text/caption_14_r']};
            }
            ${animations.shake};
          `}
          value={brandName}
          onChange={e => onChangeBrandName(e.currentTarget.value)}
          onKeyDown={e => (e.key === 'Enter' && disabledNext === false) && (onFinish?.() ?? onNext?.())}
          autoFocus
          placeholder={placeholder}
          helperText={helperText}
          inputProps={{
            style: {
              animation: helperText ? 'shake 0.5s' : undefined,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
