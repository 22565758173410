import { useCallback } from 'react';

import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';

import { LogClickProps } from './LogClick.types';

/**
 * 마우스 다운, 업, 클릭 이벤트를 이용하여 로그를 기록할 때 사용합니다.
 * 여기서의 로그는 택소노미와 같은 행동 분석 도구의 로그를 의미합니다.
 */
export const ClickLogger = ({
  clickEventType = 'click',
  children,
  taxonomy,
}: LogClickProps) => {
  const sendTaxonomyEvent = useCallback(() => {
    if (taxonomy) {
      const {
        eventName,
        eventProperties,
      } = taxonomy;

      AmplitudeTaxonomyClient.sendEvent({
        event_type: eventName,
        event_properties: eventProperties,
      });
    }
  }, [
    taxonomy,
  ]);

  return (
    <div
      role="button"
      onMouseDown={clickEventType === 'mousedown' ? sendTaxonomyEvent : undefined}
      onMouseUp={clickEventType === 'mouseup' ? sendTaxonomyEvent : undefined}
      onClick={clickEventType === 'click' ? sendTaxonomyEvent : undefined}
    >
      { children }
    </div>
  );
};
