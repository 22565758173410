import { CurvedTextManager } from '@lws/common';
import { clsx } from 'clsx';
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import { CurvedTextProps } from './CurvedText.types';

export const CurvedText = forwardRef<SVGSVGElement | undefined, CurvedTextProps>(({
  className,
  style,
  show = true,
  object,
}, ref) => {
  const svgElement = useRef<SVGSVGElement>();
  const pathElement = useRef<SVGPathElement>();
  const textPathElement = useRef<SVGTextPathElement>();
  const textElement = useRef<SVGTextElement>();

  useEffect(() => {
    if (svgElement.current && pathElement.current && textPathElement.current && textElement.current) {
      CurvedTextManager.setCurvedTextElementsStyle(
        object,
        {
          svgElement: svgElement.current,
          pathElement: pathElement.current,
          textPathElement: textPathElement.current,
          textElement: textElement.current,
        }
      );
    }
  }, [object]);

  const { parameters } = object;
  const { primaryKey } = parameters;

  const key = CurvedTextManager.getCurvedTextUniqueKey(primaryKey);

  useImperativeHandle(ref, () => svgElement.current ?? undefined);

  return (
    <svg
      ref={(element) => {
        if (element) svgElement.current = element;
      }}
      id={key}
      data-curved-text-key={key}
      className={clsx(
        'CurvedText',
        className
      )}
      style={{
        ...style,
        display: show ? 'block' : 'none',
        overflow: 'visible',
      } as CSSProperties}
    >
      <path
        ref={(path) => {
          if (path) pathElement.current = path;
        }}
        id={`${key}--Path`}
        data-curved-text-path-key={key}
        fill="none"
        stroke="none"
      />
      <text
        ref={(text) => {
          if (text) textElement.current = text;
        }}
        id={`${key}--Text`}
        data-curved-text-text-key={key}
      >
        <textPath
          ref={(textPath) => {
            if (textPath) textPathElement.current = textPath;
          }}
          id={`${key}--TextPath`}
          data-curved-text-textpath-key={key}
          xlinkHref={`#${key}--Path`}
          alignmentBaseline="central"
        />
      </text>
    </svg>
  );
});
