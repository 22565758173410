import { Tooltip as MuiTooltip } from '@mui/material';
import { Fragment } from 'react';

import { TooltipProps } from './Tooltip.types';

export const Tooltip = ({
  className,
  style,
  open = false,
  arrow = true,
  title,
  children,
  placement,
}: TooltipProps) => {
  if (!children) return (<Fragment>{ children }</Fragment>);

  return (
    <MuiTooltip
      className={className}
      style={style}
      open={open}
      arrow={arrow}
      title={title}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: 'Pretendard',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '16px',
            letterSpacing: 0,
            textAlign: 'center',
            padding: '8px 12px',
          },
        },
      }}
    >
      <div>
        { children }
      </div>
    </MuiTooltip>
  );
};
