import { css } from '@emotion/react';
import { SwitchCase } from '@lws/boon-ui';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { Nl2br } from '~/src/components/app/Nl2br';
import { MyWorkTemplatePreviewer } from '~/src/components/business/MyWorkTemplatePreviewer';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

import { PURCHASE_WINDOW_PREVIEW_TYPE_TEMPLATE, PURCHASE_WINDOW_PREVIEW_TYPE_THUMBNAIL } from './constants';
import { MyWorkThumbnailViewer } from './MyWorkThumbnailViewer';
import { usePurchaseWindowContext } from './usePurchaseWindowContext';

export const PurchaseWindowPreviewWrapper = () => {
  const laptop = useMediaQuery('laptop');
  const { t } = useTranslation(['common']);
  const { previewType } = usePurchaseWindowContext();

  return (
    <div
      css={css`
        display: flex;
        max-width: 750px;
        margin: auto;
        margin-top: 105px;
        justify-content: center;
        column-gap: 32px;
        row-gap: 32px;
        ${laptop.down.mq} {
          flex-direction: column;
          align-items: center;
        }
      `}
    >
      <div
        css={css`
          position: relative;
          width: 169px;
          height: 169px;
          border-radius: 8px;
          filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
          overflow: hidden;
        `}
      >
        <SwitchCase
          value={previewType}
          caseBy={{
            [PURCHASE_WINDOW_PREVIEW_TYPE_TEMPLATE]: (
              <MyWorkTemplatePreviewer />
            ),
            [PURCHASE_WINDOW_PREVIEW_TYPE_THUMBNAIL]: (
              <MyWorkThumbnailViewer />
            ),
          }}
        />
      </div>
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
          ${laptop.down.mq} {
            align-items: center;
            text-align: center;
            row-gap: 8px;
          }
        `}
      >
        <Typography
          variant="h4"
        >
          {t('common:payment.title')}
        </Typography>
        <Typography
          variant="body4"
          css={css`
            color: #666666;
          `}
        >
          <Nl2br>
            {t('common:payment.description')}
          </Nl2br>
        </Typography>
        {/* // MEMO: preview 텍스트는 "목업 비저닝이 완료된 후" 노출시키는 것으로 결정됨  */}
        {/* <Typography
          variant="body5"
          css={css`
            color: ${theme.app.colors['primary-color-02']};
          `}
        >
          {t('common:payment.preview')}
        </Typography> */}
      </div>
    </div>
  );
};
