import { parsePixoAppURI } from '@lws/common';

import { BACKGROUND_TYPE_NONE, BACKGROUND_TYPE_TEXTURE } from '../../constants/background';
import { getBackgroundDataType } from '../background';
import { GetBackgroundTypeArguments, getGradientCSSArguments } from './types';

export const getGradientCSS = ({
  startColor,
  endColor,
  ratio,
  degree,
}: getGradientCSSArguments) => {
  const gradientAngle = degree * -1;
  const percentage = ratio * 100;

  return `linear-gradient(${gradientAngle}deg, ${endColor}, ${percentage}%, ${startColor})`;
};

export const getBackgroundTextureFromContentId = (contentId: string) => {
  const { type, id } = parsePixoAppURI(contentId);

  if (type === 'texture') return id;

  return undefined;
};

export const getBackgroundType = ({
  background,
  textureBackgroundURI,
}: GetBackgroundTypeArguments) => {
  if (textureBackgroundURI) return BACKGROUND_TYPE_TEXTURE;
  if (background) {
    return getBackgroundDataType(background);
  }

  return BACKGROUND_TYPE_NONE;
};

