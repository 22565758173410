import getSymbolFromCurrency from 'currency-symbol-map';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { ProductPlan } from '~/src/api/v1/PolicyAPI';

export interface LocalizedPlan extends ProductPlan {
  name: string;
  characteristics: string;
  period: string;
  description: string;
  benefits: string[];
  caution: string;
  confirmMessage: string;
  forWho: string;
  originalPrice: string;
  saveMoney: string;
}

// 서버에서 plan에 대한 policy를 받아, localazy가 적용된 plans로 반환한다.
export const useLocalizedPlans = (plans?: ProductPlan[]) => {
  const router = useRouter();
  const { t } = useTranslation();

  // Editor 페이지에서 보여질 때 confirm message가 다르다. 이를 분기처리하기 위해 사용한다.
  const isEditorPage = router.pathname.includes('/editor');

  const localizedPlans = useMemo<LocalizedPlan[] | undefined>(() => {
    if (plans === undefined) return undefined;

    return (
      plans.map((plan) => {
        const originalPrice = `${plan.currency && getSymbolFromCurrency(plan.currency)}${parseFloat(plan.price) * 2}`;

        return ({
          ...plan,
          name: t(`common:plans.${plan.planType}.name`),
          characteristics: t(`common:plans.${plan.planType}.characteristics`),
          period: t(`common:plans.${plan.planType}.period`),
          description: t(`common:plans.${plan.planType}.description`),
          benefits: (t(`common:plans.${plan.planType}.benefits`, { returnObjects: true }) ?? []) as string[],
          caution: t(`common:plans.${plan.planType}.caution`),
          confirmMessage: isEditorPage ? t(`common:plans.${plan.planType}.confirmMessage_editor`) : t(`common:plans.${plan.planType}.confirmMessage`),
          forWho: t(`common:plans.${plan.planType}.for-who`),
          originalPrice: t(`common:plans.${plan.planType}.original-price`, { originalPrice: originalPrice }),
          saveMoney: t(`common:plans.${plan.planType}.save-money`),
        });
      })
    );
  }, [t, plans, isEditorPage]);

  return {
    localizedPlans,
  };
};
