import { css } from '@emotion/react';
import { useCallback } from 'react';

import { Dialog } from '~/src/components/app/Dialog';
import { TaxonomyEditorEvent } from '~/src/components/screens/Editor/utils/TaxonomyEditorEvent/TaxonomyEditorEvent';
import { USER_PLAN_FREE } from '~/src/constants/UserPlan';
import { PlanSelectorProvider } from '~/src/context/PlanSelector';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';

import { useOnceMyWorkSave } from './hooks/useOnceSaveMyWork';
import { PlanPaymentHandler } from './PlanPaymentHandler';
import { PurchaseWindowProps } from './PurchaseWindow.types';
import { PurchaseWindowHeader } from './PurchaseWindowHeader';
import { PurchaseWindowPreviewWrapper } from './PurchaseWindowPreviewWrapper';
import { usePurchaseWindowContext } from './usePurchaseWindowContext';

export const PurchaseWindow = ({
  open = false,
}: PurchaseWindowProps) => {
  const { hide } = usePurchaseWindowContext();

  const handleCloseEvent = () => {
    AmplitudeTaxonomyClient.closePurchase({
      plan: USER_PLAN_FREE,
      step: 'view_plan',
      popup_location: 'editor',
    });
    hide();
  };

  const handleSaveError = useCallback(() => {
    console.error('Can not save My Work.');

    hide();
  }, [hide]);

  // 결제 팝업이 열릴 때 최초 한 번 저장을 진행합니다.
  useOnceMyWorkSave({
    enabled: open,
    onSuccess: () => {
      // 다운로드 버튼 클릭 시 autoSave에 대한 moment를 전송 해야 합니다.
      TaxonomyEditorEvent.setTaxonomyCreateAutoSave('download');
    },
    onError: handleSaveError,
  });

  return (
    <Dialog
      open={open}
      onClose={handleCloseEvent}
      showCloseButton={false}
      css={css`
        & .MuiDialog-container .MuiPaper-root {
          width: 100%;
          min-height: 100vh;
          margin: 0;
        }
      `}
    >
      <div
        className="PurchaseDialog__Container"
        css={css`
          padding: 0 20px;
        `}
      >
        <PlanSelectorProvider>
          <PurchaseWindowHeader
            onClickCloseIcon={handleCloseEvent}
          />
          <PurchaseWindowPreviewWrapper />
          <div>
            <PlanPaymentHandler />
          </div>
        </PlanSelectorProvider>
      </div>
    </Dialog>
  );
};

