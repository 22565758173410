import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { ProductPlan } from '~/src/api/v1/PolicyAPI';
import { PlanContainer } from '~/src/components/business/PlanContainer/PlanContainer';
import { USER_PLAN_ONE_SHOT, USER_PLAN_YEARLY } from '~/src/constants/UserPlan';
import { usePlansContext } from '~/src/context/PlansProvider';
import { AmplitudeTaxonomyClient } from '~/src/helpers/AmplitudeTaxonomyClient';
import { usePlan } from '~/src/hooks/v2/Account';
import { useAuth } from '~/src/hooks/v2/Account/useAuth';
import { usePayment } from '~/src/hooks/v2/Account/usePayment';

import { usePurchaseWindowContext } from './usePurchaseWindowContext';

export const PlanPaymentHandler = () => {
  const { t } = useTranslation(['common']);
  // const { plans } = PlanCheckoutContext();
  const { plans } = usePlansContext();
  const router = useRouter();
  const { getAuthToken, callOneShotPayment, callSubscriptionPayment } = usePayment();

  const { userId } = useAuth();

  const { userPlan, planExpirationDate } = usePlan();
  const { currentMyWorkId } = usePurchaseWindowContext();

  const checkoutProduct = useCallback((productPlan: ProductPlan | undefined) => {
    const notAllowErrorMsg = t('common:error.not-allowed-guest');
    const noSelectedPlanErrorMsg = t('common:error.no-selected-plan');
    const noMyWorkErrorMsg = t('common:error.no-mywork');
    const cannotOpenPaymentModalMsg = t('common:info.cannot-access-payment-modal');
    const isPlanExpired = planExpirationDate && (Date.now() > planExpirationDate);

    if (userId === undefined) throw Error(notAllowErrorMsg);
    if (productPlan === undefined) throw Error(noSelectedPlanErrorMsg);
    if (currentMyWorkId === undefined) throw Error(noMyWorkErrorMsg);
    if (productPlan.planType === 'yearly' && (userPlan === 'yearly' || isPlanExpired)) {

      toast.info(cannotOpenPaymentModalMsg);

      return;
    }

    // 결제 요청 인증 토큰 재호출
    getAuthToken.refetch();

    // Plan 선택 유형에 따른 결제창 호출
    const callPayment = productPlan?.planType === USER_PLAN_ONE_SHOT ? callOneShotPayment : callSubscriptionPayment;

    callPayment(productPlan, currentMyWorkId);

    // 결제 모듈에서 모달 창 뜬 이후로 Callback을 지원하지 않아서,
    // 결제모듈 호출 시점에 이 Taxonomy Event 이벤트를 호출함
    AmplitudeTaxonomyClient.viewPurchaseModal();
  }, [
    currentMyWorkId,
    userId,
    planExpirationDate,
    userPlan,
    getAuthToken,
    callOneShotPayment,
    callSubscriptionPayment,
    t,
  ]);

  const onSelect = (productPlan : ProductPlan | undefined) => {
    if(userId) {
      // 전역 이벤트에서 결제 중임을 알 수 있게 URL Param 추가
      router.query.isPayment = 'true';
      router.push(router);

      // 주문 결제창 호출
      checkoutProduct(productPlan);
    }
  };

  return (
    <PlanContainer
      plans={plans ?? []}
      onClickOneShotPlan={() => {
        const oneshotPlan = (plans ?? []).find(({ planType }) => planType === USER_PLAN_ONE_SHOT);

        onSelect(oneshotPlan);
      }}
      onClickYearlyPlan={() => {
        const yearlyPlan = (plans ?? []).find(({ planType }) => planType === USER_PLAN_YEARLY);

        onSelect(yearlyPlan);
      }}
    />
  );
};
