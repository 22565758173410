
export const SPACINGS = {
  spacing_01: {
    px: '2px',
    rem: '0.125rem',
  },
  spacing_02: {
    px: '4px',
    rem: '0.25rem',
  },
  spacing_03: {
    px: '8px',
    rem: '0.5rem',
  },
  spacing_04: {
    px: '12px',
    rem: '0.75rem',
  },
  spacing_05: {
    px: '16px',
    rem: '1rem',
  },
  spacing_06: {
    px: '24px',
    rem: '1.5rem',
  },
  spacing_07: {
    px: '32px',
    rem: '2rem',
  },
  spacing_08: {
    px: '36px',
    rem: '2.25rem',
  },
  spacing_09: {
    px: '40px',
    rem: '2.5rem',
  },
  spacing_10: {
    px: '48px',
    rem: '3rem',
  },
  spacing_11: {
    px: '60px',
    rem: '3.75rem',
  },
  spacing_12: {
    px: '80px',
    rem: '5rem',
  },
  spacing_13: {
    px: '120px',
    rem: '7.5rem',
  },
  spacing_14: {
    px: '160px',
    rem: '10rem',
  },
};
