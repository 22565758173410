import { Fragment } from 'react';

import { Nl2brProps } from './Nl2br.types';

export const Nl2br = ({
  children,
}: Nl2brProps) => {
  if (typeof children !== 'string') throw new Error('children is not string');

  const lines = children.split('\n');

  return (
    <Fragment>
      {
        lines.map((line, key) => (
          <Fragment key={key}>
            { line }<br />
          </Fragment>
        ))
      }
    </Fragment>
  );
};
