import { css } from '@emotion/react';
import classNames from 'classnames';
import Link from 'next/link';
import { useMemo } from 'react';
import ReactLoading from 'react-loading';

import { ButtonProps } from './Button.types';
import Styles from './index.module.scss';

export const Button: React.FC<ButtonProps> = (props) => {
  const color = useMemo(() => props.color ?? 'orange', [props.color]);
  const size = useMemo(() => props.size ?? 'medium', [props.size]);
  const shape = useMemo(() => props.shape ?? 'normal', [props.shape]);
  const Tag = useMemo(() => props.link ? Link : 'button', [props.link]);

  const loading = props.loading ?? false;

  return (
    <Tag
      { ...props }
      href={props.link ?? ''}
      className={classNames(
        props.className,
        Styles.Button,
        { [Styles.Orange]: color === 'orange' },
        { [Styles.OrangeGradient]: color === 'orange-gradient' },
        { [Styles.Plain]: color === 'plain' },

        { [Styles.ExtraLarge]: size === 'extra-large' },
        { [Styles.Large]: size === 'large' },
        { [Styles.Medium]: size === 'medium' },
        { [Styles.Small]: size === 'small' },

        { [Styles.Normal]: shape === 'normal' },
        { [Styles.Rounded]: shape === 'rounded' },

        { [Styles.Link]: Boolean(props.link) }
      )}
      style={props.style}
      css={css`
        position: relative;
        overflow: hidden;
      `}
      disabled={loading}
      title={props.title}
    >
      { loading && <LoadingIndicator /> }
      { props.children }
    </Tag>
  );
};

const LoadingIndicator = () => {
  return (
    <div
      className="LoadingIndicator"
      css={css`
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: rgba(0, 0, 0, .2);
      `}
    >
      <ReactLoading
        type="spin"
        width={24}
        height={24}
        css={css`
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        `}
      />
    </div>
  );
};
