import { createContext, PropsWithChildren, useCallback, useState } from 'react';

import { Dialog } from '../Dialog';
import { DialogProps } from '../Dialog/Modal.types';
import { UseConfirmArguments } from './useConfirm.types';

/**
 * 사용자 입력을 받을 수 있는 Confirm창을 호출하기 위한 Context를 정의합니다.
 */
export const ConfirmContext = createContext<UseConfirmArguments | undefined>(undefined);

let index = 0;

export const ConfirmProvider = ({ children }: PropsWithChildren) => {
  const [confirms, setConfirms] = useState<Map<number, DialogProps>>(new Map());

  const create = useCallback((props: Omit<DialogProps, 'open'>) => {
    const newConfirms = new Map(confirms);
    const currentIndex = index;
    const newProps = {
      ...props,
      open: false,
    };

    newConfirms.set(currentIndex, newProps);

    setConfirms(newConfirms);

    index += 1;

    const show = () => {
      const confirms = new Map(newConfirms);
      confirms.set(currentIndex, { ...newProps, open: true });
      setConfirms(confirms);
    };

    const hide = () => {
      const confirms = new Map(newConfirms);
      confirms.set(currentIndex, { ...newProps, open: false });
      setConfirms(confirms);
    };

    return {
      show,
      hide,
    };
  }, []);

  return (
    <ConfirmContext.Provider
      value={{
        create,
      }}
    >
      { children }
      {
        Array.from(confirms).map(([key, confirm]) => {
          return (
            <Dialog
              key={key}
              { ...confirm }
            />
          );
        })
      }
    </ConfirmContext.Provider>
  );
};
