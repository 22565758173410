import { css } from '@emotion/react';
import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import Image, { StaticImageData } from 'next/image';

import { Nl2br } from '~/src/components/app/Nl2br';
import { ComponentProps } from '~/src/types/common';

export interface Benefit {
  thumbnail: StaticImageData;
  title: string;
  description: string;
}

interface BenefitCardProps extends ComponentProps {
  benefit: Benefit;
}

export const BenefitCard = (props: BenefitCardProps) => {
  const { benefit } = props;
  const { thumbnail, title, description } = benefit;

  const theme = useTheme();

  return (
    <div
      className={classNames(
        'BenefitCard',
        props.className
      )}
      css={css`
        width: 100%;
        border-radius: 10px;
        background-color: ${theme.app.colors['neutral-color-09']};
        min-height: 480px;
        overflow: hidden;
      `}
    >
      <div
        css={css`
          width: 100%;
          height: 280px;
          border-radius: 10px;
          background-color: ${theme.app.colors['second-color-1']};
        `}
      >
        <Image
          className="BenefitCard__Thumbnail"
          data-testid="BenefitCard__Thumbnail"
          src={thumbnail.src}
          alt={description}
          width={thumbnail.width}
          height={thumbnail.height}
          css={css`
            width: 100%;
            height: 100%;
            object-fit: cover;
          `}
        />
      </div>
      <div
        css={css`
          padding: 34px 10px 50px;
          border-radius: 10px;
          text-align: center;
        `}
      >
        <Typography
          variant="h5"
          data-testid="BenefitCard__Title"
          css={css`
            color: ${theme.app.colors['neutral-color-01']};
          `}
        >
          {title}
        </Typography>
        <Typography
          variant="body6"
          data-testid="BenefitCard__Description"
          css={css`
            margin-top: 16px;
            color: ${theme.app.colors['neutral-color-05']};
          `}
        >
          <Nl2br>
            {description}
          </Nl2br>
        </Typography>
      </div>
    </div>
  );
};
