import { Fragment, useCallback, useEffect, useState } from 'react';

import { ImageProps } from './Image.types';

/**
 * 이미지를 불러오는 과정에서 다양한 경우의 수를 대응하기 위한 컴포넌트입니다.
 */
export const Image = ({
  alt,
  title,
  className,
  imageClassName,
  style,
  imageStyle,
  src,
  customComponent,
  loading,
  fallback,
  onComplete,
  onLoading,
  onError,
}: ImageProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const renderChildren = useCallback(() => {
    if (isSuccess && customComponent === undefined) return (
      <img
        className={imageClassName}
        style={imageStyle}
        src={src}
        alt={alt}
        title={title}
      />
    );
    else if (isSuccess && customComponent) return customComponent(src);
    else if (isLoading) return (loading?.(src) ?? <Fragment />);
    else if (isError) return (fallback?.(src) ?? <Fragment />);

    return <Fragment />;
  }, [
    src,
    isSuccess,
    isLoading,
    isError,
    imageStyle,
    imageClassName,
    customComponent,
    loading,
    fallback,
  ]);

  useEffect(() => {
    setIsLoading(true);
    onLoading?.(src);

    const image = new window.Image();

    image.src = src;
    image.onload = () => {
      setIsSuccess(true);
      setIsLoading(false);
      setIsError(false);
      onComplete?.(src);
    };
    image.onerror = () => {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      onError?.(src);
    };
  }, [
    src,
  ]);

  return (
    <div
      className={className}
      style={{
        position: 'relative',
        ...style,
      }}
      data-testid="Image"
    >
      { renderChildren() }
    </div>
  );
};
