/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { SolidColorBackgroundLayerProps } from './SolidColorBackgroundLayer.types';

export const SolidColorBackgroundLayer = ({
  color = '#FFFFFF',
}: SolidColorBackgroundLayerProps) => {
  return (
    <div
      className="SolidColorBackgroundLayer"
      style={{
        backgroundColor: color,
      }}
      css={css`
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      `}
    />
  );
};
