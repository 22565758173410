// TODO: 다국어 처리
export const STEP_TEXTS = [
  {
    title: 'Enter your Business name',
    description: 'You can always change these later',
  },
  {
    title: 'Select Your Industry',
    description: 'This will help us find the right logo styles for your business',
  },
  {
    title: 'Choose your color inspiration',
    description: 'pick the color that represents your business',
  },
];

export const ALL_STEP_LENGTH = Object.keys(STEP_TEXTS).length;

export const ONBOARDING_STEP_DEFAULT = '';
export const ONBOARDING_STEP_NAME_BRAND_NAME = 'brandName';
export const ONBOARDING_STEP_NAME_INDUSTRY = 'industry';
export const ONBOARDING_STEP_NAME_COLOR = 'color';
export const ONBOARDING_STEP_FINISHED = 'finished';

export const ONBOARDING_STEP_NAMES_BY_ORDER: string[] = [
  ONBOARDING_STEP_NAME_BRAND_NAME,
  ONBOARDING_STEP_NAME_INDUSTRY,
  ONBOARDING_STEP_NAME_COLOR,
  ONBOARDING_STEP_FINISHED,
];
