import { useEffect, useState } from 'react';

import { UseParticleTimerArguments } from './useParticleTimer.types';

export const useParticleTimer = ({
  duration,
  delay,
  onEnd,
}: UseParticleTimerArguments) => {
  const [confettiEndTime, setConfettiEndTime] = useState<number>();

  useEffect(() => {
    setConfettiEndTime(Date.now() + duration);
  }, [
    duration,
  ]);

  useEffect(() => {
    if (onEnd && confettiEndTime) {
      const timeout = window.setTimeout(() => onEnd?.(), duration + delay);

      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [onEnd, confettiEndTime]);

  return {
    confettiEndTime,
  };
};
