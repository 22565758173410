import { User } from '~/src/types/user';
import { isCompleteBrandNameOnboardingStep } from '~/src/utils/common';

/**
 * 페이지 진입 시 온보딩 요청 팝업의 노출 여부를 확인하기 위한 함수
 * @returns true  : 온보딩 요청 팝업을 노출해야 함
 * @returns false : 온보딩 요청 팝업을 노출하지 않아야함
 */
export const checkShowOnboardingByUser = (user: User | undefined): boolean => {
  // 유저 정보가 없는 경우 : 온보딩 요청 팝업을 노출함
  if (!user) return true;

  // 온보딩 미노출 요건 : 브랜드명 입력 단계를 넘어갔는가?
  if (isCompleteBrandNameOnboardingStep(user.onboardingStep)) return false;

  // 아무 조건도 충족하지 않는 경우 : 온보딩 요청 팝업을 노출함
  return true;
};
