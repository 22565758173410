import { createContext, PropsWithChildren, useCallback, useRef, useState } from 'react';

import { Alert } from './Alert';
import { AlertProps } from './Alert.types';
import { AlertContextArguments } from './AlertProvider.types';

export const AlertContext = createContext<AlertContextArguments | undefined>(undefined);

export const AlertProvider = ({
  children,
}: PropsWithChildren) => {
  const index = useRef(0);
  const [alerts, setAlerts] = useState<Map<number, AlertProps>>(new Map());

  const create = useCallback((props: Omit<AlertProps, 'open'>) => {
    const clone = new Map(alerts);
    const newAlert: AlertProps = {
      ...props,
      open: false,
    };
    const currentIndex = index.current;
    clone.set(currentIndex, newAlert);
    setAlerts(clone);

    const show = () => {
      const newClone = new Map(clone);
      newClone.set(currentIndex, { ...newAlert, open: true });

      setAlerts(newClone);
    };

    const hide = () => {
      const newClone = new Map(clone);
      newClone.set(currentIndex, { ...newAlert, open: false });

      setAlerts(newClone);
    };

    index.current += 1;

    return {
      show,
      hide,
    };
  }, [
    alerts,
  ]);

  return (
    <AlertContext.Provider
      value={{
        create,
      }}
    >
      { children }
      {
        Array.from(alerts).map(([key, alert]) => {
          return (
            <Alert
              key={key}
              { ...alert }
            />
          );
        })
      }
    </AlertContext.Provider>
  );
};
