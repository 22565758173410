import { useQuery } from '@tanstack/react-query';

import { ProductPlan } from '~/src/api/v1/PolicyAPI';
import { paymentsAPIClient } from '~/src/api/v2/PaymentsAPI';
import { PAYPLE_AUTH_TOKEN_VALID_TIME } from '~/src/constants/Payment';
import { getPayRequestParams } from '~/src/utils/payment';

import { useAuth } from './useAuth';

declare global {
  interface Window {
    paypleGpayPaymentRequest: any;
  }
}

export const usePayment = () => {
  const { userId, email, name } = useAuth();

  const getAuthToken = useQuery({
    queryKey: ['get-auth-token', userId],
    queryFn: () => {
      return paymentsAPIClient.getAuthToken().then(result => result);
    },
    refetchOnWindowFocus : 'always',
    staleTime: PAYPLE_AUTH_TOKEN_VALID_TIME,
  });

  const authToken = getAuthToken?.data?.token;

  // 단건 결제 모듈 호출
  const callOneShotPayment = ({currency, planName, price, planId}: ProductPlan, myWorkId : string | null = null) => {
    // 인증 토큰이 없거나 로그인된 세션 정보가 없으면 진행하지 않는다.
    if(!authToken || !userId) return;

    const productInfo = {planId, planName, price, currency };
    const userInfo = {email, name, userId};

    const payRequestParams = getPayRequestParams(authToken, productInfo, userInfo, myWorkId);

    // 단건 결제시 Callback URL
    payRequestParams.resultUrl = process.env.NEXT_PUBLIC_PAYPLE_ONESHOT_CALLBACK_URL;

    // 결제요청 함수 호출
    window?.paypleGpayPaymentRequest(payRequestParams);
  };

  // 구독 결제 모듈 호출
  const callSubscriptionPayment = ({currency, planName, price, planId}: ProductPlan, myWorkId : string | null = null) => {
    // 인증 토큰이 없거나 로그인된 세션 정보가 없으면 진행하지 않는다.
    if(!authToken) return;

    const productInfo = {planId, planName, price, currency };
    const userInfo = {email, name, userId};
    const payRequestParams = getPayRequestParams(authToken, productInfo, userInfo, myWorkId);

    // 구독 결제시 Callback URL
    payRequestParams.resultUrl = process.env.NEXT_PUBLIC_PAYPLE_SUBSCRIPTION_CALLBACK_URL;

    // 결제요청 함수 호출
    window?.paypleGpayPaymentRequest(payRequestParams);
  };

  return {
    getAuthToken,
    authToken,
    callOneShotPayment,
    callSubscriptionPayment,
  };
};
