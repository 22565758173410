import { createContext } from 'react';

import { ResourceMetaContextValues, ResourceMetaProviderProps } from './ResourceMetaProvider.types';

export const ResourceMetaContext = createContext<ResourceMetaContextValues | undefined>(undefined);

export const ResourceMetaProvider = ({
  children,
  symbol,
  texture,
}: ResourceMetaProviderProps) => {
  return (
    <ResourceMetaContext.Provider
      value={{
        symbol,
        texture,
      }}
    >
      { children }
    </ResourceMetaContext.Provider>
  );
};
