import { getObjectPosition } from '@lws/common';
import { useContext } from 'react';

import { RendererContext } from '../Renderer/RendererProvider';
import { ObjectPositionerProps } from './ObjectPositioner.types';

export const ObjectPositioner = ({
  children,
  object,
}: ObjectPositionerProps) => {
  const rendererContext = useContext(RendererContext);

  if (rendererContext === undefined) throw new Error('RendererProvider is not defined.');

  const {
    width: canvasWidth,
    height: canvasHeight,
  } = rendererContext;

  const {
    frameWidth,
    frameHeight,
    centerX,
    centerY,
  } = object;

  const { left, top } = getObjectPosition({
    canvasWidth,
    canvasHeight,
    frameWidth,
    frameHeight,
    centerX,
    centerY,
  });

  return (
    <div
      className="ObjectPositioner"
      style={{
        position: 'absolute',
        left,
        top,
        width: frameWidth,
        height: frameHeight,
      }}
    >
      { children }
    </div>
  );
};
