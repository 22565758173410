import { css } from '@emotion/react';

import { LottieLoadingIndicator } from '~/src/components/app/LottieLoadingIndicator';

export const Loading = () => {
  return (
    <div
      className="Loading"
      css={css`
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, .4);
      `}
    >
      <LottieLoadingIndicator
        type="circle"
        css={css`
          width: 76px;
          height: 76px;
        `}
      />
    </div>
  );
};
