import { init } from '@amplitude/analytics-browser';
import { Fragment, useEffect } from 'react';

export const Amplitude = () => {
  useEffect(() => {
    if (typeof process.env.NEXT_PUBLIC_AMPLITUDE_TAXONOMY_API_KEY === 'string') {
      init(
        process.env.NEXT_PUBLIC_AMPLITUDE_TAXONOMY_API_KEY,
        {
          defaultTracking: true,
        }
      );
    }
  }, []);

  return (
    <Fragment />
  );
};
