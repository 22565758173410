import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

import { OnboardingRequestDrawer } from './components/OnboardingRequestDrawer/OnboardingRequestDrawer';
import { OnboardingRequestPopup } from './components/OnboardingRequestPopup/OnboardingRequestPopup';

/**
 * 유저 상태를 확인해 온보딩 요청 팝업을 자동으로 노출하는 컴포넌트
 * 온보딩 요청을 수락하는 경우 온보딩 모달을 노출하는 역할을 포함
 */
export const OnboardingRequest = () => {
  const mobile = useMediaQuery('mobile');

  return (
    <>
      {mobile.only.is ? (
        <OnboardingRequestDrawer />
      ) : (
        <OnboardingRequestPopup />
      )}
    </>
  );
};
