import styled from '@emotion/styled';
import { css, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';

import BannerImage from './popup_thumbnail.jpg';
import { RebrandModalProps } from './RebrandModal.types';

/**
 * Logo Maker Shop에서 Boon으로 브랜드를 개편함을 알리는 Modal입니다.
 */
export const RebrandModal = ({
  show,
  onClose,
}: RebrandModalProps) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    onClose?.(false);
  }, [
    onClose,
  ]);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      css={css`
        .MuiDialog-paper {
          max-width: 464px;
          width: 100%;
          border-radius: 16px;
        }
      `}
    >
      <RebrandBanner
        className="DialogHeader"
      />
      <ContentWrapper>
        <RebrandTitle
          className="RebrandModalTitle"
        >
          { t('common:modal.rebrand.title') }
        </RebrandTitle>
        <RebrandContent
          className="RebrandModalContent"
        >
          { t('common:modal.rebrand.content') }
        </RebrandContent>
      </ContentWrapper>
      <Actions>
        <StartButton
          onClick={handleClose}
        >
          Get Started
        </StartButton>
      </Actions>
    </Dialog>
  );
};

const RebrandBanner = styled('div')`
  position: relative;
  height: 310px;
  padding: 0;
  margin-bottom: 32px;
  background: #282220;
  background-image: url(${BannerImage.src});
  background-size: cover;
  background-position: center;
`;

const RebrandTitle = styled('h1')`
  /* Title/title_24_sb */
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  text-align: center;
`;

const ContentWrapper = styled(DialogContent)`
  padding: 0 16px !important;
`;

const RebrandContent = styled('p')`
  /* Text/body_16_r */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-align: center;
  color: #757575;
  padding: 0 24px;
`;

const Actions = styled(DialogActions)`
  align-items: center;
  justify-content: center;
  padding: 32px 40px;
`;

const StartButton = styled('button')`
  border-radius: 8px;
  width: 100%;
  padding: 12px 20px;
  /* Text/body_16_sb */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  background-color: #FF8E26;
  color: white;
  border: none;
  cursor: pointer;
`;
