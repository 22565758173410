import { useContext } from 'react';

import { ParticleContext } from './ParticleProvider';

export const useParticle = () => {
  const context = useContext(ParticleContext);

  if (context === undefined) throw new Error('ParticleProvider is not defined.');

  const {
    work,
    stop,
  } = context;

  return {
    work,
    stop,
  };
};
