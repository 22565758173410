import { createContext } from 'react';

import { ResourcesContextValues, ResourcesProviderProps } from './ResourcesProvider.types';

export const ResourcesContext = createContext<ResourcesContextValues | undefined>(undefined);

export const ResourcesProvider = ({
  children,
  texture,
  symbol,
  font,
  image,
}: ResourcesProviderProps) => {
  return (
    <ResourcesContext.Provider
      value={{
        font,
        symbol,
        texture,
        image,
      }}
    >
      { children }
    </ResourcesContext.Provider>
  );
};
