/**
 * TODO:
 * LegacyGlobalThemeProvider는 웹 페이지 전역적으로 사용되는 테마 프로바이더임.
 * PD가 중간에 변경되면서 디자인 시스템이 대대적으로 변경 되게 되었는데 이 작업은 정식으로 착수하지 않아 기존 테마 프로바이더와 GlobalThemeProvider가 공존하게 되었음.
 * 디자인 시스템 작업 착수 전까지 GlobalThemeProvider를 컴포넌트마다 래핑하여 UI 작업을 진행해야 함.
 *
 * 그러므로 추후에는 다음과 같은 코드를 삭제해야 함.
 * 1. web 패키지에 삽입 되어 있는 최상위 ThemeProvider를 제외한 나머지 Provider
 * 2. boon-ui 패키지에 삽입 되어 있는 컴포넌트를 래핑하는 Provider
 */
export * from './GlobalThemeProvider';
export * from './LegacyGlobalThemeProvider';
