
/**
 * Last Updated: 2024.02.13
 */

export const NORMAL_FONT = 'Pretendard';

export const TYPOGRAPHY = {
  'display_64_sb': {
    'token': 'h',
    'level': 'display',
    'fontWeight': 600,
    'fontSize': '64px',
    'lineHeight': '110%',
  },
  'title_56_sb': {
    'token': 'h',
    'level': 'title',
    'fontWeight': 600,
    'fontSize': '56px',
    'lineHeight': '120%',
  },
  'title_44_sb': {
    'token': 'h',
    'level': 'title',
    'fontWeight': 600,
    'fontSize': '44px',
    'lineHeight': '120%',
  },
  'title_36_sb': {
    'token': 'h',
    'level': 'title',
    'fontWeight': 600,
    'fontSize': '36px',
    'lineHeight': '130%',
  },
  'title_32_sb': {
    'token': 'h',
    'level': 'title',
    'fontWeight': 600,
    'fontSize': '32px',
    'lineHeight': '130%',
  },
  'title_24_sb': {
    'token': 'h',
    'level': 'title',
    'fontWeight': 600,
    'fontSize': '24px',
    'lineHeight': '140%',
  },
  'title_20_sb': {
    'token': 'h',
    'level': 'title',
    'fontWeight': 600,
    'fontSize': '20px',
    'lineHeight': '140%',
  },
  'body_18_sb': {
    'token': 'p',
    'level': 'body',
    'fontWeight': 600,
    'fontSize': '18px',
    'lineHeight': '150%',
  },
  'body_16_sb': {
    'token': 'p',
    'level': 'body',
    'fontWeight': 600,
    'fontSize': '16px',
    'lineHeight': '150%',
  },
  'body_18_r': {
    'token': 'p',
    'level': 'body',
    'fontWeight': 400,
    'fontSize': '18px',
    'lineHeight': '150%',
  },
  'body_16_r': {
    'token': 'p',
    'level': 'body',
    'fontWeight': 400,
    'fontSize': '16px',
    'lineHeight': '150%',
  },
  'caption_14_r': {
    'token': undefined,
    'level': 'caption',
    'fontWeight': 400,
    'fontSize': '14px',
    'lineHeight': '150%',
  },
  'caption_14_m': {
    'token': undefined,
    'level': 'caption',
    'fontWeight': 500,
    'fontSize': '14px',
    'lineHeight': '150%',
  },
  'caption_12_r': {
    'token': undefined,
    'level': 'caption',
    'fontWeight': 400,
    'fontSize': '12px',
    'lineHeight': '150%',
  },
  'caption_12_m': {
    'token': undefined,
    'level': 'caption',
    'fontWeight': 500,
    'fontSize': '12px',
    'lineHeight': '150%',
  },
  'caption_10_r': {
    'token': undefined,
    'level': 'caption',
    'fontWeight': 400,
    'fontSize': '10px',
    'lineHeight': '150%',
  },
  'caption_10_m': {
    'token': undefined,
    'level': 'caption',
    'fontWeight': 500,
    'fontSize': '10px',
    'lineHeight': '150%',
  },
};
