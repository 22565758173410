export const STICKER_OBJECT_TYPE = 'CLStickerView';
export const TEXT_OBJECT_TYPE = 'CLTextView';
export const NONE_OBJECT_TYPE = 'none';
export const MIXED_OBJECT_TYPE = 'mixed';

export const TEXT_OBJECT_DEFAULT_FONT_SIZE = 50;
export const TEXT_KERNING_SLIDER_VALUE_MIN = 0;
export const TEXT_KERNING_SLIDER_VALUE_MAX = 1;
export const TEXT_KERNING_SLIDER_VALUE_CENTER = (TEXT_KERNING_SLIDER_VALUE_MIN + TEXT_KERNING_SLIDER_VALUE_MAX) / 2;
export const TEXT_KERNING_MULTIPLIER = 35;

export const TEXT_OBJECT_PLAIN_TYPE = 'plain-text';
export const TEXT_OBJECT_CURVED_TYPE = 'curved-text';

export const CURVED_TEXT_DIRECTION_UP = 1;
export const CURVED_TEXT_DIRECTION_DOWN = -1;
export const CURVED_TEXT_DIRECTION_NO = 0;

export const TEXT_ALIGNMENT_LEFT = 0;
export const TEXT_ALIGNMENT_CENTER = 1;
export const TEXT_ALIGNMENT_RIGHT = 2;

export const TEXT_ALIGNMENT_LEFT_LABEL = 'left';
export const TEXT_ALIGNMENT_CENTER_LABEL = 'center';
export const TEXT_ALIGNMENT_RIGHT_LABEL = 'right';

export const FONT_LOAD_TIMEOUT = 5000;

export const MIN_FONT_SIZE = 12;
export const MAX_FONT_SIZE = 250;

export const MIN_KERNING_SLIDER_VALUE = 0;
export const MAX_KERNING_SLIDER_VALUE = 1;

export const MAX_TEXTURE_IMAGE_CENTER = 1;
export const MIN_TEXTURE_IMAGE_CENTER = 0;
