import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';

import { useOnboardingController } from '../../OnboardingRequest/hooks/useOnboardingController';

interface UseOnboardingStepControllerProps {
  steps: [string, ReactNode][];
  currentStepName: string;
  setStepIndex: (newStepIndex: number) => void;
}

/**
 * 온보딩 각 단계를 URL로 관리하기 위한 컨트롤러로써 브라우저의 뒤로가기, 앞으로가기 기능을 통해 각 온보딩 단계를 움직일 수 있도록 함
 */
export const useOnboardingStepController = ({
  steps,
  currentStepName,
  setStepIndex,
}: UseOnboardingStepControllerProps) => {
  const router = useRouter();
  const { onboardingModal } = useOnboardingController();
  const { open } = onboardingModal;

  useEffect(() => {
    if (open === false) return;

    // 현재 스텝을 URL Query Params로 관리하는 로직
    router.push({ query: { stepName: currentStepName }}, undefined, { shallow: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepName]);

  useEffect(() => {
    if (open === false) return;

    // router.query가 변경되었을 때, stepName이라는 key를 가진 값이 있는지 확인하고 있는 경우 해당 스텝으로 이동하는 로직
    const changedStepName = router.query.stepName;
    const stepIndex = steps.findIndex(([stepName]) => stepName === changedStepName);

    if (stepIndex === -1) return;

    setStepIndex(stepIndex);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);
};
