import AmazonWebServicesLogo from '@/assets/svgs/amazon_web_services_logo.svg';
import GoogleCloudLogo from '@/assets/svgs/google_cloud_logo.svg';
import JCBLogo from '@/assets/svgs/jcb_logo.svg';
import MastercardLogo from '@/assets/svgs/mastercard_logo.svg';
import VisaLogo from '@/assets/svgs/visa_logo.svg';
import { PartnershipLogo } from '~/src/types/common';

export const SERVICE_NAME = 'Boon';
export const SOCIAL_SHARE_HASHTAG = 'boonlogomaker';

export const DEFAULT_SIGNIN_REDIRECT_URI = '/logo-preview';

export const PARTNERS: PartnershipLogo[] = [
  { src: AmazonWebServicesLogo, alt: 'amazon_web_services_logo' },
  { src: MastercardLogo, alt: 'mastercard_logo' },
  { src: GoogleCloudLogo, alt: 'google_cloud_logo' },
  { src: VisaLogo, alt: 'visa_logo' },
  { src: JCBLogo, alt: 'jcb_logo' },
];
