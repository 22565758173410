import { useCallback, useState } from 'react';

interface UseDialogProps {
  defaultOpen?: boolean
}

export const useDialog = ({ defaultOpen = false }: UseDialogProps) => {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  const onOpen = useCallback(() => setOpen(true), []);

  const onClose = useCallback(() => setOpen(false), []);

  return {
    open,
    setOpen,
    onOpen,
    onClose,
  };
};
