/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { getStickerObjectResourceId, SwitchCase } from '@lws/common';
import { Fragment, useContext, useState } from 'react';
import InlineSVG from 'react-inlinesvg';

import { getFillModeStickerObject } from '../../utils/sticker';
import { ResourceMetaContext } from '../Renderer/ResourceMetaProvider';
import { ResourcesContext } from '../Renderer/ResourcesProvider';
import { SolidColorPainter } from './SolidColorPainter';
import { StickerContext } from './StickerProvider';
import { StickerTexture } from './StickerTexture';

export const SVGResourceRenderer = () => {
  const resourcesContext = useContext(ResourcesContext);
  const resourceMetaContext = useContext(ResourceMetaContext);
  const context = useContext(StickerContext);

  if (resourcesContext === undefined) throw new Error('ResourcesProvider not declared');
  if (resourceMetaContext === undefined) throw new Error('ResourceMetaProvider not declared');
  if (context === undefined) throw new Error('StickerProvider not declared');

  const [svgElement, setSVGElement] = useState<SVGElement>();
  const { object } = context;
  const { symbol } = resourcesContext;
  const {
    contentId,
    hexColor,
    decoratorIds,
  } = object;

  const resourceId = getStickerObjectResourceId(contentId);
  const resource = symbol[resourceId];

  if (resource === undefined) throw new Error(`없는 리소스입니다: ${resourceId}`);

  const { symbol: symbolMeta } = resourceMetaContext;
  const currentSymbolMeta = symbolMeta[resourceId];

  if (currentSymbolMeta === undefined) throw new Error(`리소스 메타데이터가 없습니다: ${resourceId}`);

  const { width, height } = currentSymbolMeta;
  const fillMode = getFillModeStickerObject({ color: hexColor, decorators: decoratorIds });

  return (
    <Fragment>
      <SwitchCase
        value={fillMode}
        caseBy={{
          'color': (<SolidColorPainter svg={svgElement} />),
          'texture': (<StickerTexture svg={svgElement} />),
        }}
      />
      <InlineSVG
        className="SVGRenderer"
        innerRef={(svg) => {
          if (svg) setSVGElement(svg);
        }}
        viewBox={`0 0 ${width} ${height}`}
        src={resource}
        width={width}
        height={height}
        css={css`
          display: block;
          width: 100%;
          height: 100%;
        `}
      />
    </Fragment>
  );
};
