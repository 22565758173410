import { clsx } from 'clsx';
import { forwardRef, useCallback } from 'react';

import { InfiniteScrollProps } from './InfiniteScroll.types';
import { Loader } from './Loader';

export const InfiniteScroll = forwardRef<HTMLDivElement, InfiniteScrollProps>(({
  className,
  style,
  items,
  isLoading = false,
  threshold,
  children,
  loader,
  hasNextPage,
  emptyComponent,
  fetchNextPage,
}, ref) => {
  const isShowLoader = isLoading && hasNextPage;
  const isShowNextPageFetchTrigger = !isLoading && hasNextPage;

  const handleIntersectionLoader = useCallback(() => {
    if (isLoading) return;

    fetchNextPage?.();
  }, [isLoading, fetchNextPage]);

  return (
    <div
      ref={ref}
      className={clsx(
        'InfiniteScroll',
        className
      )}
      style={{
        ...style,
        position: 'relative',
      }}
    >
      { emptyComponent ? emptyComponent : children }
      {
        isShowNextPageFetchTrigger &&
          <Loader
            items={items}
            threshold={threshold}
            callback={handleIntersectionLoader}
          />
      }
      { isShowLoader && loader }
    </div>
  );
});
