import { TemplateObject, TemplateStickerObject } from '@lws/types';
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getObjectsWithPrimaryKeyFromObjects } from '~/src/utils/editor/template';

import { addStickerObjectThunk, overwriteMyWorkFromTemplateThunk } from '../thunk/EditorThunk';

export const ObjectAdapter = createEntityAdapter<TemplateObject>({
  selectId: (model) => model.parameters.primaryKey,
});

const initialState = ObjectAdapter.getInitialState({
  selectedObjects: [] as string[],
  preselectedObjects: [] as TemplateObject[],
});

export type ObjectSliceState = typeof initialState;

export const objectsSlice = createSlice({
  name: 'Objects',
  initialState,
  reducers: {
    initialize: ObjectAdapter.setAll,
    addOne: ObjectAdapter.addOne,
    addMany: ObjectAdapter.addMany,
    setAll: ObjectAdapter.setAll,
    setOne: ObjectAdapter.setOne,
    setMany: ObjectAdapter.setMany,
    removeOne: ObjectAdapter.removeOne,
    removeMany: ObjectAdapter.removeMany,
    updateOne: ObjectAdapter.updateOne,
    updateMany: ObjectAdapter.updateMany,
    initializeSelectedObjects: (state, action: PayloadAction<string[]>) => {
      state.selectedObjects = action.payload;
    },
    removeSelectedObjects: (state, action: PayloadAction<TemplateObject[]>) => {
      state.selectedObjects = [];
      state.preselectedObjects = [];
      ObjectAdapter.removeMany(state, action.payload.map(({ parameters }) => parameters.primaryKey));
    },
    setSelectedObjectsAll: (state, action: PayloadAction<string[]>) => {
      state.selectedObjects = action.payload;
    },
    setPreselectedObjectsAll: (state, action: PayloadAction<TemplateObject[]>) => {
      state.preselectedObjects = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // 그래픽 요소를 추가합니다.
      .addCase(addStickerObjectThunk.fulfilled, (state, action) => {
        const stickerObject = action.payload.object as TemplateStickerObject;
        const { primaryKey } = stickerObject.parameters;

        ObjectAdapter.setOne(
          state,
          stickerObject
        );
        state.selectedObjects = [primaryKey];
      })

      /**
       * 편집 작업 도중 템플릿을 불러올 때 적용
       */
      .addCase(overwriteMyWorkFromTemplateThunk.pending, (state) => {
        ObjectAdapter.setAll(state, []);
        state.selectedObjects = [];
        state.preselectedObjects = [];
      })

      // 다른 템플릿을 덮어씌웁니다.
      .addCase(overwriteMyWorkFromTemplateThunk.fulfilled, (state, action) => {
        const { objects } = action.payload;
        ObjectAdapter.setAll(state, getObjectsWithPrimaryKeyFromObjects(objects));
        state.selectedObjects = [];
      });
  },
});
