import { Fragment, PropsWithChildren, useEffect, useState } from 'react';

export const CSROnly = ({ children }: PropsWithChildren) => {
  const [isCSR, setIsCSR] = useState(false);

  useEffect(() => setIsCSR(true), []);

  return (
    <Fragment>
      { isCSR ? children : undefined }
    </Fragment>
  );
};
