import _ from 'lodash';
import { UIEvent, UIEventHandler } from 'react';

export const scrollStartEventHandler = _.debounce((
  event: UIEvent<HTMLDivElement>,
  onScrollStart?: UIEventHandler<HTMLDivElement>
) => {
  onScrollStart && onScrollStart(event);
}, 200, {
  leading: true,
  trailing: false,
});

export const scrollEndEventHandler = _.debounce((
  event: UIEvent<HTMLDivElement>,
  onScrollEnd?: UIEventHandler<HTMLDivElement>
) => {
  onScrollEnd && onScrollEnd(event);
}, 200);
