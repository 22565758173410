import { QueryClient as ReactQueryClient, QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';

export interface QueryClientProviderProps {
  children?: ReactNode;
}


export const QueryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
      refetchIntervalInBackground: false,
    },
  },
});

export const QueryClientProvider = (props: QueryClientProviderProps) => {
  return (
    <ReactQueryClientProvider client={QueryClient}>
      { props.children }
    </ReactQueryClientProvider>
  );
};
