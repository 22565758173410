import Avatar1 from '@/assets/images/pricing_avatar_1.jpeg';
import Avatar2 from '@/assets/images/pricing_avatar_2.jpeg';
import Avatar3 from '@/assets/images/pricing_avatar_3.jpeg';
import Avatar4 from '@/assets/images/pricing_avatar_4.jpeg';
import Avatar5 from '@/assets/images/pricing_avatar_5.jpeg';
import Avatar6 from '@/assets/images/pricing_avatar_6.jpeg';
import { Review } from '~/src/components/business/ReviewCard/ReviewCard';

import { UseReviewsArguments } from './useReviews.types';

export const useReviews = ({
  reviews = [],
}: UseReviewsArguments) => {
  const avatars = [
    Avatar1,
    Avatar2,
    Avatar3,
    Avatar4,
    Avatar5,
    Avatar6,
  ];
  const reviewsWithAvatar: Review[] = reviews.map((review, idx) => ({
    ...review,
    avatar: avatars[idx],
  }));

  return {
    reviews: reviewsWithAvatar,
  };
};
