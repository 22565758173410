import { StickerResourceManager } from '@lws/common/lib/helpers/StickerResourceManager';
import { initializeStickerObject, initializeTemplate } from '@lws/common/lib/utils/initializer';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { resourceAPIClient } from '~/src/api/v1/ResourcesAPI';
import { templateAPIClient } from '~/src/api/v1/TemplatesAPI';
import { getPrivateResourceUri } from '~/src/utils/editor/template';

export const addStickerObjectThunk = createAsyncThunk(
  'editor/addStickerObjectThunk',
  async (
    payload: {
      resourceId: string;
      canvasWidth: number;
      canvasHeight: number;
      deviceScale: number;
    }
  ) => {
    const resource = await resourceAPIClient.getResource(payload.resourceId);
    const resourceDestination = getPrivateResourceUri(resource.data.sourceData);
    const [size, resourceData] = await Promise.all([
      StickerResourceManager.getSize(resourceDestination),
      StickerResourceManager.getBase64(resourceDestination),
    ]);
    const {
      width: resourceWidth,
      height: resourceHeight,
    } = size;

    const object = await initializeStickerObject({
      resourceId: payload.resourceId,
      resourceDestination,
      canvasWidth: payload.canvasWidth,
      canvasHeight: payload.canvasHeight,
      deviceScale: payload.deviceScale,
    });

    return {
      id: payload.resourceId,
      meta: { width: resourceWidth, height: resourceHeight },
      resource: resourceData,
      object,
      canvasWidth: payload.canvasWidth,
      canvasHeight: payload.canvasHeight,
    };
  }
);

export const overwriteMyWorkFromTemplateThunk = createAsyncThunk(
  'editor/overwriteMyWorkFromTemplateThunk',
  async (templateId: string) => {
    const {
      template,
      resources,
      meta,
      myWork,
    } = await templateAPIClient.getMyWorkFormat(templateId);

    const {
      template: parsedTemplate,
      objects: clonedObjects,
    } = await initializeTemplate({
      template,
      resources,
      meta,
    });

    const result = {
      resources,
      meta,
      template: parsedTemplate,
      objects: clonedObjects,
      myWork,
    };

    return result;
  }
);
