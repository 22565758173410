import { css } from '@mui/material';
import Image from 'next/image';

import OnboardingPopupThumbnail from '@/assets/images/onboarding_popup.png';

export const OnboardingThumbnail = () => {
  return (
    <Image
      src={OnboardingPopupThumbnail.src}
      alt="onboarding popover thumbnail"
      width={OnboardingPopupThumbnail.width}
      height={OnboardingPopupThumbnail.height}
      css={css`
        width: 100%;
        height: auto;
      `}
    />
  );
};
