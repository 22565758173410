import { css } from '@mui/material';
import classNames from 'classnames';
import { HTMLAttributes, useEffect, useState } from 'react';
import InlineSVG from 'react-inlinesvg';

export interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  width: number;
  height: number;
  fill?: string;
  stroke?: string;
  children?: string;
}

export const Icon = ({ width, height, stroke, fill, children, ...others }: IconProps) => {
  const [iconFile, setIconFile] = useState<string>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    import(`./icons/${children}.svg`)
      .then((r) => {
        if (r?.default?.src) {
          setIconFile(r.default.src);
        }
      })
      .catch(() => {
        // catch
      })
      .finally(() => {
        setLoaded(true);
      });
  }, [children]);

  return (
    <span
      { ...others }
      className={classNames(
        'Icon',
        others.className
      )}
      style={{
        width,
        height,
      }}
      css={css`
        display: inline-block;
      `}
    >
      {
        loaded ?
          iconFile ?
            <InlineSVG
              src={iconFile}
              width={width}
              height={height}
              css={css`
                path,
                rect,
                line {
                  stroke: ${stroke} !important;
                  fill: ${fill} !important;
                }
              `}
            /> :
            children :
          undefined
      }
    </span>
  );
};
