import { useContext } from 'react';

import { PlansContext } from './PlansProvider';

export const usePlansContext = () => {
  const context = useContext(PlansContext);

  if (context === undefined) throw new Error('PlansContext is undefined.');

  return {
    ...context,
  };
};
