import { AxiosResponse } from 'axios';

import { User } from '~/src/types/user';

import { APIClient } from '../APIClient';
import { GetUserParams, UpdateUserParams } from './UserAPI.types';

export class UserAPI extends APIClient {
  async getUser({ userId }: GetUserParams): Promise<User> {
    return this.axios
      .get<User>(`/${this.appName}/users/${userId}`)
      .then(res => res.data);
  }

  async updateUser({ userId, isOnboardingRequestPopupOpened, onboardingStep }: UpdateUserParams): Promise<User> {
    return this.axios
      .put<User, AxiosResponse<User>, User>(`/${this.appName}/users/${userId}`, {
        isOnboardingRequestPopupOpened,
        onboardingStep,
      })
      .then(res => res.data);
  }
}

export const userAPIClient = new UserAPI;

export default userAPIClient;
