/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext } from 'react';

import { getBackgroundTextureFromContentId } from '../../utils/template';
import { ResourcesContext } from '../Renderer/ResourcesProvider';
import { TextureBackgroundLayerProps } from './TextureBackgroundLayer.types';

export const TextureBackgroundLayer = ({
  textureBackgroundURI,
}: TextureBackgroundLayerProps) => {
  const resourcesContext = useContext(ResourcesContext);

  if (!textureBackgroundURI) throw new Error('Can not load background texture');
  if (resourcesContext === undefined) throw new Error('It is not declare ResourcesProvider');

  const { texture } = resourcesContext;
  const textureId = getBackgroundTextureFromContentId(textureBackgroundURI);

  if (textureId === undefined) throw new Error('There is no information about texture');

  const textureResource = texture[textureId];

  if (textureResource === undefined) throw new Error(`There is no texture resources : ${textureId}`);

  return (
    <div
      className="TextureBackgroundLayer"
      style={{
        backgroundImage: `url(${textureResource})`,
      }}
      css={css`
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        background-size: cover;
        background-position: center;
      `}
    />
  );
};
