// GTM noscript
const GoogleTagManagerNoScript = () => {
  const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

  // 환경 변수가 없으면 빈 값을 리턴함
  if(!GOOGLE_TAG_MANAGER_ID) return <></>;

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
        <!-- Google Tag Manager (noscript) -->
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        <!-- End Google Tag Manager (noscript) -->
      `,
      }} />
  );
};

export default GoogleTagManagerNoScript;
