import { css } from '@emotion/react';
import { GlobalStyles, useTheme } from '@mui/material';
import classNames from 'classnames';
import { ToastContainer, ToastContainerProps } from 'react-toastify';

export type ToastifyProps = ToastContainerProps

export const Toastify = ({ ...others }: ToastifyProps) => {
  const theme = useTheme();

  return (
    <>
      <GlobalStyles
        styles={css`
          :root {
            --toastify-toast-width: auto !important;
            --toastify-font-family: ${theme.app.typography['basic-font']} !important;
          }
          .Toastify__toast-container {
            ${theme.app.typography.body7};
          }
          .Toastify__spinner {
            // Production 환경에서 스피너의 배경이 스타일 적용되지 않는 이슈로 인해 !important 사용함
            border-color: var(--toastify-spinner-color-empty-area);
            border-right-color: var(--toastify-spinner-color);
          }
        `}
      />
      <ToastContainer
        position="top-right"
        hideProgressBar
        { ...others }
        className={classNames(
          'ToastifyContainer'
        )}
      />
    </>
  );
};
