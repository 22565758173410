import { css } from '@emotion/react';
import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import BenefitThumbnail1 from '@/assets/images/benefit1@3x.png';
import BenefitThumbnail2 from '@/assets/images/benefit2@3x.png';
import BenefitThumbnail3 from '@/assets/images/benefit3@3x.png';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { ComponentProps } from '~/src/types/common';

import { Nl2br } from '../../../../app/Nl2br';
import { Benefit, BenefitCard } from '../../../BenefitCard';


type PlanBenefitProps = ComponentProps;

const benefitThumbnails = [BenefitThumbnail1, BenefitThumbnail2, BenefitThumbnail3];

export const PlanBenefit = (props: PlanBenefitProps) => {
  const theme = useTheme();
  const laptop = useMediaQuery('laptop');
  const { t } = useTranslation();

  const benefits = useMemo(() => {
    const benefits: Benefit[] =
      (t('common:benefit.benefits', { returnObjects: true }) as Omit<Benefit, 'thumbnail'>[])
        .map(((benefit, idx) => {
          return {
            ...benefit,
            thumbnail: benefitThumbnails[idx],
          } as Benefit;
        }));

    return benefits;
  }, [t]);

  return (
    <article
      className={classNames(
        'PlanBenefit',
        props.className
      )}
      css={css`
        text-align: center;
      `}
    >
      <Typography
        variant={laptop.down.is ? 'h4' : 'h3'}
        data-testid="PlanBenefit__Title"
      >
        {t('common:benefit.title')}
      </Typography>
      <Typography
        variant={laptop.down.is ? 'body6' : 'body4'}
        data-testid="PlanBenefit__Description"
        css={css`
          margin-top: 16px;
          color: ${theme.app.colors['neutral-color-05']};
        `}
      >
        <Nl2br>
          {t('common:benefit.description')}
        </Nl2br>
      </Typography>
      <div
        css={css`
          display: flex;
          align-items: stretch;
          justify-content: center;
          flex-wrap: wrap;
          column-gap: 32px;
          row-gap: 32px;
          margin-top: 48px;
        `}
      >
        {benefits.map((benefit, key) => {
          return (
            <BenefitCard
              key={key}
              benefit={benefit}
              css={css`
                max-width: 320px;
              `}
            />
          );
        })}
      </div>
    </article>
  );
};
