import { css } from '@emotion/react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from './Button.types';
import { CommonButtonStyle, ContainedButtonStyle, DarkColorButtonStyle, ExtraLargeButtonStyle, GradientColorButtonStyle, LargeButtonStyle, LightColorButtonStyle, MediumButtonStyle, OutlinedButtonStyle, PlainColorButtonStyle, PrimaryColorButtonStyle, SmallButtonStyle, TextButtonStyle } from './CommonStyle';

export interface ButtonProps extends Omit<MuiButtonProps, 'size' | 'color'> {
  size?: BUTTON_SIZE;
  variant?: BUTTON_VARIANT;
  color?: BUTTON_COLOR;
  radius?: number;
  children?: ReactNode;
}

export const Button = ({ color, variant, size, ...others }: ButtonProps) => {
  const currentColor = color ?? 'plain';
  const currentVariant = variant ?? 'contained';
  const currentSize = size ?? 'large';
  const currentRadius = others.radius ? others.radius : size && getDefaultRadius(size);

  return (
    <MuiButton
      { ...others }
      className={classNames(
        'Button',
        others.className
      )}
      style={others.style}
      css={css`
        position: relative;
        ${CommonButtonStyle}

        ${currentSize === 'extra-large' && ExtraLargeButtonStyle}
        ${currentSize === 'large' && LargeButtonStyle}
        ${currentSize === 'medium' && MediumButtonStyle}
        ${currentSize === 'small' && SmallButtonStyle}

        ${currentVariant === 'contained' && ContainedButtonStyle}
        ${currentVariant === 'outlined' && OutlinedButtonStyle}
        ${currentVariant === 'text' && TextButtonStyle}

        ${currentColor === 'plain' && PlainColorButtonStyle}
        ${currentColor === 'dark' && DarkColorButtonStyle}
        ${currentColor === 'gradient' && GradientColorButtonStyle}
        ${currentColor === 'light' && LightColorButtonStyle}
        ${currentColor === 'primary' && PrimaryColorButtonStyle}
        
        ${currentRadius && `border-radius: ${currentRadius}px;`}
        overflow: hidden;
      `}
    >
      { others.children }
    </MuiButton>
  );
};

const getDefaultRadius = (size: BUTTON_SIZE) => {
  switch (size) {
  case 'extra-large':
    return 50;

  case 'large':
    return 8;

  case 'medium':
    return 12;

  case 'small':
    return 6;
  }
};
