import { Template } from '@lws/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { overwriteMyWorkFromTemplateThunk } from '../thunk/EditorThunk';


const initialState = {
  data: undefined as (Template | undefined),
};

type TEMPLATE_STATE = typeof initialState;

export const templateSlice = createSlice({
  name: 'Template',
  initialState: initialState,
  reducers: {
    initialize: (state: TEMPLATE_STATE, action: PayloadAction<Template>) => {
      state.data = action.payload;
    },
    set: (state: TEMPLATE_STATE, action: PayloadAction<Template | undefined>) => {
      state.data = action.payload;
    },
    setBackgroundColor: (state: TEMPLATE_STATE, action: PayloadAction<string>) => {
      if (state.data === undefined) return;

      state.data.background = action.payload;
    },
    setContentId: (state: TEMPLATE_STATE, action: PayloadAction<string>) => {
      if (state.data === undefined) return;

      state.data.contentId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(overwriteMyWorkFromTemplateThunk.fulfilled, (state, action) => {
        const { template } = action.payload;

        state.data = {
          ...template,
        };
      });
  },
});
