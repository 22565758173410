/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { SwitchCase } from '@lws/common';
import { TemplateBackgroundGradient } from '@lws/types';
import { clsx } from 'clsx';
import { forwardRef } from 'react';

import { getBackgroundType } from '../../utils/template';
import { GradientBackgroundColorLayer, SolidColorBackgroundLayer, TextureBackgroundLayer } from '../BackgroundLayer';
import { ObjectLayer } from './ObjectLayer';
import { RendererProps } from './Renderer.types';
import { RendererProvider } from './RendererProvider';
import { ResourceMetaProvider } from './ResourceMetaProvider';
import { ResourcesProvider } from './ResourcesProvider';

export const Renderer = forwardRef<HTMLDivElement, RendererProps>(({
  className,
  style = {},
  width,
  height,
  objects,
  background,
  textureBackgroundURI,
  resources = { font: {}, symbol: {}, texture: {}, image: {} },
  resourceMeta = { symbol: {}, texture: {}, image: {} },
}, ref) => {
  const backgroundType = getBackgroundType({
    background,
    textureBackgroundURI,
  });

  const {
    font,
    symbol,
    texture,
    image,
  } = resources;
  const {
    symbol: symbolMeta,
    texture: textureMeta,
  } = resourceMeta;

  return (
    <RendererProvider
      width={width}
      height={height}
    >
      <ResourcesProvider
        font={font}
        symbol={symbol}
        texture={texture}
        image={image}
      >
        <ResourceMetaProvider
          symbol={symbolMeta}
          texture={textureMeta}
        >
          <div
            ref={ref}
            className={clsx(
              'Renderer',
              className
            )}
            style={{
              ...style,
              width,
              height,
            }}
            css={css`
            position: relative;
            pointer-events: all;
          `}
          >
            <SwitchCase
              value={backgroundType}
              caseBy={{
                'texture': (
                  <TextureBackgroundLayer textureBackgroundURI={textureBackgroundURI as string} />
                ),
                'gradient': (
                  <GradientBackgroundColorLayer background={background as TemplateBackgroundGradient} />
                ),
                'solid': (
                  <SolidColorBackgroundLayer color={background as string} />
                ),
              }}
              defaultElement={<SolidColorBackgroundLayer color="#FFFFFF" />}
            />
            <ObjectLayer
              objects={objects}
            />
          </div>
        </ResourceMetaProvider>
      </ResourcesProvider>
    </RendererProvider>
  );
});
