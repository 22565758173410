import { CognitoJwtVerifier } from 'aws-jwt-verify';
import { deleteCookie } from 'cookies-next';

import { AuthProviderName } from '~/src/types/common';

export interface TokenResponsePayload {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  error?: string; // Exception!
}

export interface NewTokenResponsePayload {
  access_token: string;
  id_token: string;
  token_type: string;
  expires_in: number;
}

interface Identity {
  userId: string;
  providerName: AuthProviderName;
  providerType: string;
  issuer: null;
  primary: string;
  dateCreated: string;
}

export interface CognitoUserPayload {
  at_hash: string;
  sub: string;
  'cognito:groups': string[];
  email_verified?: boolean;
  iss: string;
  'cognito:username': string;
  preferred_username?: string;
  nonce?: string;
  picture?: string;
  origin_jti: string;
  aud: string;
  identities: Identity[];
  token_use: string;
  auth_time: number;
  name?: string;
  exp: number;
  iat: number;
  jti: string;
  email?: string;
}

export class CognitoClient {
  static region = process.env.NEXT_PUBLIC_AWS_COGNITO_REGION as string;
  static userPoolId = process.env.NEXT_PUBLIC_AWS_COGNITO_USER_POOL_ID as string;
  static clientId = process.env.NEXT_PUBLIC_AWS_CLIENT_ID as string;
  static authDomain = process.env.NEXT_PUBLIC_AWS_COGNITO_AUTH_DOMAIN as string;
  static scope = ['email', 'phone', 'profile', 'openid'];

  static verifier = CognitoJwtVerifier.create({
    userPoolId: this.userPoolId,
    tokenUse: 'id',
    clientId: this.clientId,
  });

  /**
   * 실행하면 provider에 맞는 로그인 서비스로 이동합니다.
   * @param provider 로그인을 진행하기 위한 소셜 서비스 정보를 입력
   */
  static signIn(provider: string) {
    const scope = this.scope.join(' ');
    const redirectUri = `${process.env.NEXT_PUBLIC_DOMAIN}/auth/signin`;
    const endpoint = `https://${this.authDomain}/oauth2/authorize?redirect_uri=${redirectUri}&response_type=code&client_id=${this.clientId}&identity_provider=${provider}&scope=${scope}`;

    window.location.href = window.encodeURI(endpoint);
  }

  /**
   * 발급 받은 idpCode를 이용하여 로그인 처리에 필요한 token들을 발급 받습니다.
   * @param code
   * @param redirectUri
   * @returns
   */
  static getToken(code: string, redirectUri: string): Promise<TokenResponsePayload> {
    const endpoint = `https://${this.authDomain}/oauth2/token`;

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const body = new URLSearchParams();
    body.append('grant_type', 'authorization_code');
    body.append('client_id', this.clientId);
    body.append('redirect_uri', redirectUri);
    body.append('code', code);

    const options: RequestInit = {
      method: 'POST',
      headers,
      body,
      redirect: 'follow',
    };

    return fetch(endpoint, options)
      .then((res) => res.json())
      .catch((error) => error);
  }

  /**
   * 로그아웃 시 해당 소셜 로그인 서비스로부터 로그아웃 하고 보관 하고 있는 쿠키 정보를 제거합니다.
   */
  static signOut() {
    const endpoint = `https://${this.authDomain}/logout`;
    const logoutUri = `${process.env.NEXT_PUBLIC_DOMAIN}/auth/signout`;
    const params = new URLSearchParams({
      client_id: this.clientId,
      logout_uri: logoutUri,
      response_type: 'code',
    });
    const option = { path: '/' };

    deleteCookie('access-token', option);
    deleteCookie('expires-in', option);
    deleteCookie('id-token', option);
    deleteCookie('refresh-token', option);
    deleteCookie('token-type', option);

    const uri = `${endpoint}?${params.toString()}`;

    window.location.href = uri;
  }

  /**
   * idToken을 decode하여 화면 구성에 필요한 회원 정보를 얻습니다.
   * @param idToken
   * @returns
   */
  static getPayloadFromIdToken(idToken: string): Promise<CognitoUserPayload> {
    return this.verifier.verify(idToken) as Promise<CognitoUserPayload>;
  }

  /**
   * refresh token을 이용하여 token을 재발급 받습니다.
   * @param refreshToken
   * @returns
   */
  static getAllTokensFromRefreshToken(refreshToken: string): Promise<NewTokenResponsePayload> {
    const endpoint = `https://${this.authDomain}/oauth2/token`;

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const body = new URLSearchParams();
    body.append('grant_type', 'refresh_token');
    body.append('client_id', this.clientId);
    body.append('refresh_token', refreshToken);

    const options: RequestInit = {
      method: 'POST',
      headers,
      body,
      redirect: 'follow',
    };

    return fetch(endpoint, options).then((res) => res.json());
  }
}
