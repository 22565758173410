import { clsx } from 'clsx';
import { forwardRef } from 'react';

import { ScrollProps } from './Scroll.types';
import { scrollEndEventHandler, scrollStartEventHandler } from './utils';

export const Scroll = forwardRef<HTMLDivElement, ScrollProps>(({
  className,
  style,
  children,
  onScroll,
  onScrollStart,
  onScrollEnd,
}, ref) => {
  return (
    <div
      ref={ref}
      className={clsx(
        'Scroll',
        className
      )}
      style={{
        overflow: 'scroll',
        ...style,
      }}
      onScroll={(event) => {
        scrollStartEventHandler(event, onScrollStart);
        onScroll?.(event);
        scrollEndEventHandler(event, onScrollEnd);
      }}
    >
      { children }
    </div>
  );
});
