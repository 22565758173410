import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MAXIMUM_ZOOM_SCALE, MINIMUM_ZOOM_SCALE } from '~/src/constants/Editor';

import { APP_STATE } from '../../types';

const initialState = {
  is: 1,
};

export type ZOOM_STATE = typeof initialState;

export const zoomSlice = createSlice({
  name: 'ZoomSlice',
  initialState,
  reducers: {
    set: (state: ZOOM_STATE, action: PayloadAction<number>) => {
      const value = Math.min(Math.max(action.payload, MINIMUM_ZOOM_SCALE), MAXIMUM_ZOOM_SCALE);

      state.is = value;
    },
  },
});

const selector = (state: APP_STATE) => state.editor.zoom;

export const zoomSelector = createSelector(selector, (state) => state.is);
