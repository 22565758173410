/**
 * 에러 메시지를 표시할 때 사용되는 컴포넌트는 Provider의 영향을 받지 않도록 @mui가 아닌 @emotion 패키지를 사용합니다.
 */
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { ClientErrorMessageProps } from './ClientErrorMessage.types';

/**
 * StatusCode가 발행되는 에러(네트워크 에러)를 표시하는 컴포넌트입니다.
 */
const ERROR_DEFAULT_CAPTION = 'Oops! Something went wrong!';
const ERROR_DEFAULT_MESSAGE = 'Unknown error.';


export const ClientErrorMessage = ({
  className,
  style,
  caption = ERROR_DEFAULT_CAPTION,
  message = ERROR_DEFAULT_MESSAGE,
  homeButtonText = 'Home',
  homeButtonLink = '/',
  homeButtonCallback,
}: ClientErrorMessageProps) => {
  const router = useRouter();

  const redirectHome = useCallback(() => {
    router.replace(homeButtonLink, undefined, { shallow: true });
  }, [
    router,
    homeButtonLink,
  ]);

  return (
    <div
      className={className}
      style={style}
    >
      <ErrorCaption>
        { caption }
      </ErrorCaption>
      <Message>
        Sorry
      </Message>
      <ErrorDescription>
        { message }
      </ErrorDescription>
      <BackButton
        role="link"
        data-href={homeButtonLink}
        onClick={homeButtonCallback ?? redirectHome}
      >
        { homeButtonText }
      </BackButton>
    </div>
  );
};

const ErrorCaption = styled('h1')`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
  @media screen and (max-width: 600px) {
    font-size: 2.5vw;
    line-height: 2.5vw;
  }
`;

const Message = styled('h2')`
  color: #FF783E;
  text-align: center;
  font-family: Poppins;
  font-size: 140px;
  font-style: normal;
  font-weight: 800;
  line-height: 200px; /* 100% */
  margin-top: 8px;
  @media screen and (max-width: 600px) {
    font-size: 160px;
    line-height: 160px;
    font-size: 17.5vw;
    line-height: 17.5vw;
  }
`;

const ErrorDescription = styled('p')`
  color: #464748;
  text-align: center;
  /* Body 4 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 12px;
  @media screen and (max-width: 600px) {
    font-size: 2vw;
    line-height: 3vw;
  }
`;

const BackButton = styled('button')`
  display: block;
  margin: 48px auto 0;
  color:  #464748;
  text-align: center;
  /* Button 4 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  padding: 15px 78px;
  border-radius: 8px;
  appearance: none;
  background-color: #F2F2F2;
  border: none;
  cursor: pointer;
`;
