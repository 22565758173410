import { useContext } from 'react';

import { RebrandModalContext } from './RebrandModalProvider';

export const useRebrandModalContext = () => {
  const context = useContext(RebrandModalContext);

  if (context === undefined) throw new Error('RebrandModalContext is undefined.');

  return {
    ...context,
  };
};
