import { TemplateObject } from '@lws/types';

import { Point, Rect } from '../../types/editor-policy';
import { radianToDegree } from '../../utils/editor/template';

export class ObjectManager<T extends TemplateObject> {
  protected object: Partial<T> = {};
  protected canvasWidth: number | undefined;
  protected canvasHeight: number | undefined;

  constructor(object: Partial<T>) {
    this.object = object;
  }

  public setCanvasWidth(width: number) {
    this.canvasWidth = width;

    return this;
  }

  public setCanvasHeight(height: number) {
    this.canvasHeight = height;

    return this;
  }

  public setCenterX(centerX: number) {
    this.object = {
      ...this.object,
      centerX,
    };

    return this;
  }

  public setCenterY(centerY: number) {
    this.object = {
      ...this.object,
      centerY,
    };

    return this;
  }

  public setColor(color: string) {
    this.object = {
      ...this.object,
      hexColor: color,
    };
  }

  public setAlpha(alpha: number) {
    this.object = {
      ...this.object,
      alpha,
    };
  }

  public getZeroAngleLeft() {
    const { canvasWidth, object } = this;

    if (object === undefined || canvasWidth === undefined) return 0;

    const centerX = this.object.centerX ?? 0;
    const frameWidth = this.object.frameWidth ?? 0;

    return canvasWidth * centerX - frameWidth / 2;
  }

  public getZeroAngleTop() {
    const { canvasHeight, object } = this;

    if (object === undefined || canvasHeight === undefined) return 0;

    const centerY = this.object.centerY ?? 0;
    const frameHeight = this.object.frameHeight ?? 0;

    return canvasHeight * centerY - frameHeight / 2;
  }

  public getZeroAngleRight() {
    const { canvasWidth, object } = this;

    if (object === undefined || canvasWidth === undefined) return 0;

    const frameWidth = this.object.frameWidth ?? 0;

    return this.getZeroAngleLeft() + frameWidth;
  }

  public getZeroAngleBottom() {
    const { canvasHeight, object } = this;

    if (object === undefined || canvasHeight === undefined) return 0;

    const frameHeight = this.object.frameHeight ?? 0;

    return this.getZeroAngleTop() + frameHeight;
  }

  public getCenterPointX() {
    const { canvasWidth, object } = this;

    if (object === undefined || canvasWidth === undefined) return 0;

    const centerX = this.object.centerX ?? 0;

    return canvasWidth * centerX;
  }

  public getCenterPointY() {
    const { canvasHeight, object } = this;

    if (object === undefined || canvasHeight === undefined) return 0;

    const centerY = object.centerY ?? 0;

    return canvasHeight * centerY;
  }

  public getZeroAngleRect(): Rect | undefined {
    const { object, canvasWidth, canvasHeight } = this;
    if (
      object === undefined ||
      canvasWidth === undefined ||
      canvasHeight === undefined
    ) return undefined;

    const { frameWidth, frameHeight, centerX, centerY } = this.object;

    if (frameWidth === undefined || frameHeight === undefined || centerX === undefined || centerY === undefined) return undefined;

    const centerPointX = canvasWidth * centerX;
    const centerPointY = canvasHeight * centerY;

    const left = centerPointX - frameWidth / 2;
    const top = centerPointY - frameHeight / 2;
    const right = centerPointX + frameWidth / 2;
    const bottom = centerPointY + frameHeight / 2;

    return {
      left,
      top,
      right,
      bottom,
      width: right - left,
      height: bottom - top,
    };
  }

  public getZeroAnglePoints(): Point[] | undefined {
    const rect = this.getZeroAngleRect();

    if (rect === undefined) return undefined;

    return [
      { x: rect.left, y: rect.top },
      { x: rect.right, y: rect.top },
      { x: rect.right, y: rect.bottom },
      { x: rect.left, y: rect.bottom },
    ];
  }

  public getRotatedPoints(p: number, q: number): Point[] | undefined {
    if (!this.object) return undefined;

    const { arg } = this.object;

    if (arg === undefined) return undefined;

    const points = this.getZeroAnglePoints()?.map(({ x, y }) => {
      const horizontal = (x - p) * Math.cos(arg) - (y - q) * Math.sin(arg) + p;
      const vertical = (x - p) * Math.sin(arg) + (y - q) * Math.cos(arg) + q;

      return { x: horizontal, y: vertical };
    });

    return points;
  }

  public getRotatedRect(p?: number, q?: number): Rect | undefined {
    const centerX = this.getZeroAngleRight() - this.getZeroAngleLeft();
    const centerY = this.getZeroAngleBottom() - this.getZeroAngleTop();
    const points = this.getRotatedPoints(p ? p : centerX, q ? q : centerY);

    if (points === undefined) return undefined;

    let left = Infinity, top = Infinity;
    let right = -Infinity, bottom = -Infinity;

    for (const point of points) {
      const { x, y } = point;

      if (left > x) left = x;
      if (top > y) top = y;
      if (right < x) right = x;
      if (bottom < y) bottom = y;
    }

    return {
      left,
      top,
      right,
      bottom,
      width: right - left,
      height: bottom - top,
    };
  }

  public generate(): T {
    const object = {
      ...this.object,
      parameters: {
        ...this.object.parameters,
        isInitialized: true,
      },
    } as unknown as T;

    return object;
  }

  public get scaledWidth() {
    const { frameWidth, scale } = this.object;

    return (frameWidth && scale) ? frameWidth * scale : 0;
  }

  public get scaledHeight() {
    const { frameHeight, scale } = this.object;

    return (frameHeight && scale) ? frameHeight * scale : 0;
  }

  public get radianAngle() {
    return this.object.arg ?? 0;
  }

  public get degreeAngle() {
    return this.object.arg ? radianToDegree(this.object.arg) : 0;
  }

  public get frameWidth() {
    return this.object.frameWidth ?? 0;
  }

  public get frameHeight() {
    return this.object.frameHeight ?? 0;
  }

  public get centerX() {
    return this.object.centerX ?? 0;
  }

  public get centerY() {
    return this.object.centerY ?? 0;
  }
}
