export const COLOR_NAME_RED = 'red';
export const COLOR_NAME_YELLOW = 'yellow';
export const COLOR_NAME_ORANGE = 'orange';
export const COLOR_NAME_PINK = 'pink';
export const COLOR_NAME_PURPLE = 'purple';
export const COLOR_NAME_GREEN = 'green';
export const COLOR_NAME_TEAL = 'teal';
export const COLOR_NAME_BLUE = 'blue';
export const COLOR_NAME_GRAY = 'gray';

export const COLOR_NAMES_BY_ORDER: string[] = [
  COLOR_NAME_RED,
  COLOR_NAME_YELLOW,
  COLOR_NAME_ORANGE,
  COLOR_NAME_PINK,
  COLOR_NAME_PURPLE,
  COLOR_NAME_GREEN,
  COLOR_NAME_TEAL,
  COLOR_NAME_BLUE,
  COLOR_NAME_GRAY,
];

export const COLOR_PALETTES: Record<string, string[]> = {
  [COLOR_NAME_RED]: [
    '#FDD6D4',
    '#FBACA9',
    '#F6817C',
    '#F1544F',
    '#EA1B1C',
  ],
  [COLOR_NAME_YELLOW]: [
    '#F9F2D5',
    '#F8E7AD',
    '#F5DC86',
    '#F3D158',
    '#EFC517',
  ],
  [COLOR_NAME_ORANGE]: [
    '#FDE0D2',
    '#F9C1A6',
    '#F5A278',
    '#EF8248',
    '#EA6101',
  ],
  [COLOR_NAME_PINK]: [
    '#F5D9EE',
    '#E9B3DE',
    '#DC8BCE',
    '#D064BC',
    '#C23BAB',
  ],
  [COLOR_NAME_PURPLE]: [
    '#DED3F1',
    '#BCA5E3',
    '#9A79D4',
    '#774BC5',
    '#551EB5',
  ],
  [COLOR_NAME_GREEN]: [
    '#D8EFD4',
    '#B0DDA9',
    '#85CD7D',
    '#58BB4F',
    '#15AB10',
  ],
  [COLOR_NAME_TEAL]: [
    '#D8EFEE',
    '#AEDEDF',
    '#84CECE',
    '#55BDBE',
    '#0BAAAA',
  ],
  [COLOR_NAME_BLUE]: [
    '#D1E0F6',
    '#A4C2ED',
    '#75A3E4',
    '#4585DA',
    '#0066D0',
  ],
  [COLOR_NAME_GRAY]: [
    '#CFCFCF',
    '#A0A0A0',
    '#707070',
    '#414141',
    '#040F0F',
  ],
};
