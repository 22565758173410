/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { TextProps } from './Text.types';

export const Text = ({ object }: TextProps) => {
  const { text } = object;
  const lines = text.split('\\n').map((text, key) => (
    <div
      key={key}
      className="Text__Line"
      css={css`
        white-space: pre;
        font-size: 50px;
      `}
    >
      { text }
    </div>
  ));

  return (
    <div className="Text">
      { lines }
    </div>
  );
};
