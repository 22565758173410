import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MyWork } from '~/src/types/my-work';

import { updateMyWorkTitleThunk } from './thunk/MyWorkThunk';

const initialState = {
  data: undefined as (MyWork | undefined),
};

export type MyWorkSliceState = typeof initialState;

export const myWorkSlice = createSlice({
  name: 'MyWorkSlice',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<MyWork | undefined>) => {
      state.data = action.payload;
    },
    setSavedDate: (state, action: PayloadAction<number>) => {
      if (state.data === undefined) return;

      state.data.lastModifiedDate = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateMyWorkTitleThunk.fulfilled, (state, action) => {
        if (state.data) {
          state.data = {
            ...state.data,
            title: action.payload.title,
          };
        }
      });
  },
});
