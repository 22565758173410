import { css, Grid, Typography } from '@mui/material';
import Image from 'next/image';

import { AspectRatio } from '~/src/components/app/AspectRatio';
import { OnboardingStepIndustryProps } from '~/src/components/app/OnboardingModal/components/OnboardingStep/OnboardingStep.types';
import { Loading } from '~/src/components/screens/Editor/components/Panels/components/Loading';
import { useHomePolicy } from '~/src/hooks/v2/Policy/useHomePolicy';

export const OnboardingIndustryGrid = (props: OnboardingStepIndustryProps) => {
  const {
    industry: selectedIndustry,
    onChangeIndustry,
  } = props;

  const { policy, stringSlideItems: industries } = useHomePolicy();

  return (
    <>
      {policy.isLoading && (
        <Loading />
      )}
      <Grid
        container
        columns={{
          desktop: 12,
          laptop: 10,
          tablet: 8,
          mobile: 6,
        }}
        spacing={'16px'}
      >
        {industries.map((industry) => {
          const isSelected = industry.id === selectedIndustry?.id;

          return (
            <Grid
              key={industry.id}
              item
              desktop={2}
              laptop={2}
              tablet={2}
              mobile={3}
            >
              <AspectRatio
                ratio="1:1"
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    padding: 28px 24px 24px 24px;
                    border: 2px solid transparent;
                    border-radius: var(--02-Radius-corner-radius-16, 16px);
                    transition-property: border-color, background-color;
                    transition-duration: 150ms;
                    cursor: pointer;
                    &:hover {
                      // TODO: boon-ui 통합 필요
                      border-color: var(--color-Brand-600, rgba(255, 143, 38, 0.5));
                    }
                  `}
                  style={{
                    // TODO: boon-ui 통합 필요
                    backgroundColor: isSelected ? '#FFF4EF' : 'var(--color-Gray-50, #F5F5F5)',
                    border: isSelected ? '2px solid var(--color-Brand-600, #FF8E26)' : undefined,
                  }}
                  onClick={() => onChangeIndustry(isSelected ? undefined : industry)}
                >
                  <Image
                    src={industry.imageLink}
                    alt={industry.title}
                    width={36}
                    height={36}
                    css={css`
                      margin-bottom: 4px;
                    `}
                  />
                  <Typography
                    css={css`
                      // TODO: boon-ui 통합 필요
                      color: var(--color-Gray-black, #000);
                      text-align: center;
                      font-family: Pretendard;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 150%; /* 21px */
                    `}
                  >
                    {industry.title}
                  </Typography>
                </div>
              </AspectRatio>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
