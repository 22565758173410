import { css } from '@mui/material';
import classNames from 'classnames';
import Image, { ImageProps } from 'next/image';

import LogoImage from '@/assets/images/logo_boon@3x.png';
import { SERVICE_NAME } from '~/src/constants/Common';

import { Link, LinkProps } from '../../app/Link';
interface LogoProps extends Omit<LinkProps, 'href'> {
  href?: string;
  width?: number | `${number}`;
  height?: number | `${number}`;
  imageProps?: Omit<ImageProps, 'src' | 'alt'>
}

export const Logo = ({
  href,
  width = 70,
  height = 28,
  imageProps,
  ...others
}: LogoProps) => {
  return (
    <>
      {
        href === undefined ?
          <Image
            src={LogoImage.src}
            alt={SERVICE_NAME}
            width={width}
            height={height}
            css={css`
              width: ${width}px;
              height: auto;
              pointer-events: none;
            `}
            {...imageProps}
          /> :
          <Link
            { ...others }
            href={href}
            className={classNames(
              'Logo'
            )}
          >
            <Image
              src={LogoImage.src}
              alt={SERVICE_NAME}
              width={width}
              height={height}
              css={css`
                width: ${width}px;
                height: auto;
                pointer-events: none;
              `}
              {...imageProps}
            />
          </Link>
      }
    </>
  );
};
