import Script from 'next/script';

// 결제 모듈(Payple) 공용 스크립트
// MEMO : 페이플측 Core 스크립트에서 jquery 의존성이 있어서 항상 jquery 3.2.1 버전을 먼저 호출해주어야 하는 특이사항 있음
// jQuery 스크립트는 공용 페이지 헤드(PageHead 컴포넌트)에 항상 호출됨
const PaypleScript = () => {
  const paypleScriptUrl = process.env.NEXT_PUBLIC_PAYPLE_MODULE_URL;
  // 개발용 : https://demo-gpay.payple.kr/common/js/gpay-1.0.1.js
  // 운영용 : https://gpay.payple.kr/common/js/gpay-1.0.1.js

  if (!paypleScriptUrl) {
    throw new Error('Payple 모듈을 불러올 수 없습니다. 환경 변수를 설정해주세요.');
  }

  return (
    <Script
      strategy="afterInteractive"
      src={paypleScriptUrl}
    />
  );
};

export default PaypleScript;
