import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { policyAPIClient } from '../../../../api/v2/PolicyAPI';
import { LGSHomePolicyStringSlideItem } from '../../../../types/home-policy';
import { URIParser } from '../../../../utils/uri-parser';
import { UseHomePolicyArguments } from './useHomePolicy.types';

export const useHomePolicy = (args?: UseHomePolicyArguments) => {
  const policy = useQuery({
    queryKey: ['policy', 'home', 'v2'],
    queryFn: () => {
      return policyAPIClient.getHome();
    },
    onSuccess: ({ data }) => {
      args?.onLoadedHomePolicy && args.onLoadedHomePolicy(data);
    },
  });

  const stringSlideItems = useMemo<LGSHomePolicyStringSlideItem[]>(() => {
    const categoryItems = policy.data?.data;

    if (!categoryItems || !categoryItems.length) return [];

    return categoryItems.map((item) => {
      const id = URIParser.getCategoryId(item.value);

      return { ...item, id };
    });
  }, [policy.data]);

  return {
    policy,
    stringSlideItems,
  };
};
