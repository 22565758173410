import Lottie from 'lottie-react';
import { Fragment, useEffect, useState } from 'react';

import ballBalance from '~/src/assets/lottie/ball-balance-loader.json';
import loadingCircle from '~/src/assets/lottie/loading-circle.json';

import { LottieLoadingIndicatorProps } from './LottieLoadingIndicator.types';

export const LottieLoadingIndicator = ({
  type = 'circle',
  className,
  style,
}: LottieLoadingIndicatorProps) => {
  const [lottieData, setLottieData] = useState<any>(undefined);

  useEffect(() => {
    switch(type) {
    case 'circle':
      setLottieData(loadingCircle);
      break;
    case 'ball-balance':
      setLottieData(ballBalance);
      break;
    }
  }, [type]);

  return (
    <Fragment>
      {
        lottieData
          && (
            <Lottie
              className={className}
              animationData={lottieData}
              style={{
                ...style,
              }}
            />
          )
      }
    </Fragment>
  );
};
