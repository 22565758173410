import Script from 'next/script';

// Google Analytics 공용 스크립트
const GoogleAnalyticsScript = () => {
  const GA_TAG_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG_ID;

  // 환경 변수가 없으면 빈 값을 리턴함
  if(!GA_TAG_ID) {
    return <></>;
  }

  return (
    <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${GA_TAG_ID}`} />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', '${GA_TAG_ID}');
        `}
      </Script>
    </>
  );
};

export default GoogleAnalyticsScript;
