import { css, Modal } from '@mui/material';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { LGSHomePolicyStringSlideItem } from '~/src/types/home-policy';

import { useOnboardingController } from '../OnboardingRequest/hooks/useOnboardingController';
import { OnboardingModalHeader } from './components/OnboardingModalHeader/OnboardingModalHeader';
import { OnboardingProgressBar } from './components/OnboardingProgressBar/OnboardingProgressBar';
import { OnboardingStep } from './components/OnboardingStep';
import { ALL_STEP_LENGTH, ONBOARDING_STEP_NAME_BRAND_NAME, ONBOARDING_STEP_NAME_COLOR, ONBOARDING_STEP_NAME_INDUSTRY, ONBOARDING_STEP_NAMES_BY_ORDER, STEP_TEXTS   } from './constants';
import { useOnboardingStepController } from './hooks/useOnboardingStepController';
import { ONBOARDING_STEP_NAME_TYPES } from './OnboardingModal.types';

interface OnboardingModalProps {
  onExit?: () => void;
}

/**
 * 온보딩 요청 팝업에서 온보딩을 수락한 유저를 대상으로 온보딩 프로세스를 시작하는 컴포넌트
 * 현재는 브랜드명 입력, 인더스트리 선택, 색상 선택 단계가 있음
 */
export const OnboardingModal = (props: OnboardingModalProps) => {
  const { onExit } = props;

  const router = useRouter();
  const { onboardingModal, updateOnboardingStep } = useOnboardingController();
  const { open, onClose } = onboardingModal;

  const [stepIndex, setStepIndex] = useState<number>(0);
  const stepText = STEP_TEXTS[stepIndex];
  const [isBrandNameOverflowError, setIsBrandNameOverflowError] = useState<boolean>(false);

  const [stepData, setStepData] = useState({
    [ONBOARDING_STEP_NAME_BRAND_NAME]: '' as string,
    [ONBOARDING_STEP_NAME_INDUSTRY]: undefined as undefined | LGSHomePolicyStringSlideItem,
    [ONBOARDING_STEP_NAME_COLOR]: [] as string[],
  });

  const onChangeBrandName = useCallback((newBrandName: string) => {
    if (newBrandName.length > 12) {
      setIsBrandNameOverflowError(true);

      return;
    }

    setIsBrandNameOverflowError(false);
    setStepData(prev => {
      prev[ONBOARDING_STEP_NAME_BRAND_NAME] = newBrandName;
      return {...prev};
    });
  }, []);

  const onChangeIndustry = useCallback((newIndustry: undefined | LGSHomePolicyStringSlideItem) => {
    setStepData(prev => {
      prev[ONBOARDING_STEP_NAME_INDUSTRY] = newIndustry;
      return {...prev};
    });
  }, []);

  const onChangeColors = useCallback((newColors: string[]) => {
    setStepData(prev => {
      prev[ONBOARDING_STEP_NAME_COLOR] = newColors;
      return {...prev};
    });
  }, []);

  const onPrev = useCallback(() => {
    const newStepIndex = stepIndex - 1 < 0 ? 0 : stepIndex - 1;
    setStepIndex(newStepIndex);
    return newStepIndex;
  }, [stepIndex]);

  const onNext = useCallback(() => {
    const newStepIndex = stepIndex + 1 > (ALL_STEP_LENGTH - 1) ? (ALL_STEP_LENGTH - 1) : stepIndex + 1;
    setStepIndex(newStepIndex);
    return newStepIndex;
  }, [stepIndex]);

  const onFinish = useCallback(() => {
    const { brandName, industry, color } = stepData;
    const query = {
      ...(brandName ? { [ONBOARDING_STEP_NAME_BRAND_NAME]: brandName } : undefined),
      ...(industry ? { [ONBOARDING_STEP_NAME_INDUSTRY]: industry.id } : undefined),
      ...(color.length ? { [ONBOARDING_STEP_NAME_COLOR]: color.join(',') } : undefined),
    };

    router
      .push({ pathname: '/logo-preview', query: query })
      .then(() => {
        onClose?.();
      });

    return ONBOARDING_STEP_NAMES_BY_ORDER.length - 1;
  }, [router, stepData, onClose]);

  const steps: [string, ReactNode][] = useMemo(() => {
    const { brandName, industry, color } = stepData;

    return [
      [
        ONBOARDING_STEP_NAME_BRAND_NAME,
        <OnboardingStep.BrandName
          key={ONBOARDING_STEP_NAME_BRAND_NAME}
          {...stepText}
          // TODO: 다국어
          placeholder="Enter your Business name"
          helperText={isBrandNameOverflowError ? 'For best results, Keep your Business name under 12characters' : undefined}
          brandName={brandName}
          disabledNext={brandName.length <= 1 || brandName.length >= 13}
          onChangeBrandName={onChangeBrandName}
          onNext={() => {
            const nextStepIndex = onNext();
            const nextStepName = ONBOARDING_STEP_NAMES_BY_ORDER[nextStepIndex] as ONBOARDING_STEP_NAME_TYPES;

            updateOnboardingStep(nextStepName);
          }}
        />,
      ],
      [
        ONBOARDING_STEP_NAME_INDUSTRY,
        <OnboardingStep.Industry
          key={ONBOARDING_STEP_NAME_INDUSTRY}
          {...stepText}
          industry={industry}
          onChangeIndustry={onChangeIndustry}
          onNext={() => {
            const nextStepIndex = onNext();
            const nextStepName = ONBOARDING_STEP_NAMES_BY_ORDER[nextStepIndex] as ONBOARDING_STEP_NAME_TYPES;

            updateOnboardingStep(nextStepName);
          }}
        />,
      ],
      [
        ONBOARDING_STEP_NAME_COLOR,
        <OnboardingStep.Color
          key={ONBOARDING_STEP_NAME_COLOR}
          {...stepText}
          colors={color}
          onChangeColors={onChangeColors}
          onFinish={() => {
            const lastStepIndex = onFinish();
            const lastStepName = ONBOARDING_STEP_NAMES_BY_ORDER[lastStepIndex] as ONBOARDING_STEP_NAME_TYPES;

            updateOnboardingStep(lastStepName);
          }}
        />,
      ],
    ];
  }, [stepData, stepText, isBrandNameOverflowError, updateOnboardingStep, onChangeBrandName, onChangeIndustry, onChangeColors, onNext, onFinish]);

  const [currentStepName, currentStepComponent] = useMemo(() => steps[stepIndex], [stepIndex, steps]);

  useOnboardingStepController({
    steps,
    currentStepName,
    setStepIndex,
  });

  const desktop = useMediaQuery('desktop');
  const laptop = useMediaQuery('laptop');

  return (
    <Modal
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
      closeAfterTransition
      disableEnforceFocus
    >
      <div
        css={css`
          width: 100%;
          height: 100%;
          background-color: white;
          overflow-y: scroll;
          overscroll-behavior: contain;
        `}
      >
        <OnboardingModalHeader
          css={css`
            min-height: 72px;
          `}
          onPrev={stepIndex === 0 ? undefined : onPrev}
          onExit={onExit}
        />
        <OnboardingProgressBar
          stepIndex={stepIndex}
          allStepLength={steps.length}
        />
        <div
          css={css`
            max-width: 1440px;
            margin: auto;
            margin-top: 80px;
            padding-left: 56px;
            padding-right: 56px;
            padding-bottom: 160px;
            ${desktop.down.mq} {
              padding-left: 36px;
              padding-right: 36px;
            }
            ${laptop.down.mq} {
              margin-top: 40px;
              padding-left: 20px;
              padding-right: 20px;
            }
          `}
        >
          {currentStepComponent}
        </div>
      </div>
    </Modal>
  );
};
