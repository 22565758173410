import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { LOCALSTORAGE_KEY_ACCOUNT_DIALOG } from '~/src/constants/LocalStorage';

import { AccountDialog } from './AccountDialog';
import { AccountDialogContextArguments } from './AccountDialogProvider.types';

export const AccountDialogContext = createContext<undefined | AccountDialogContextArguments>(undefined);

/**
 * 1. useAccountDialog 훅을 이용하여 AccountDialog를 호출할 수 있습니다.
 * 2. Querystring에 login=1 값을 전달하면 AccountDialog를 호출할 수 있습니다.
 * 3. Querystring에 login=1&redirectUri=... 값을 전달하면 로그인 이후 해당 페이지로 이동합니다.
 */
export const AccountDialogProvider = ({
  children,
}: PropsWithChildren) => {
  const redirectUriKey = 'signin-redirect-uri';

  const [isOpened, setIsOpened] = useState(false);

  const getRedirectUri = useCallback(() => {
    return window.localStorage.getItem(redirectUriKey) || undefined;
  }, []);

  const removeRedirectUri = useCallback(() => {
    window.localStorage.removeItem(redirectUriKey);
  }, []);

  const show = useCallback((redirectUri?: string) => {
    localStorage.setItem(LOCALSTORAGE_KEY_ACCOUNT_DIALOG, 'true');

    if (redirectUri === undefined) {
      removeRedirectUri();
    } else {
      window.localStorage.setItem(redirectUriKey, redirectUri);
    }

    setIsOpened(true);
  }, [
    removeRedirectUri,
  ]);

  const hide = useCallback(() => {
    localStorage.removeItem(LOCALSTORAGE_KEY_ACCOUNT_DIALOG);
    removeRedirectUri();

    setIsOpened(false);
  }, [
    removeRedirectUri,
  ]);

  /**
   * AccountDialog를 열어둔 상태에서 새로고침을 하거나 페이지를 이탈하는 경우를 방지하기 위해
   * LocalStorage 값을 초기화 합니다.
   */
  useEffect(() => {
    localStorage.removeItem(LOCALSTORAGE_KEY_ACCOUNT_DIALOG);
  }, []);

  return (
    <AccountDialogContext.Provider
      value={{
        show,
        hide,
        getRedirectUri,
        removeRedirectUri,
      }}
    >
      { children }
      <AccountDialog
        show={isOpened}
        onClose={hide}
      />
    </AccountDialogContext.Provider>
  );
};
