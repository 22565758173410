import Lottie from 'lottie-react';

import ballBalance from '../../assets/lottie/ball-balance-loader.json';
import loadingCircle from '../../assets/lottie/loading-circle.json';
import { SwitchCase } from '../SwitchCase';
import {
  LOADING_INDICATOR_TYPE_BALL_BALANCE,
  LOADING_INDICATOR_TYPE_BALL_BALANCE_TEST_ID,
  LOADING_INDICATOR_TYPE_CIRCLE,
  LOADING_INDICATOR_TYPE_CIRCLE_TEST_ID
} from './LoadingIndicator.constants';
import { LoadingIndicatorProps } from './LoadingIndicator.types';

export const LoadingIndicator = ({
  type = LOADING_INDICATOR_TYPE_CIRCLE,
  className,
  style,
}: LoadingIndicatorProps) => {
  return (
    <SwitchCase
      value={type}
      caseBy={{
        [LOADING_INDICATOR_TYPE_CIRCLE]: (
          <Lottie
            data-testid={LOADING_INDICATOR_TYPE_CIRCLE_TEST_ID}
            className={className}
            animationData={loadingCircle}
            style={{
              ...style,
            }}
          />
        ),
        [LOADING_INDICATOR_TYPE_BALL_BALANCE]: (
          <Lottie
            data-testid={LOADING_INDICATOR_TYPE_BALL_BALANCE_TEST_ID}
            className={className}
            animationData={ballBalance}
            style={{
              ...style,
            }}
          />
        ),
      }}
    />
  );
};
