import autoAnimate from '@formkit/auto-animate';
import { css, Typography, useTheme  } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { Icon } from '~/src/components/app/Icon/v2';
import { TAXONOMY_EVENT_TYPE_CLICK_FAQ } from '~/src/constants/Taxonomy';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';

import { ClickLogger } from '../../app/Logs/ClickLogger';
import { FAQAccordionProps } from './FAQAccordion.types';

export const FAQAccordion = ({
  title,
  style,
  className,
  content,
  taxonomyEvents,
}: FAQAccordionProps) => {
  const [isOpen, setOpen] = useState(false);
  const theme = useTheme();
  const laptop = useMediaQuery('laptop');
  const accordionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    accordionRef.current && autoAnimate(accordionRef.current);
  }, [accordionRef]);

  return (
    <div
      ref={accordionRef}
      title={title}
      className={classNames(
        'Accordion',
        className
      )}
      style={style}
      css={css`
        background-color: #F7EFE8;
        border-radius: 20px;
      `}
    >
      <ClickLogger
        taxonomy={
          taxonomyEvents?.click_faq
            ? ({
              eventName: TAXONOMY_EVENT_TYPE_CLICK_FAQ,
              eventProperties: taxonomyEvents.click_faq,
            })
            : undefined
        }
      >
        <button
          type="button"
          className="Accordion__TitleWrapper"
          css={css`
            width: 100%;
            appearance: none;
            border: none;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 38px 40px;
            background-color: transparent;
            cursor: pointer;
            ${laptop.down.mq} {
              padding: 24px;
            }
          `}
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          <Typography
            className="Accordion__title"
            variant={laptop.down.is ? 'body4' : 'body1'}
            css={css`
              color: black;
              ${laptop.down.mq} {
                text-align: left;
              }
            `}
          >
            { title }
          </Typography>
          {
            isOpen ?
              <Icon
                width={36}
                height={36}
              >
                ic_close
              </Icon> :
              <Icon
                width={36}
                height={36}
              >
                ic_open
              </Icon>
          }
        </button>
      </ClickLogger>
      {
        isOpen &&
          <div
            className="Accordion__ContentWrapper"
            css={css`
              font-family: ${theme.app.typography['basic-font']};
              ${theme.app.typography.body4}
              color: ${theme.app.colors['neutral-color-05']};
              padding: 0 40px 40px;
              ol {
                padding-left: 24px;
                &, & li {
                  list-style-type: decimal;
                }
              }
              ${laptop.down.mq} {
                padding: 0 20px 20px;
                ${theme.app.typography.body6}
              }
            `}
          >
            { content }
          </div>
      }
    </div>
  );
};
