import { useEffect, useMemo, useState } from 'react';

import { useAuth } from '~/src/hooks/v2/Account/useAuth';
import { useAppSelector } from '~/src/store';
import { templateDataSelector } from '~/src/store/editor/template/selectors';
import { myWorkDataSelector } from '~/src/store/my-work/selectors';

import { UseOnceSaveMyWorkArguments } from './useOnceSaveMyWork.types';
import { useSaveMyWork } from './useSaveMyWork';

export const useOnceMyWorkSave = ({
  enabled,
  onSuccess,
  onError,
}: UseOnceSaveMyWorkArguments) => {
  const [isSaved, setIsSaved] = useState(false);
  const { userId } = useAuth();
  const { save } = useSaveMyWork();

  const myWork = useAppSelector(myWorkDataSelector);
  const template = useAppSelector(templateDataSelector);

  const myWorkId = useMemo(() => myWork?.myWorkId, [myWork?.myWorkId]);

  useEffect(() => {
    if (
      isSaved === true
      || enabled === false
      || userId === undefined
      || myWorkId === undefined
      || template === undefined
    ) return;

    setIsSaved(true);
    save(userId).then(() => {
      onSuccess?.();
    }).catch(() => {
      setIsSaved(false);
      onError?.();
    });
  }, [userId, myWorkId, template, isSaved, enabled, onSuccess, onError, save]);

  useEffect(() => {
    if (!enabled) setIsSaved(false);
  }, [enabled]);
};
