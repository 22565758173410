import { styled } from '@mui/material';
import Image from 'next/image';
import { Fragment } from 'react';

import { PreviewContentThumbnail } from './PurchasedWindowProvider.types';
import { usePurchaseWindowContext } from './usePurchaseWindowContext';

export const MyWorkThumbnailViewer = () => {
  const { previewContent } = usePurchaseWindowContext();
  const content = previewContent as PreviewContentThumbnail;

  return (
    <Fragment>
      {
        content?.thumbnailUri
          ? (
            <ThumbnailImage
              src={content.thumbnailUri}
              width={500}
              height={500}
              alt=""
            />
          )
          : (
            <Fragment />
          )
      }
    </Fragment>
  );
};

const ThumbnailImage = styled(Image)`
  width: 100%;
  height: 100%;
  background-color: #EFEFEF;
`;
