import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { TemplateResourceMeta, TemplateResourceMetaGroup } from '~/src/types/my-work';

import { APP_STATE } from '../../types';
import { addStickerObjectThunk, overwriteMyWorkFromTemplateThunk } from '../thunk/EditorThunk';

interface ResourceMetaAdapter {
  type: string;
  id: string;
  value: TemplateResourceMeta;
}

const resourceMetaAdapter = createEntityAdapter<ResourceMetaAdapter>({
  selectId: (model) => model.id,
});

export const resourceMetaSlice = createSlice({
  name: 'ResourceMeta',
  initialState: resourceMetaAdapter.getInitialState(),
  reducers: {
    setAll: (state: EntityState<ResourceMetaAdapter>, action: PayloadAction<TemplateResourceMetaGroup>) => {
      const adapterPayload = getAdapterPayloadFromMyworkResourceMeta(action.payload);

      resourceMetaAdapter.setAll(state, adapterPayload);
    },
    setMany: (state: EntityState<ResourceMetaAdapter>, action: PayloadAction<TemplateResourceMetaGroup>) => {
      const adapterPayload = getAdapterPayloadFromMyworkResourceMeta(action.payload);

      resourceMetaAdapter.setMany(state, adapterPayload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addStickerObjectThunk.fulfilled, (state, action) => {
        const payload = {
          id: action.payload.id,
          type: 'symbol',
          value: action.payload.meta,
        };

        resourceMetaAdapter.setOne(state, payload);
      })
      .addCase(overwriteMyWorkFromTemplateThunk.fulfilled, (state, action) => {
        // 리소스 메타는 히스토리에 추가 되지 않으므로 setAll 대신 setMany
        resourceMetaAdapter.setMany(state, getAdapterPayloadFromMyworkResourceMeta(action.payload.meta));
      });
  },
});


const getAdapterPayloadFromMyworkResourceMeta = (payload: TemplateResourceMetaGroup) => {
  const adapterPayload: ResourceMetaAdapter[] = [];
  const resources = Object.entries(payload);

  for (const [type, object] of resources) {
    const items = Object.entries<TemplateResourceMeta>(object);

    items.forEach(([id, value]) => {
      adapterPayload.push({ type, id, value });
    });
  }

  return adapterPayload;
};

const getMetaFromAdapterPayload = (payload: ResourceMetaAdapter[]) => {
  const resourceMeta: TemplateResourceMetaGroup = {
    symbol: {},
    image: {},
    texture: {},
  };

  for (const adapter of payload) {
    if (resourceMeta[adapter.type] === undefined) resourceMeta[adapter.type] = {};

    resourceMeta[adapter.type][adapter.id] = adapter.value;
  }

  return resourceMeta;
};

const resourcesMetaSelector = resourceMetaAdapter.getSelectors<APP_STATE>((state) => state.editor.meta);

// export const allResourceMetaSelector = (state: APP_STATE) => {
//   const allItems = resourcesMetaSelector.selectAll(state);

//   return getMetaFromAdapterPayload(allItems);
// };
export const allResourceMetaSelector = createSelector((state:APP_STATE) => state, (state: APP_STATE) => {
  const allItems = resourcesMetaSelector.selectAll(state);

  return getMetaFromAdapterPayload(allItems);
});

export const resourceMetaSelector =
  (resourceId: string) =>
    (state: APP_STATE) =>
      resourcesMetaSelector.selectById(state, resourceId);
