export const PAYPLE_ERROR_CODE = {
  C0998 : 'C0998', // 사용자가 결제 취소한 케이스
  A0999: 'A0999', // 결제 정보를 잘못 입력한 케이스
  A0998: 'A0998', // None data
  A0997: 'A0997', // Payple Internal Service Error
  R0999: 'R0999', // Payple 시스템 점검
};

// Payple 인증 토큰 유효 시간(단위 : ms)
export const PAYPLE_AUTH_TOKEN_VALID_TIME = 300000; //5분

// 단건 결제 Plan ID
export const ONESHOT_PLAN_ID = 'b7xm';

// 구독 결제 ID
export const SUBSCRIPTION_PLAN_ID = 'r69w';

// 구독 결제 유형
export const TYPE_SUBSCRIPTION = 'subscription';

// 단건 결제 유형
export const TYPE_ONESHOT = 'oneshot';
