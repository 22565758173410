import { User } from '../types/user';

const DEFAULT_ONBOARDING_DATA: User = {
  isOnboardingRequestPopupOpened: false,
  onboardingStep: '',
};

/**
 * 비로그인 유저의 유저 정보를 localStorage로 편리하게 관리할 수 있도록 도와주는 Storage 클레스입니다.
 */
export class UserStorage {
  private static key = 'user';

  static get(): User {
    const value = localStorage.getItem(this.key);

    try {
      return value && typeof value === 'string' ? JSON.parse(value) as User : DEFAULT_ONBOARDING_DATA;
    } catch {
      return DEFAULT_ONBOARDING_DATA;
    }
  }

  static set(user: User) {
    localStorage.setItem(this.key, JSON.stringify(user));
  }

  static update(user: User) {
    const oldUser = this.get();
    const newUser = oldUser ? { ...oldUser, ...user } : user;

    localStorage.setItem(this.key, JSON.stringify(newUser));
  }
}
