import { css, Grid } from '@mui/material';

import { ReviewCard } from '../ReviewCard/ReviewCard';
import { GridReviewListProps } from './GridReviewList.types';

export const GridReviewList = ({
  reviews,
}: GridReviewListProps) => {
  return (
    <Grid
      className="UsersReviewList"
      container
      spacing={'16px'}
    >
      {
        reviews.map((review, index) => {
          return (
            <Grid
              key={index}
              item
              desktop={4}
              laptop={4}
              tablet={6}
            >
              <ReviewCard
                review={review}
                css={css`
                  min-height: 450px;
                `}
              />
            </Grid>
          );
        })
      }
    </Grid>
  );
};
