import { css } from '@emotion/react';

import { LegacyDefaultTheme } from '~/src/themes/legacyDefault';

/** 기본 버튼 스타일 */
export const CommonButtonStyle = css`
  text-decoration: none;
  font-family: ${LegacyDefaultTheme.typography['basic-font']};
  text-transform: none;
  appearance: none;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  min-width: auto;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

/** 버튼 사이즈 */
export const ExtraLargeButtonStyle = css`
  padding: 0 50px;
  line-height: 80px;
  height: 80px;
  ${LegacyDefaultTheme.typography.button1};
`;

export const LargeButtonStyle = css`
  height: 56px;
  padding: 0 30px;
  line-height: 56px;
  ${LegacyDefaultTheme.typography.button2};
`;

export const MediumButtonStyle = css`
  height: 48px;
  padding: 0 30px;
  line-height: 48px;
  ${LegacyDefaultTheme.typography.button5};
`;

export const SmallButtonStyle = css`
  height: 28px;
  line-height: 24px;
  padding: 0 14px;
  height: 28px;
  ${LegacyDefaultTheme.typography.body8};
`;

/** 버튼 컬러 */
export const PrimaryColorButtonStyle = css`
  border: 1px solid ${LegacyDefaultTheme.colors['primary-color-02']};
  background: ${LegacyDefaultTheme.colors['primary-color-02']} !important;
  color: ${LegacyDefaultTheme.colors['neutral-color-10']};
  &:hover {
    opacity: .8;
  }
`;

export const PlainColorButtonStyle = css`
  border: 1px solid ${LegacyDefaultTheme.colors['neutral-color-09']};
  background: ${LegacyDefaultTheme.colors['neutral-color-09']};
  color: ${LegacyDefaultTheme.colors['neutral-color-04']};
  &:hover {
    background: ${LegacyDefaultTheme.colors['neutral-color-08']};
  }
  &:active {
    background: ${LegacyDefaultTheme.colors['neutral-color-07']};
  }
`;

export const GradientColorButtonStyle = css`
  border: 1px solid rgba(0, 0, 0, 0);
  background: linear-gradient(283.28deg, #FF6525 12.92%, #FF8E25 83.6%);
  color: ${LegacyDefaultTheme.colors['neutral-color-10']};
  &:hover {
    opacity: .8;
  }
`;

export const DarkColorButtonStyle = css`
  border: 1px solid #000000;
  background: #000000;
  color: ${LegacyDefaultTheme.colors['neutral-color-10']};
  border-color: rgba(255, 255, 255, .2);
  &:hover {
    background: ${LegacyDefaultTheme.colors['neutral-color-05']};
  }
`;

export const LightColorButtonStyle = css`
  background: ${LegacyDefaultTheme.colors['neutral-color-10']};
  border: 1px solid ${LegacyDefaultTheme.colors['neutral-color-08']};
  color: ${LegacyDefaultTheme.colors['neutral-color-04']};
`;

/** 버튼 모양 */
export const TextButtonStyle = css`
  background: transparent !important;
  border: 1px solid transparent !important;
`;

export const ContainedButtonStyle = css``;

export const OutlinedButtonStyle = css`
  background: transparent !important;
`;
