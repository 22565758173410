import GoogleAnalyticsScript from './GoogleAnalytics';
import GoogleTagManagerNoScript from './GoogleTagManagerNoScript';
import PaypleScript from './Payple';

interface ExternalScriptProps {
  children?: React.ReactNode;
}

// 항상 페이지 하단에 공통으로 정의될 외부 스크립트 정의
export const ExternalScript: React.FC<ExternalScriptProps> = () => {
  return (
    <>
      <GoogleAnalyticsScript />
      <PaypleScript />
      <GoogleTagManagerNoScript />
    </>
  );
};
