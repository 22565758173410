import 'normalize.css';
import '@/assets/styles/global.scss';
import 'rc-slider/assets/index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';

import { AlertProvider, ConfirmProvider, LegacyGlobalThemeProvider } from '@lws/boon-ui';
import { ParticleProvider } from '@lws/common';
import { DehydratedState, Hydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';

import { wrapper } from '@/store/index';
import { AppHead } from '~/src/components/business/AppHead';
import { ExternalScript } from '~/src/components/business/ExternalScript';
import { QueryClientProvider } from '~/src/context/QueryClientContext';

import { LottieLoadingOverlayProvider } from '../components/app/LottieLoadingOverlayProvider/LottieLoadingProvider';
import { MobileLimitAlert } from '../components/app/MobileLimitAlert';
import { OnboardingProvider } from '../components/app/OnboardingProvider/OnboardingProvider';
import { RoutingProgress } from '../components/app/RoutingProgress';
import { Amplitude } from '../components/business/Amplitude';
import { AppErrorBoundary } from '../components/business/AppErrorBoundary';
import { AccountDialogProvider } from '../components/business/Dialogs';
import { PurchasedWindowProvider } from '../components/business/PurchasedWindow';
import { RebrandModalProvider } from '../components/business/RebrandModal';
import { RouteActionProvider } from '../components/business/RouteActionProvider';
import { Smartlook } from '../components/business/Smartlook';
import { Toastify } from '../components/business/Toastify';
import { PlansProvider } from '../context/PlansProvider';
import { AppPropsWithLayout } from '../types/common';

export interface AppProps {
  idToken?: string;
  cookies?: { [key: string]: string };
  dehydratedState: DehydratedState;
}

// SmartLook 전역 객체에 대한 인터페이스 정의
declare global {
  interface Window {
    smartlook: any;
  }
}

// 개발모드에서만 API Mocking 하기
if (process.env.NODE_ENV === 'development') {
  require('../mocks');
}

export const App = ({ Component, ...rest }: AppPropsWithLayout<AppProps>) => {
  const Layout = Component.Layout || ((page) => page);
  const { store, props } = wrapper.useWrappedStore(rest);

  return (
    <QueryClientProvider>
      <Provider store={store}>
        <AppHead />
        {/* Fix: 원래는 로그인 로직을 SSR에서 초기화 하기 위해 넣었는데 지금은 유명무실한듯? */}
        <Hydrate
          state={rest.pageProps.dehydratedState}
        >
          <Smartlook />
          <Amplitude />
          <AppErrorBoundary>
            {
              /**
               * FIX: 현재는 ThemeProvider가 분리 되어 있어서 ThemeProvider 안에 내제할 수 없음.
               * 추후 ThemeProvider가 일원화 되면 ThemeProvider 안쪽으로 ConfirmProvider, AlertProvider를 옮겨야 함.
               */
            }
            <AlertProvider>
              <ConfirmProvider>
                <ReactQueryDevtools />
                <LegacyGlobalThemeProvider>
                  <AccountDialogProvider>
                    <ParticleProvider>
                      <LottieLoadingOverlayProvider>
                        <OnboardingProvider>
                          <RouteActionProvider>
                            <PlansProvider>
                              <PurchasedWindowProvider>
                                {/* MEMO: 추후 리브랜딩 Modal의 수요가 없어진다 판단되면 해당 Provider를 반드시 지울 것. */}
                                <RebrandModalProvider>
                                  { Layout(<Component { ...props.pageProps } {...props} />) }
                                  <Toastify />
                                  <RoutingProgress />

                                  { /* Mobile일 때 항상 표시되는 알러트 */}
                                  <MobileLimitAlert />
                                </RebrandModalProvider>
                              </PurchasedWindowProvider>
                            </PlansProvider>
                          </RouteActionProvider>
                        </OnboardingProvider>
                      </LottieLoadingOverlayProvider>
                    </ParticleProvider>
                  </AccountDialogProvider>
                </LegacyGlobalThemeProvider>
              </ConfirmProvider>
            </AlertProvider>
          </AppErrorBoundary>
        </Hydrate>
        <ExternalScript />
      </Provider>
    </QueryClientProvider>
  );
};

export default appWithTranslation(App);
