import { styled } from '@mui/material';

import {
  BUTTON_COLOR_PRIMARY,
  BUTTON_SIZE_X_LARGE,
  BUTTON_VARIANT_CONTAINED
} from '../../constants/Button';
import { Button } from '../Button';
import { Dialog } from '../Dialog';
import { AlertProps } from './Alert.types';

export const Alert = ({
  className,
  style,
  sx,
  open,
  title,
  message,
  confirmMessage = 'Exit',
  onConfirm,
}: AlertProps) => {
  return (
    <Dialog
      className={className}
      style={style}
      sx={sx}
      open={open}
      title={title}
      message={message}
      actions={(
        <ConfirmButton
          color={BUTTON_COLOR_PRIMARY}
          variant={BUTTON_VARIANT_CONTAINED}
          size={BUTTON_SIZE_X_LARGE}
          onClick={onConfirm}
        >
          { confirmMessage }
        </ConfirmButton>
      )}
    />
  );
};

const ConfirmButton = styled(Button)`
  width: 100%;
`;
