import { css, TextField as MuiTextField, TextFieldProps, useTheme } from '@mui/material';
import classNames from 'classnames';


export const TextField = (props: TextFieldProps) => {
  const theme = useTheme();

  return (
    <>
      { props.children }
      <MuiTextField
        { ...props }
        className={classNames(
          'TextField',
          props.className
        )}
        css={css`
          .MuiFormLabel-root {
            font-family: ${theme.app.typography['basic-font']};
          }
          .MuiInputBase-root {
            font-family: ${theme.app.typography['basic-font']};
            ${theme.app.typography.body7};
            &::after,
            &::before {
              border-color: #DDDDDD;
            }
          }
          .MuiFormHelperText-root {
            color: #FF0000;
            ${theme.app.typography.body9}
          }
        `}
      />
    </>
  );
};
