import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { LOCALSTORAGE_KEY_PURCHASE_WINDOW } from '~/src/constants/LocalStorage';

import { PURCHASE_WINDOW_PREVIEW_TYPE_THUMBNAIL } from './constants';
import { PreviewContent, PreviewType, PurchaseWindowContextArguments, PurchaseWindowShowArguments } from './PurchasedWindowProvider.types';
import { PurchaseWindow } from './PurchaseWindow';

export const PurchaseWindowContext = createContext<PurchaseWindowContextArguments | undefined>(undefined);

export const PurchaseWindowProvider = ({ children }: PropsWithChildren) => {
  const [currentMyWorkId, setCurrentMyWorkId] = useState<string>();
  const [previewType, setPreviewType] = useState<PreviewType>(PURCHASE_WINDOW_PREVIEW_TYPE_THUMBNAIL);
  const [previewContent, setPreviewContent] = useState<PreviewContent>();
  const [isActivePurchaseDialog, setIsActivePurchaseDialog] = useState(false);

  const show = useCallback(({
    previewType,
    previewContent,
    currentMyWorkId,
  }: PurchaseWindowShowArguments) => {
    localStorage.setItem(LOCALSTORAGE_KEY_PURCHASE_WINDOW, 'true');
    if (previewContent) setPreviewContent(previewContent);
    setIsActivePurchaseDialog(true);
    setPreviewType(previewType);
    setCurrentMyWorkId(currentMyWorkId);
  }, []);

  const hide = useCallback(() => {
    localStorage.removeItem(LOCALSTORAGE_KEY_PURCHASE_WINDOW);
    setPreviewContent(undefined);
    setIsActivePurchaseDialog(false);
    setCurrentMyWorkId(undefined);
  }, []);

  /**
   * PurchaseWindow를 열어둔 상태에서 새로고침을 하거나 페이지를 이탈하는 경우를 방지하기 위해
   * LocalStorage 값을 초기화 합니다.
   */
  useEffect(() => {
    localStorage.removeItem(LOCALSTORAGE_KEY_PURCHASE_WINDOW);
  }, []);

  return (
    <PurchaseWindowContext.Provider
      value={{
        isActive: isActivePurchaseDialog,
        currentMyWorkId,
        previewType,
        previewContent,
        show,
        hide,
      }}
    >
      <PurchaseWindow
        open={isActivePurchaseDialog}
      />
      { children }
    </PurchaseWindowContext.Provider>
  );
};

