/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Fragment, useCallback, useContext, useEffect } from 'react';

import { getStickerObjectTextureSize, getTextureResourceFromDecorators } from '../../utils/sticker';
import { ResourceMetaContext } from '../Renderer/ResourceMetaProvider';
import { ResourcesContext } from '../Renderer/ResourcesProvider';
import { StickerContext } from './StickerProvider';
import { StickerTextureProps } from './StickerTexture.types';

export const StickerTexture = ({
  svg,
}: StickerTextureProps) => {
  const resourcesContext = useContext(ResourcesContext);
  const resourceMetaContext = useContext(ResourceMetaContext);
  const context = useContext(StickerContext);

  if (context === undefined) throw new Error('StickerContext not declared.');
  if (resourcesContext === undefined) throw new Error('ResourcesProvider not declared');
  if (resourceMetaContext === undefined) throw new Error('ResourcesProvider not declared');

  const { object } = context;
  const {
    frameWidth,
    frameHeight,
    decoratorIds,
    maskImageScale,
    parameters,
  } = object;

  if (parameters?.primaryKey === undefined) throw new Error('primaryKey is not assigned');

  const { primaryKey } = parameters;

  const textureId = getTextureResourceFromDecorators(decoratorIds);

  const paint = useCallback((svg: SVGElement) => {
    const nodes = svg.querySelectorAll<HTMLElement>('path, ellipse, rect');

    for (const node of nodes) node.style.fill = `url("#${primaryKey}")`;
  }, [primaryKey]);

  useEffect(() => {
    if (svg) paint(svg);
  }, [svg, paint]);

  if (textureId === undefined) return (<Fragment />);

  const { texture } = resourcesContext;
  const { texture: textureMeta } = resourceMetaContext;
  const textureResource = texture[textureId];
  const textureResourceMeta = textureMeta[textureId];

  if (textureResource === undefined) throw new Error(`There is no Texture resource: ${textureId}`);
  if (textureResourceMeta === undefined) throw new Error(`There is not meta data (texture resource): ${textureResourceMeta}`);

  const {
    width: textureWidth,
    height: textureHeight,
  } = textureResourceMeta;

  const textureViewBox = `0 0 ${frameWidth} ${frameHeight}`;
  const {
    width: textureImageWidth,
    height: textureImageHeight,
  } = getStickerObjectTextureSize({
    objectWidth: frameWidth,
    objectHeight: frameHeight,
    textureWidth: textureWidth,
    textureHeight: textureHeight,
    maskScale: maskImageScale ?? 1,
  });

  return (
    <svg
      viewBox={'0 0 1 1'}
      width={1}
      height={1}
      css={css`
        position: absolute;
      `}
    >
      <defs>
        <pattern
          id={primaryKey}
          width={'100%'}
          height={'100%'}
          viewBox={textureViewBox}
          patternContentUnits="objectBoundingBox"
          preserveAspectRatio="xMidYMid slice"
        >
          <image
            width={textureImageWidth}
            height={textureImageHeight}
            xlinkHref={textureResource}
          />
        </pattern>
      </defs>
    </svg>
  );
};
