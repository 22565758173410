import { css } from '@mui/material';

import { Button } from '../../../Button/v2';

interface OnboardingRequestButtonProps {
  onClick?: () => void;
}

export const OnboardingRequestButton = (props: OnboardingRequestButtonProps) => {
  const {
    onClick,
  } = props;

  return (
    <Button
      color="primary"
      fullWidth
      css={css`
        border-radius: 8px;
      `}
      onClick={onClick}
    >
      {/* // TODO: 다국어 처리 */}
      Get Started
    </Button>
  );
};
