/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Image from 'next/image';
import { useContext } from 'react';

import { getImageIdFromContentId } from '../../utils/sticker';
import { ResourcesContext } from '../Renderer/ResourcesProvider';
import { StickerContext } from './StickerProvider';

export const ImageRenderer = () => {
  const context = useContext(StickerContext);
  const resourceContext = useContext(ResourcesContext);

  if (context === undefined) throw new Error('StickerContext not declared.');
  if (resourceContext === undefined) throw new Error('ResourcesProvider not declared.');

  const { object } = context;
  const { symbol } = resourceContext;
  const {
    contentId,
    frameWidth,
    frameHeight,
  } = object;

  const imageId = getImageIdFromContentId(contentId);

  if (imageId === undefined) throw new Error('Unable to extract image data from contentId of Sticker object.');

  const imageResource = symbol[imageId];

  if (imageResource === undefined) throw new Error('Cannot find image resources');

  return (
    <Image
      className="ImageRenderer"
      src={imageResource}
      alt="ImageRenderer"
      width={frameWidth}
      height={frameHeight}
      css={css`
        width: 100%;
        height: 100%;
        pointer-events: none;
      `}
    />
  );
};
