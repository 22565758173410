import { createContext, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { LottieLoadingOverlay } from './LottieLoadingOverlay';
import { LottieLoadingIndicatorConfig, LottieLoadingOverlayContextArguments } from './LottieLoadingProvider.types';

export const LottieLoadingOverlayContext = createContext<undefined | LottieLoadingOverlayContextArguments>(undefined);

export const LottieLoadingOverlayProvider = ({
  children,
}: PropsWithChildren) => {
  const index = useRef(0);
  const [configs, setConfigs] = useState<Map<number, LottieLoadingIndicatorConfig>>(new Map());

  const hide = useCallback((index: number) => {
    const clone = new Map(configs);
    clone.delete(index);

    setConfigs(clone);
  }, [
    configs,
  ]);

  const show = useCallback((config?: LottieLoadingIndicatorConfig) => {
    const clone = new Map(configs);
    clone.set(index.current, config ?? {});

    setConfigs(clone);

    return index.current++;
  }, [
    configs,
  ]);

  useEffect(() => {
    if (configs.size > 0) {
      window.document.body.style.setProperty('overflow', 'hidden');
    } else {
      window.document.body.style.setProperty('overflow', 'auto');
    }
  }, [configs.size]);

  return (
    <LottieLoadingOverlayContext.Provider
      value={{
        show,
        hide,
      }}
    >
      { children }
      {
        Array.from(configs).map(([index, config]) => {
          return (
            <LottieLoadingOverlay
              key={index}
              indicatorWidth={config.indicatorWidth}
              indicatorHeight={config.indicatorHeight}
              indicatorType={config.indicatorType}
            />
          );
        })
      }
    </LottieLoadingOverlayContext.Provider>
  );
};
