import { TemplateBackgroundGradient, TemplateObject } from '@lws/types';
import _ from 'lodash';
import uuid from 'react-uuid';
import URLParse from 'url-parse';

import { BACKGROUND_TYPE_COLOR, BACKGROUND_TYPE_GRADIENT, DEFAULT_FONT_SIZE } from '~/src/constants/Editor';

import { Direction2D, Point, Rect, Vector2D } from '../../../types/editor-policy';
import { TemplateResourceGroup, TemplateResourceMeta, TemplateResourceMetaGroup } from '../../../types/my-work';

export const calculateImageMaxSize = (width: number, height: number) => {
  return Math.min(width, height) * 1.25;
};

export const radianToDegree = (radian: number) => {
  return radian * (180 / Math.PI);
};

export const degreeToRadian = (degree: number) => {
  return degree * (Math.PI / 180);
};

export const getPrivateResourceUri = (uri: string) => {
  return [uri, process.env.NEXT_PUBLIC_RESOURCE_API_KEY].join('?apiKey=');
};

export const getStickerSymbolFrameSize = (
  imageWidth: number,
  imageHeight: number,
  canvasWidth: number,
  canvasHeight: number,
  scale: number,
  deviceScale: number,
  imageMaxSize?: number
) => {
  const maxSize = imageMaxSize ?? calculateImageMaxSize(canvasWidth, canvasHeight);
  const symbolSize = maxSize * deviceScale * scale;

  const frameWidth = imageWidth > imageHeight ? symbolSize : symbolSize * (imageWidth / imageHeight);
  const frameHeight = imageHeight > imageWidth ? symbolSize : symbolSize * (imageHeight / imageWidth);

  return { frameWidth, frameHeight };
};

export const calculateStickerScale = (frameWidth: number, frameHeight: number, imageMaxSize: number, deviceScale: number) => {
  const standard = frameWidth > frameHeight ? frameWidth : frameHeight;

  return standard / (imageMaxSize * deviceScale);
};

export const calculateImagePosition = (width: number, height: number, canvasWidth: number, canvasHeight: number, centerX: number, centerY: number) => {
  const originX = width / 2;
  const originY = height / 2;
  const left = canvasWidth * centerX;
  const top = canvasHeight * centerY;
  const x = left - originX;
  const y = top - originY;

  return { x, y };
};

export const calculateResizedCanvasSize = (canvasWidth: number, canvasHeight: number, maxWidth: number, maxHeight: number) => {
  const widthRatio = canvasWidth / canvasHeight;
  const heightRatio = canvasHeight / canvasWidth;
  const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
  const width = maxWidth < canvasWidth ? maxWidth : canvasWidth;
  const height = maxHeight < canvasHeight ? maxHeight : canvasHeight;
  const result = { width, height };

  if (widthRatio >= heightRatio) {
    result.height = width * ratio;
  } else {
    result.width = height * ratio;
  }

  return result;
};

export const domRectToRect = (rect: DOMRect): Rect => {
  const _rect: Rect = {
    width: rect.width,
    height: rect.height,
    left: rect.left,
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };

  return _rect;
};

export const getBoundingRectFromRects = (rects: Rect[]): Rect | undefined => {
  let startX = Infinity;
  let endX = -Infinity;
  let startY = Infinity;
  let endY = -Infinity;

  if (rects === undefined || rects.length <= 0) return undefined;

  rects.forEach((rect) => {
    if (startX > rect.left) startX = rect.left;
    if (endX < rect.right) endX = rect.right;
    if (startY > rect.top) startY = rect.top;
    if (endY < rect.bottom) endY = rect.bottom;
  });

  return {
    left: startX,
    top: startY,
    right: endX,
    bottom: endY,
    width: endX - startX,
    height: endY - startY,
  };
};

export const getOptimizedRect = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
) => {
  const left = Math.min(x1 ?? 0, x2 ?? 0);
  const top = Math.min(y1 ?? 0, y2 ?? 0);
  const right = Math.max(x1 ?? 0, x2 ?? 0);
  const bottom = Math.max(y1 ?? 0, y2 ?? 0);
  const width = right - left;
  const height = bottom - top;

  return {
    left, top, right, bottom, width, height,
  };
};

export const getDistanceByTwoPoints = (a: Point, b: Point) => {
  const width = b.x - a.x;
  const height = b.y - a.y;

  return Math.sqrt(width * width + height * height);
};

export const getAngleFromTwoPoints = (p1: Point, p2: Point) => {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  const theta = (Math.atan2(dy, dx) * 180) / Math.PI;
  return (theta < 0 ? 360 + theta : theta);
};

export const getRotatedPoint = (
  x: number,
  y: number,
  centerX: number,
  centerY: number,
  radian: number
): Point => {
  const left = (x - centerX) * Math.cos(radian) - (y - centerY) * Math.sin(radian) + centerX;
  const top = (x - centerX) * Math.sin(radian) + (y - centerY) * Math.cos(radian) + centerY;

  return { x: left, y: top };
};

export const getRotatedRectFromPoints = (points: Point[], centerX: number, centerY: number, angleInRadians: number): Rect => {
  const rotatedPoints = points.map(point => getRotatedPoint(point.x, point.y, centerX, centerY, angleInRadians));

  return getRectFromPoints(rotatedPoints);
};

export const getResourceDataFromDecoratorId = (decoratorId: string) => {
  const url = URLParse(decoratorId);
  const [, resourceType, resourceName] = url.pathname.split('/');

  return {
    resourceType,
    resourceName,
  };
};

export const getResourceFromUri = (uri: string, resources: TemplateResourceGroup) => {
  if (!uri) return undefined;

  const _uri = URLParse(uri);

  const location = _uri.hostname;
  const pathname = _uri.pathname.split('/');
  const type = pathname[1];
  const identity = pathname[2];
  let storage = undefined;

  if (location !== 'embedded') return undefined;

  if (type === 'symbol') storage = resources.symbol;
  if (type === 'font') storage = resources.font;
  if (type === 'image') storage = resources.image;
  if (type === 'texture') storage = resources.texture;

  if (storage === undefined) return undefined;
  if (!identity) return undefined;

  return storage[identity];
};

export const getMetaFromUri = (uri: string, meta: TemplateResourceMetaGroup) => {
  type ResourcesMetaKeys = keyof TemplateResourceMetaGroup;
  const _uri = URLParse(uri);

  const pathname = _uri.pathname.split('/');
  const type = pathname[1] as ResourcesMetaKeys;
  const identity = pathname[2];

  return meta[type][identity] as TemplateResourceMeta;
};

export const getMovedRectFromDistance = (rect: Rect, distance: Point) => {
  const _rect = { ...rect };

  _rect.left += distance.x;
  _rect.top += distance.y;
  _rect.right += distance.x;
  _rect.bottom += distance.y;

  return _rect;
};

export const getRotatedRectFromRect = (rect: Rect, radian: number, p?: number, q?: number) => {
  const { x: centerX, y: centerY } = getCenterPointFromRect(rect);
  const _p = p ?? centerX;
  const _q = q ?? centerY;
  const rotatedRectPoints = getRotatedPointsFromRect(rect, _p, _q, radian);
  const rotatedRect = getRectFromPoints(rotatedRectPoints);

  return rotatedRect;
};

export const getPointsFromRect = (rect: Rect): Point[] => {
  const points: Point[] = [
    { x: rect.left, y: rect.top },
    { x: rect.right, y: rect.top },
    { x: rect.right, y: rect.bottom },
    { x: rect.left, y: rect.bottom },
  ];

  return points;
};

export const getRotatedPointsFromRect = (rect: Rect, p: number, q: number, radian: number) => {
  const points = getPointsFromRect(rect);
  const newPoints: Point[] = [];

  for (const point of points) {
    const _point = getRotatedPoint(point.x, point.y, p, q, radian);
    newPoints.push(_point);
  }

  return newPoints;
};

export const getRectFromPoints = (points: Point[]): Rect => {
  let left = Infinity, top = Infinity;
  let right = -Infinity, bottom = -Infinity;

  for (const point of points) {
    const { x, y } = point;

    if (left > x) left = x;
    if (top > y) top = y;
    if (right < x) right = x;
    if (bottom < y) bottom = y;
  }

  return {
    left,
    top,
    right,
    bottom,
    width: right - left,
    height: bottom - top,
  };
};

export const getCenterPointFromRect = (rect: Rect) => {
  return {
    x: (rect.right + rect.left) / 2,
    y: (rect.bottom + rect.top) / 2,
  };
};

export const getCenterPointFromRatio = (centerX: number, centerY: number, width: number, height: number) => {
  return {
    x: width * centerX,
    y: height * centerY,
  };
};

export const getRatioFromPoint = (point: Point, width: number, height: number) => {
  return {
    x: point.x / width,
    y: point.y / height,
  };
};

export const getTextSidePaddingWidth = (singleLineHeight: number, fontSize: number) => {
  const BORDER_PADDING = 5;
  // 초기 렌더링된 폰트 스케일을 구합니다.
  const defaultFontScale = 20 / DEFAULT_FONT_SIZE;
  // 현재 렌더링된 폰트 스케일을 구합니다.
  const fontScale = fontSize / DEFAULT_FONT_SIZE;
  // 두 스케일간의 비율차를 구합니다.
  const defaultFontScaleRatio = defaultFontScale / fontScale;

  // BORDER_PADDING과 singleLineHeight에 비율차를 적용하여 스케일 다운/업에 대응합니다.
  return (BORDER_PADDING / defaultFontScaleRatio) * 2 + (singleLineHeight / defaultFontScaleRatio) * 0.6;
};

export const getTextFrameWidth = (width: number, scale: number, singleLineHeight: number, fontSize: number) => {
  return (width * scale) + getTextSidePaddingWidth(singleLineHeight, fontSize);
};

export const getTextFrameHeight = (defaultHeight: number, scale: number) => {
  return defaultHeight * scale;
};

export const getTextAlignTranslatePosition = (singleLineHeight: number) => {
  return singleLineHeight * .6 / 2;
};

export const getVector2D = (a: Point, b: Point): Vector2D => {
  const x = b.x > a.x ? -1 : b.x === a.x ? 0 : 1;
  const y = b.y > a.y ? -1 : b.x === a.x ? 0 : 1;

  return { x, y };
};


export const getRectFromTemplateObject = (canvasWidth: number, canvasHeight: number, object: TemplateObject): Rect => {
  const centerX = canvasWidth * object.centerX;
  const centerY = canvasHeight * object.centerY;
  const left = centerX - (object.frameWidth / 2);
  const top = centerY - (object.frameHeight / 2);
  const right = centerX + (object.frameWidth / 2);
  const bottom = centerY + (object.frameHeight / 2);
  const width = right - left;
  const height = bottom - top;

  return {
    left,
    top,
    right,
    bottom,
    width,
    height,
  };
};

export const getDirectionFromTwoPoints = (p1: Point, p2: Point): Direction2D => {
  const x = p2.x > p1.x ? -1 : p2.x < p1.x ? 1 : 0;
  const y = p2.y > p1.y ? -1 : p2.y < p1.y ? 1 : 0;

  return { x, y };
};

export const getArrayPointFromPoint = (point: Point) => {
  return [point.x, point.y];
};

export const getArrayPointsFromPoints = (points: Point[]) => {
  return points.map((point) => getArrayPointFromPoint(point));
};

export const getInitializedDegreeFromTemplateObjects = (objects: TemplateObject[]) => {
  if (objects.length > 1) return 0;
  if (objects.at(0) === undefined) return 0;

  const object = objects.at(0) as TemplateObject;

  return radianToDegree(object.arg);
};

export const getCenterRatioFromRect = (rect: Rect, width: number, height: number) => {
  const { left, right, top, bottom } = rect;
  const x = (left + right) / (2 * width);
  const y = (top + bottom) / (2 * height);

  return { x, y };
};

export const findResourceIndexFromDecoratorIds = (type: string, decoratorIds: string[]): number => {
  let index = -1;

  for (let i = 0; i < decoratorIds.length; i += 1) {
    const decoratorId = decoratorIds.at(i) as string;
    const url = URLParse(decoratorId);
    const [, resourceType] = url.pathname.split('/');

    if (resourceType === type) {
      index = i;

      break;
    }
  }

  return index;
};

export const getStickerURIFromResourceURI = (resourceURI: string) => {
  return `pixo-app://resource@embedded/symbol/${resourceURI}`;
};

export const getObjectsWithPrimaryKeyFromObjects = (objects: TemplateObject[]) => {
  const newObjects = _.cloneDeep(objects);

  for (const newObject of newObjects) {
    if (newObject.parameters === undefined || newObject.parameters.primaryKey === undefined) {
      newObject.parameters = {
        primaryKey: uuid(),
      };
    }
  }

  return newObjects;
};

export const getBackgroundType = (background: string | TemplateBackgroundGradient) => {
  if (typeof background === 'string') return BACKGROUND_TYPE_COLOR;

  return BACKGROUND_TYPE_GRADIENT;
};
