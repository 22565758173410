import { useEffect } from 'react';

import { BASIC_USER_NAME } from '~/src/constants/User';
import { useAuth, usePlan } from '~/src/hooks/v2/Account';
import { UserPlan } from '~/src/types/user';

import { SmartlookProps } from './Smartlook.types';

/**
 * 사용자의 행동 패턴을 분석하기 위해 smartlook 트래킹 정보를 추가합니다.
 */
interface ConnectSmartlookArguments {
  userName: string;
  userId: string;
  userEmail: string;
  userPlan: UserPlan;
}

const connectSmartlook = ({
  userName = BASIC_USER_NAME,
  userId,
  userEmail,
  userPlan,
}: ConnectSmartlookArguments) => {
  const userIdentityParams = {
    name: userName,
    email: userEmail,
    plan: userPlan,
  };

  if (typeof window.smartlook !== 'undefined') {
    window?.smartlook('identify', userId, userIdentityParams);
  }
};

export const Smartlook = ({
  debug = false,
}: SmartlookProps) => {
  const isEnabled = debug || (process.env.NODE_ENV === 'production');

  const {
    userId,
    email,
    name,
  } = useAuth();
  const {
    userPlan,
  } = usePlan();

  useEffect(() => {
    if (debug) {
      console.log('[Smartlook] Enabled debug mode.');
    }
  }, [
    debug,
  ]);

  useEffect(() => {
    if (isEnabled && name && email && userId && userPlan) {
      connectSmartlook({
        userName: name,
        userEmail: email,
        userId,
        userPlan,
      });
    }
  }, [
    isEnabled,
    userId,
    email,
    name,
    userPlan,
  ]);

  return (
    <>
      {isEnabled && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
              window.smartlook||(function(d) {
                var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
                var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
                c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
                })(document);
                smartlook('init', '4b84ed8a975b99c5c51a1b0ae879f7d3eeb155be', { region: 'eu' });
            `,
            }}
          />
        </>
      )}
    </>
  );
};
