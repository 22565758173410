import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { hydrate } from '../types';

const initialState = {
  cookies: {} as { [key: string]: string },
};

export type BrowserSliceState = typeof initialState;

export const browserSlice = createSlice({
  name: 'BrowserSlice',
  initialState,
  reducers: {
    setCookies: (state, action: PayloadAction<{ [key: string]: string }>) => {
      state.cookies = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(hydrate, (state, action) => {
        return {
          ...state,
          ...action.payload.browser,
        };
      });
  },
});

