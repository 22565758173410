/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { getFontResourceFromDecorators, getTextObjectLineSpacingCSS, PlainTextManager } from '@lws/common';
import { CSSProperties } from 'react';

import { getFlipCSS, getObjectRotateCSS } from '../../utils/common';
import { TextStylerProps } from './TextStyler.types';
import { useAlignment } from './useAlignment';

export const TextStyler = ({
  children,
  object,
}: TextStylerProps) => {
  const { alignment } = useAlignment({ object });

  const {
    arg,
    scale,
    hexColor,
    decoratorIds,
    kerning,
    lineSpacing,
    parameters,
    flipped,
    alpha,
  } = object;

  const {
    singleLineHeight,
  } = parameters;

  const fontFamily = getFontResourceFromDecorators(decoratorIds);
  const letterSpacing = PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);
  const lineSpacingStyle = getTextObjectLineSpacingCSS({ singleLineHeight, lineSpacing });
  const rotateStyle = getObjectRotateCSS(arg);
  const flipStyle = getFlipCSS(flipped);

  const scaledStyle = {
    textAlign: 'var(--text-align)',
    transform: 'scale(var(--scale)) translateX(0)',
  } as unknown as CSSProperties;

  return (
    <div
      className="TextStyler"
      style={{
        fontFamily,
        color: hexColor,
        transform: `${rotateStyle} ${flipStyle}`,
        opacity: alpha,
        '--scale': scale,
        '--text-align': alignment,
        '--letter-spacing': `${letterSpacing}px`,
        '--line-spacing': `${lineSpacingStyle}px`,
      } as CSSProperties}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        line-height: normal;
        letter-spacing: var(--letter-spacing);
        .Text__Line {
          margin-bottom: var(--line-spacing);
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      `}
    >
      <div
        className="TextStyler--Scaled"
        style={scaledStyle}
      >
        { children }
      </div>
    </div>
  );
};
