import { css, IconButton } from '@mui/material';

import { Icon } from '../../../Icon/v2';

interface OnboardingCloseButtonProps {
  onClose?: () => void;
}

export const OnboardingCloseButton = (props: OnboardingCloseButtonProps) => {
  const {
    onClose,
  } = props;

  return (
    <div
      css={css`
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;
      `}
    >
      <IconButton
        color="inherit"
        css={css`
          padding: 4px;
          background-color: white;
          &:hover {
            background-color: rgba(255, 255, 255, 0.7);
          }
        `}
        onClick={onClose}
      >
        <Icon
          width={16}
          height={16}
          css={css`
            display: flex;
          `}
        >
          ic_cross
        </Icon>
      </IconButton>
    </div>
  );
};
