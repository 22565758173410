import { ReasonPhrases, StatusCodes } from 'http-status-codes';

export class APIError extends Error {
  message: string;
  statusCode: number;

  constructor(statusCode: number, message: string, name?: string, cause?: string) {
    super(message);

    this.statusCode = statusCode;
    this.message = message;
    if (name) this.name = name;
    if (cause) this.cause = cause;
  }
}

export class BadRequestError extends APIError {
  constructor(message: string = ReasonPhrases.BAD_REQUEST) {
    super(
      StatusCodes.BAD_REQUEST,
      message,
      ReasonPhrases.BAD_REQUEST
    );
  }
}

export class NotFoundError extends APIError {
  constructor(message: string = ReasonPhrases.NOT_FOUND) {
    super(
      StatusCodes.NOT_FOUND,
      message,
      ReasonPhrases.NOT_FOUND
    );
  }
}

export class InternalServerError extends APIError {
  constructor(message: string = ReasonPhrases.INTERNAL_SERVER_ERROR) {
    super(
      StatusCodes.INTERNAL_SERVER_ERROR,
      message,
      ReasonPhrases.INTERNAL_SERVER_ERROR
    );
  }
}

export class UnauthorizedError extends APIError {
  constructor(message: string = ReasonPhrases.UNAUTHORIZED) {
    super(
      StatusCodes.UNAUTHORIZED,
      message,
      ReasonPhrases.UNAUTHORIZED
    );
  }
}

export class MethodNotAllowedError extends APIError {
  constructor(message: string = ReasonPhrases.METHOD_NOT_ALLOWED) {
    super(
      StatusCodes.METHOD_NOT_ALLOWED,
      message,
      ReasonPhrases.METHOD_NOT_ALLOWED
    );
  }
}
