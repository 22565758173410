export class StickerResourceManager {
  static getFile(destination: string) {
    return new Promise<Response>((resolve, reject) => {
      fetch(destination).then((file) => {
        resolve(file);
      }).catch(() => {
        reject();
      });
    });
  }

  static getImageElement(uri: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.src = uri;

      image.onload = () => {
        resolve(image);
      };

      image.onerror = () => {
        reject();
      };
    });
  }

  static getBase64(destination: string) {
    return new Promise<string>((resolve, reject) => {
      this.getFile(destination).then((resource) => {
        resource.blob().then((blob) => {
          const fr = new FileReader();

          fr.readAsDataURL(blob);

          fr.onload = () => {
            resolve(fr.result as string);
          };
        }).catch(() => {
          reject();
        });
      });
    });
  }

  static getSize(destination: string) {
    return new Promise<{ width: number, height: number }>((resolve) => {
      this.getFile(destination).then((response) => {
        const mimeType = response.headers.get('Content-Type');

        // SVG인 경우
        if (mimeType?.includes('svg')) {
          response.text().then((text) => {
            const parser = new DOMParser();
            const svg = parser.parseFromString(text, 'image/svg+xml').querySelector('svg');
            const viewBox = svg?.viewBox;
            const widthAttr = svg?.getAttribute('width');
            const heightAttr = svg?.getAttribute('height');

            const width = viewBox?.baseVal?.width ? viewBox.baseVal.width : typeof widthAttr === 'string' ? parseInt(widthAttr) : undefined;
            const height = viewBox?.baseVal?.height ? viewBox.baseVal.height : typeof heightAttr === 'string' ? parseInt(heightAttr) : undefined;

            if (width === undefined || height === undefined) {
              this.getImageElement(destination).then(({ clientWidth, clientHeight }) => {
                resolve({
                  width: clientWidth,
                  height: clientHeight,
                });
              });
            } else {
              resolve({
                width,
                height,
              });
            }
          });
        } else {
          // 그 외 이미지인 경우
          this.getImageElement(destination).then(({ width, height }) => {
            resolve({
              width,
              height,
            });
          });
        }
      });
    });
  }
}
