import { css } from '@emotion/react';
import { create } from 'canvas-confetti';
import { HTMLAttributes, useCallback, useEffect, useRef } from 'react';

import { SchoolParadeEffectProps } from './SchoolParadeEffect.types';
import { useParticleTimer } from './useParticleTimer';

export type WelcomeConfettiProps = HTMLAttributes<HTMLCanvasElement>

const END_DELAY = 4000;

export const SchoolParadeEffect = ({
  duration = 3000,
  onEnd,
}: SchoolParadeEffectProps) => {
  const element = useRef<HTMLCanvasElement>(null);
  const confettiCanvas = useRef<confetti.CreateTypes>();
  const { confettiEndTime } = useParticleTimer({
    duration,
    delay: END_DELAY,
    onEnd,
  });

  const startConfetti = useCallback(() => {
    if (confettiCanvas.current) {
      confettiCanvas.current({
        particleCount: 12,
        startVelocity: 90,
        spread: 24,
        angle: 60,
        origin: {
          x: -.1,
          y: 1,
        },
      });

      confettiCanvas.current({
        particleCount: 12,
        startVelocity: 90,
        spread: 24,
        angle: 120,
        origin: {
          x: 1.1,
          y: 1,
        },
      });

      if (confettiEndTime && confettiEndTime > Date.now()) {
        requestAnimationFrame(startConfetti);
      }
    }
  }, [confettiEndTime]);

  useEffect(() => {
    if (element.current !== null) {
      confettiCanvas.current =
        create(element.current, {
          resize: true,
          useWorker: true,
        });
    }
  }, []);

  useEffect(() => {
    if (confettiEndTime) {
      startConfetti();
    }
  }, [confettiEndTime, startConfetti]);

  return (
    <canvas
      ref={element}
      id="SchoolParadeEffect"
      css={css`
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
        width: 100%;
        height: 100%;
        pointer-events: none;
      `}
    />
  );
};
