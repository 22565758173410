import { createContext, useCallback, useState } from 'react';

import { SwitchCase } from '../SwitchCase';
import { ParticleContextArguments, ParticleItem, ParticleOptions, ParticleProviderProps } from './ParticleProvider.types';
import { SchoolParadeEffect } from './SchoolParadeEffect';

export const ParticleContext = createContext<ParticleContextArguments | undefined>(undefined);

export const ParticleProvider = ({
  children,
}: ParticleProviderProps) => {
  const [index, setIndex] = useState(0);
  const [particles, setParticles] = useState<Map<number, ParticleItem>>(new Map());

  const work = useCallback((particleName: string, options: ParticleOptions) => {
    const clone = new Map(particles);
    clone.set(index, {
      name: particleName,
      options,
    });

    setParticles(clone);
    setIndex(index + 1);
    return index;
  }, []);

  const stop = useCallback((particleId: number) => {
    const clone = new Map(particles);
    clone.delete(particleId);

    setParticles(clone);
  }, [particles]);

  return (
    <ParticleContext.Provider
      value={{
        work,
        stop,
      }}
    >
      {
        Array.from(particles).map(([key, { name, options }]) => {
          return (
            <SwitchCase
              key={key}
              value={name}
              caseBy={{
                'school-parade': (
                  <SchoolParadeEffect
                    duration={options.duration}
                    onEnd={() => {
                      stop(key);
                    }}
                  />
                ),
              }}
            />
          );
        })
      }
      { children }
    </ParticleContext.Provider>
  );
};
