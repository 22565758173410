import { css, LinearProgress } from '@mui/material';
import { useMemo } from 'react';

interface OnboardingProgressBarProps {
  stepIndex: number;
  allStepLength: number;
}

export const OnboardingProgressBar = (props: OnboardingProgressBarProps) => {
  const { stepIndex, allStepLength } = props;
  const progress = useMemo(() => (stepIndex + 1) / allStepLength * 100, [stepIndex, allStepLength]);

  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      color="primary"
      css={css`
        position: sticky;
        top: 72px;
        left: 0;
        width: 100%;
        height: 2px;
        // TODO: boon-ui 통합 필요
        background: var(--color-Gray-50, #F5F5F5);
        z-index: 1;
      `}
      sx={{
        '.MuiLinearProgress-barColorPrimary': {
          background: '#FF8E26',
        },
      }}
    />
  );
};
