import { URLPattern } from 'next/server';
import { Fragment } from 'react';

import { ONBOARDING_STEP_NAME_BRAND_NAME, ONBOARDING_STEP_NAMES_BY_ORDER } from '../components/app/OnboardingModal/constants/constants';

export const classNames = (classNames: (string | undefined)[]) => {
  return classNames.filter((className) => className).join(' ');
};

export const getVisualText = (text: string) => {
  return text.replaceAll('\\n', '<br/>').replaceAll(' ', '&nbsp;');
};

export const isActiveRoute = (path: string, currentPath: string) => {
  const match = new RegExp(`^${path}`);
  let isActive = Boolean(currentPath.match(match));

  if (path === '/' && currentPath !== '/') isActive = false;

  return isActive;
};

export const numberFormat = (number: number) => {
  if (!number) return 0;
  if (typeof number !== 'number') return number;

  return number.toLocaleString();
};

export const textToHTMLText = (text: string) => {
  return text
    .replace(/\\n/gm, '\n')
    .replace(/ /gm, '&nbsp;')
    .split(/\n/gm)
    .map((text) => `<p>${text}</p>`)
    .join('');
};

export const htmlTextToText = (html: string) => {
  return html
    .replaceAll('</p>', '\n')
    .replaceAll('<p>', '');
};

export const splittedString = (text: string) => {
  return text.replace(/\\m/gm, '\n').split('\n');
};

export const getFileFromURI = (uri: string): Promise<Response> => {
  return new Promise((resolve, reject) => {
    fetch(uri).then((file) => {
      resolve(file);
    }).catch(() => {
      reject();
    });
  });
};

export const convertResourceURIToBase64 = (resourceURI: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    getFileFromURI(resourceURI).then((resource) => {
      resource.blob().then((blob) => {
        const fr = new FileReader();

        fr.readAsDataURL(blob);

        fr.onload = () => {
          resolve(fr.result as string);
        };
      }).catch(() => {
        reject();
      });
    });
  });
};

export const getLoadedImageSize = (imageURI: string): Promise<{ width: number, height: number }> => {
  return new Promise((resolve) => {
    getFileFromURI(imageURI).then((response) => {
      const mimeType = response.headers.get('Content-Type');

      if (mimeType?.includes('svg')) {
        response.text().then((text) => {
          const parser = new DOMParser();
          const svg = parser.parseFromString(text, 'image/svg+xml').querySelector('svg');
          const viewBox = svg?.viewBox;
          const width = svg?.getAttribute('width') ? parseInt(svg.getAttribute('width') ?? '0', 10) : (viewBox?.baseVal.width ?? 10);
          const height = svg?.getAttribute('height') ? parseInt(svg.getAttribute('height') ?? '0', 10) : (viewBox?.baseVal.height ?? 100);

          resolve({
            width,
            height,
          });
        });
      } else {
        const image = new Image();

        image.src = imageURI;

        image.onload = () => {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
      }
    });
  });
};

export const getFragmentByNewLine = (text: string) => {
  return text.split('\n').map((line, key) => {
    return (
      <Fragment
        key={key}
      >
        { line }<br />
      </Fragment>
    );
  });
};

export const nl2br = (text: string) => {
  return text.replace('\\n', '\n').split('\n').join('<br />');
};

export const nl2brForReact = (text: string) => {
  if (text === undefined) return '';

  return text.replace('\\n', '\n').split('\n').map((t, key) => <span key={key}>{ t }<br /></span>);
};

/**
 * JSON 형식으로 작성 된 문자열을 JSON으로 파싱합니다.
 * 만약 JSON 형태의 문자열이 아닐 경우 undefined를 반환합니다.
 * @param str
 * @returns
 */
export const parseJSON = <T, >(str: string) => {
  try {
    const json = JSON.parse(str) as T;

    return json;
  } catch {
    return undefined;
  }
};

/**
 * 범위 안의 랜덤 숫자를 반환하는 함수입니다.
 * @param max 최대값
 * @param min 최소값
 * @returns 최소값과 최대값 사이의 랜덤한 숫자를 반환함
 */
export const randomInRange = (max: number, min: number): number =>{
  return Math.floor(Math.random() * (max - min) + min);
};

/**
 * <input> 태그를 파라미터로 받아 해당 <input> 의 값을 클립보드에 복사합니다.
 * 모바일 대응을 위해 <input> 태그를 사용합니다.
 * @param inputElement 인풋 창
 */
export const copyValueByInput = async (inputElement: HTMLInputElement): Promise<void> => {
  const text = inputElement.value;

  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    inputElement.select();
    inputElement.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand('copy');
  }
};

/**
 * Dynamic router에서 특정 패턴에 해당하는 값을 추출합니다.
 *
 * @function
 * @example 마이워크 Id를 추출하는 예시 -> pages/share/[myWorkId]
 * const params = getParamsFromDynamicRoute("https:localhost:3000/share/f6b557fa-428c-4ce8-8bec-09d197640a56", "/share/:myWorkId");
 * const myWorkId = params.myWorkId; // -> Dynamic routes에서 설정한 myWorkId 입니다.
 */
export function getParamsFromDynamicRoute<T extends Record<string, string | undefined> = Record<string, string | undefined>>(url: string, pathname: string): Partial<T> | undefined {
  const input = url.split('?')[0];
  const pattern = new URLPattern({ pathname });
  const patternResult = pattern.exec(input);

  return patternResult?.pathname.groups as Partial<T>;
}

export const getOnboardingStepIndex = (onboardingStepName: string | undefined) => {
  return ONBOARDING_STEP_NAMES_BY_ORDER.findIndex((stepName) => stepName === onboardingStepName);
};

/**
 * 브랜드명 온보딩 단계를 완료했는지 판단하기 위한 함수
 * @param onboardingStepName 온보딩 이름
 * @returns 브랜드명 입력 온보딩 단계를 넘어섰는지 여부
 */
export const isCompleteBrandNameOnboardingStep = (onboardingStepName: string | undefined) => {
  const stepIndex = getOnboardingStepIndex(onboardingStepName);
  const brandNameStepIndex = getOnboardingStepIndex(ONBOARDING_STEP_NAME_BRAND_NAME);

  return stepIndex > brandNameStepIndex;
};
