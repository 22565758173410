import { css, Grid } from '@mui/material';
import { xor } from 'lodash';

import { COLOR_NAMES_BY_ORDER, COLOR_PALETTES } from '~/src/constants/colors';

import { AspectRatio } from '../../app/AspectRatio';
import { ColorPaletteChip } from '../../app/ColorPaletteChip/ColorPaletteChip';
import { OnboardingStepColorProps } from '../../app/OnboardingModal/components/OnboardingStep';

const colorPalettes: [string, string[]][] = COLOR_NAMES_BY_ORDER.map((colorName) => {
  return [colorName, COLOR_PALETTES[colorName]];
});

export const OnboardingColorGrid = (props: OnboardingStepColorProps) => {
  const {
    colors: colorNames,
    onChangeColors,
  } = props;

  return (
    <Grid
      container
      columns={{
        desktop: 5,
        laptop: 5,
        tablet: 4,
        mobile: 2,
      }}
      spacing={'16px'}
    >
      {colorPalettes.map((colorPalette, index) => {
        const [colorName, palette] = colorPalette;
        const isSelected = colorNames.includes(colorName);

        return (
          <Grid
            key={index}
            item
            desktop={1}
            laptop={1}
            tablet={1}
            mobile={1}
          >
            <AspectRatio
              ratio="4:3"
            >
              <ColorPaletteChip
                palette={palette}
                css={[
                  css`
                    border-radius: var(--02-Radius-corner-radius-16, 16px);
                    cursor: pointer;
                    &::before {
                      position: absolute;
                      top: -6px;
                      left: -6px;
                      width: 100%;
                      height: 100%;
                      border: 6px solid transparent;
                      border-radius: 22px;
                      background: transparent;
                      outline: 2px solid transparent;
                      transition: 150ms ease-in-out;
                    }
                    &:hover {
                      &::before {
                        content: '';
                        background: var(--grayColor-gray_700, rgba(0, 0, 0, 0.05));
                      }
                    }
                    &:focus {
                      &::before {
                        content: '';
                        background: var(--grayColor-gray_700, rgba(0, 0, 0, 0.08));
                      }
                    }
                  `,
                  isSelected && css`
                    &::before {
                      content: '';
                      border-color: #FFF4EF;
                      // TODO: boon-ui 통합 필요
                      outline-color: var(--color-Brand-600, #FF8E26);
                    }
                  `,
                ]}
                onClick={() => onChangeColors(xor(colorNames, [colorName]))}
              />
            </AspectRatio>
          </Grid>
        );
      })}
    </Grid>
  );
};
