import { API_METHOD_GET, API_METHOD_POST } from '~/src/constants/API';
import { APIError } from '~/src/errors/APIErrors';

export class InternalAPI {
  baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  get(path: string, init?: RequestInit) {
    return fetch(
      `${this.baseURL}/${path}`,
      {
        ...init,
        method: API_METHOD_GET,
      }
    ).then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new APIError(res.status, res.statusText);
      }
    });
  }

  post(path: string, init?: RequestInit) {
    return fetch(
      `${this.baseURL}/${path}`,
      {
        ...init,
        method: API_METHOD_POST,
      }
    ).then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new APIError(res.status, res.statusText);
      }
    });
  }
}
