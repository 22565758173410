import { getCurvedTextAngle, getCurvedTextArcLength, getCurvedTextAreaSize, getFontResourceFromDecorators, getObjectPosition, getTextAlignment, getTextObjectFontSize, getTextObjectLineSpacingCSS, getTextObjectSize, getTextType, PlainTextManager, TEXT_OBJECT_CURVED_TYPE, TEXT_OBJECT_PLAIN_TYPE } from '@lws/common';
import { TemplateTextObject } from '@lws/types';
import URLParse from 'url-parse';

import { DEFAULT_FONT_SIZE } from '~/src/constants/Editor';

import { textToHTMLText } from '../../common';
import { getTextFrameHeight, getTextFrameWidth, radianToDegree } from '../template';

export const getVirtualTextElement = (object: TemplateTextObject) => {
  const {
    text,
    lineSpacing,
    kerning,
    scale,
  } = object;
  const font = getFontFromDecoratorIds(object.decoratorIds);
  const nl2brText = textToHTMLText(text);
  const element = document.createElement('p');
  const letterSpacing = PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);

  const style = `
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    letter-spacing: ${letterSpacing}px;
    line-height: normal;
    font-family: '${font?.fontName}' !important;
    font-size: 50px;
    z-index: 9999;
    zoom: ${object.scale};
  `;

  element.style.cssText = style;

  element.innerHTML = 'A';

  document.body.append(element);

  const singleLineRect = element.clientHeight;
  const lineHeight = singleLineRect * lineSpacing;
  const lines = element.querySelectorAll('p');

  element.innerHTML = nl2brText;

  for (let i = 0; i < lines.length - 1; i += 1) {
    lines[i].style.setProperty('margin-bottom', `${lineHeight}px`);
  }

  const width = element.clientWidth;
  const height = element.clientHeight;

  element.remove();

  return {
    width,
    height,
  };
};

export const getInitializeText = (font: string): Promise<number> => {
  return new Promise((resolve) => {
    document.fonts.load(`16px ${font}`).finally(() => {
      const result = getSingleLineHeight(font);

      resolve(result);
    });
  });
};

export const getSingleLineHeight = (fontName: string) => {
  const element = document.createElement('p');

  const style = `
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    letter-spacing: normal;
    line-height: normal;
    font-family: '${fontName}' !important;
    font-size: 50px;
    z-index: 9999;
  `;

  element.setAttribute('style', style);

  element.innerHTML = 'A';

  document.body.append(element);

  const singleLineHeight = element.clientHeight;

  element.remove();

  return singleLineHeight;
};

export const repaintTextElement = (
  container: HTMLElement,
  editor: HTMLElement,
  canvasWidth: number,
  canvasHeight: number,
  object: TemplateTextObject
) =>{
  const {
    kerning,
    scale,
  } = object;
  const letterSpacing = PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);

  container.style.setProperty('--letter-spacing', `${letterSpacing}px`);

  const width = getTextFrameWidth(editor.clientWidth, object.scale, object.parameters.singleLineHeight, object.fontSize);
  const height = getTextFrameHeight(editor.clientHeight, object.scale);
  const left = canvasWidth * object.centerX - width / 2;
  const top = canvasHeight * object.centerY - height / 2;

  container.style.setProperty('width', `${width}px`);
  container.style.setProperty('height', `${height}px`);
  container.style.setProperty('left', `${left}px`);
  container.style.setProperty('top', `${top}px`);

  return {
    frameWidth: width,
    frameHeight: height,
  };
};

export const getTextBoundingRectFromTextObject = (object: TemplateTextObject) => {
  const {
    decoratorIds,
    kerning,
    scale,
    parameters,
    lineSpacing,
  } = object;

  const {
    singleLineHeight,
  } = parameters;
  const font = getFontFromDecoratorIds(decoratorIds);
  const element = document.createElement('div');
  const lineSpacingStyle = lineSpacing * singleLineHeight - singleLineHeight;
  const letterSpacing = PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);
  const defaultLineHeight = object.parameters.singleLineHeight;

  document.documentElement.append(element);

  element.innerHTML = textToHTMLText(object.text);
  element.style.cssText = `
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    letter-spacing: ${letterSpacing}px;
    line-height: normal;
    font-family: '${font?.fontName}' !important;
    zoom: ${object.scale};
    font-size: 50px;
    z-index: 9999;
  `;

  const children = element.querySelectorAll('p');

  for (let i = 0; i < children.length - 1; i += 1) {
    const child = children[i];
    child.style.setProperty('height', `${defaultLineHeight}px`);
    child.style.setProperty('margin-bottom', `${lineSpacingStyle}px`);
  }

  const rect = element.getBoundingClientRect();

  element.remove();

  return rect;
};

export const getResourceDataFromDecoratorId = (decoratorId: string) => {
  const url = URLParse(decoratorId);
  const [, resourceType, resourceName] = url.pathname.split('/');

  return {
    resourceType,
    resourceName,
  };
};

export const getFontFromDecoratorId = (decoratorId: string) => {
  const { resourceName: fontName } = getResourceDataFromDecoratorId(decoratorId);

  return { fontName, decoratorId };
};

export const getFontFromDecoratorIds = (decoratorIds: string[]) => {
  for (const decoratorId of decoratorIds) {
    const font = getFontFromDecoratorId(decoratorId);

    if (font) return font;
  }

  return undefined;
};

export const fontLoader = (fontName: string, resource: string) => {
  return new Promise<boolean>((resolve) => {
    const isExistFontLoader = Boolean(document.querySelector(`style[data-font='${fontName}']`));

    if (isExistFontLoader) return resolve(true);

    const style = document.createElement('style');

    style.setAttribute('data-font', fontName as string);
    style.innerHTML = `
      @font-face { font-family: '${fontName}'; src: url('${resource}'); }";
    `;

    document.head.append(style);

    document.fonts.load(`16px ${fontName}`).then(() => {
      resolve(true);
    }).catch(() => {
      resolve(false);
    });
  });
};

export const calculateLineSpacingFromSingleLineHeight = (lineSpacing: number, singleLineHeight: number) => {
  const optimizedLineHeight = lineSpacing / 3;
  const multiplier = 3;
  const multiple = optimizedLineHeight * multiplier;
  const _lineHeight = (multiple - 1) * singleLineHeight;

  return _lineHeight;
};

export const calculateScaleFromFontScale = (fontSize: number) => {
  return fontSize / DEFAULT_FONT_SIZE;
};

export const calculateFontScaleFromScale = (scale: number) => {
  return DEFAULT_FONT_SIZE * scale;
};

export const getFontURIFromFontName = (fontName: string) => {
  return `pixo-cms://resource@embedded/font/${fontName}`;
};

export const getTextWrapperElement = (object: TemplateTextObject) => {
  const { parameters } = object;
  const { primaryKey } = parameters;
  const textElement = document.querySelector(`.TextObject[data-primary-key="${primaryKey}"]`) as HTMLElement;

  if (textElement === undefined) return undefined;

  return textElement;
};

export const getTextElementStyles = (
  object: TemplateTextObject,
  canvasWidth: number,
  canvasHeight: number
) => {
  const {
    curve,
    decoratorIds,
    centerX,
    centerY,
    arg,
    kerning,
    scale,
    lineSpacing,
    parameters,
    hexColor,
    flipped,
    alpha,
    textAlignment,
    text,
    radius,
  } = object;

  const { singleLineHeight } = parameters;
  const fontFamily = getFontResourceFromDecorators(decoratorIds);

  if (fontFamily === undefined) return undefined;

  const letterSpacing = PlainTextManager.getLetterSpacingStyleFromKerning(kerning, scale);
  const lineHeight = getTextObjectLineSpacingCSS({ singleLineHeight, lineSpacing });
  const fontSize = getTextObjectFontSize(scale);
  const textType = getTextType(object);
  let frameWidth = 0;
  let frameHeight = 0;

  if (textType === TEXT_OBJECT_PLAIN_TYPE) {
    const {
      width,
      height,
    } = getTextObjectSize({
      text,
      fontFamily,
      letterSpacing,
      lineSpacing: lineHeight,
      fontSize,
    });

    frameWidth = width;
    frameHeight = height;
  } else if (textType === TEXT_OBJECT_CURVED_TYPE) {
    const arcLength = getCurvedTextArcLength({
      text,
      fontFamily,
      fontSize,
      letterSpacing,
    });
    const angle = getCurvedTextAngle({ arcLength, radius });
    const {
      width,
      height,
    } = getCurvedTextAreaSize({ angle, radius, singleLineHeight });

    frameWidth = width;
    frameHeight = height;
  }

  const {
    left,
    top,
  } = getObjectPosition({
    frameWidth,
    frameHeight,
    centerX,
    centerY,
    canvasWidth,
    canvasHeight,
  });

  const degree = radianToDegree(arg);
  const alignment = getTextAlignment(textAlignment);

  return {
    curve,
    frameWidth,
    frameHeight,
    degree,
    letterSpacing,
    lineHeight,
    fontFamily,
    alignment,
    left,
    top,
    hexColor,
    flipped,
    alpha,
    scale,
  };
};

export const setTextElementStyle = (
  object: TemplateTextObject,
  canvasWidth: number,
  canvasHeight: number
) => {
  const element = getTextWrapperElement(object);
  const styles = getTextElementStyles(object, canvasWidth, canvasHeight);
  const plainTextWrapper = element?.querySelector('div[data-element-type="plain-text-editor"]') as HTMLElement;
  const curvedTextWrapper = element?.querySelector('div[data-element-type="curved-text-editor"]') as HTMLElement;

  if (
    element &&
    plainTextWrapper &&
    curvedTextWrapper &&
    styles
  ) {
    const {
      curve,
      frameWidth,
      frameHeight,
      left,
      top,
      degree,
      letterSpacing,
      lineHeight,
      fontFamily,
      scale,
      hexColor,
      flipped,
      alpha,
      alignment,
    } = styles;

    plainTextWrapper.style.setProperty('opacity', !curve ? '1' : '0');
    plainTextWrapper.style.setProperty('pointer-events', !curve ? 'auto' : 'none');
    curvedTextWrapper.style.setProperty('opacity', curve ? '1' : '0');
    curvedTextWrapper.style.setProperty('pointer-events', curve ? 'auto' : 'none');

    element.style.setProperty('width', `${frameWidth}px`);
    element.style.setProperty('height', `${frameHeight}px`);
    element.style.setProperty('left', `${left}px`);
    element.style.setProperty('top', `${top}px`);
    element.style.setProperty('transform', `rotateZ(${degree}deg)`);
    element.style.setProperty('--letter-spacing', `${letterSpacing}px`);
    element.style.setProperty('--line-spacing', `${lineHeight}px`);
    element.style.setProperty('--font-family', fontFamily);
    element.style.setProperty('--font-scale', `${scale}`);
    element.style.setProperty('--color', hexColor);
    element.style.setProperty('--fliped-degree', flipped ? '180deg' : '0');
    element.style.setProperty('--alpha', `${alpha}`);
    element.style.setProperty('--alignment', alignment);
  }
};

