import { useTranslation } from 'next-i18next';
import { Fragment } from 'react';

import { Nl2brProps } from './Nl2br.types';

export const Nl2br = ({
  children,
}: Nl2brProps) => {
  const { t } = useTranslation(['system']);
  const errMsg = t('system:error.no-string');

  if (typeof children !== 'string') throw new Error(errMsg);

  const parts = children.split('\n').map((text, key) => (
    <Fragment
      key={key}
    >
      { text }<br />
    </Fragment>
  ));

  return (
    <>{ parts }</>
  );
};
