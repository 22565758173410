import { css } from '@mui/material';

import { ComponentProps } from '~/src/types/common';

interface PaletteItemProps extends ComponentProps {
  palette: string[];
  onClick?: () => void;
}

export const ColorPaletteChip = (props: PaletteItemProps) => {
  const { palette, onClick, ...others } = props;

  return (
    <button
      {...others}
      css={css`
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        border: 0;
        outline: none;
      `}
      onClick={onClick}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          border-radius: inherit;
          overflow: hidden;
          pointer-events: none;
        `}
      >
        {palette.map((color) => {
          return (
            <div
              key={color}
              css={css`
                flex: 1;
                width: 100%;
              `}
              style={{
                backgroundColor: color,
              }}
            />
          );
        })}
      </div>
    </button>
  );
};
