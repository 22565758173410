import { memo, useContext } from 'react';

import { RendererContext } from '../Renderer/RendererProvider';
import { StickerObjectProps } from './StickerObject.types';
import { StickerProvider } from './StickerProvider';
import { StickerResourceRenderer } from './StickerResourceRenderer';
import { StickerStyler } from './StickerStyler';

export const StickerObject = memo(({
  object,
}: StickerObjectProps) => {
  const context = useContext(RendererContext);

  if (context === undefined) throw new Error('RendererProvider not declared.');
  if (!object) throw new Error('No object data');
  if (object.type !== 'CLStickerView') throw new Error('It is not valid type object ');

  const { parameters } = object;

  if (parameters?.primaryKey === undefined) throw new Error('There is no unique id');

  return (
    <StickerProvider object={object}>
      <StickerStyler>
        <StickerResourceRenderer />
      </StickerStyler>
    </StickerProvider>
  );
});
