import { css } from '@emotion/react';
import classNames from 'classnames';

import { ProductPlan } from '~/src/api/v1/PolicyAPI';
import { usePlansContext } from '~/src/context/PlansProvider';
import { useMediaQuery } from '~/src/hooks/v1/Common/useMediaQuery';
import { ComponentProps } from '~/src/types/common';

import { CheckoutCard } from './components/CheckoutCard/CheckoutCard';

interface PlanCheckoutProps extends ComponentProps {
  onClickOneShotPlan?: (plan: ProductPlan) => void;
  onClickYearlyPlan?: (plan: ProductPlan) => void;
}

export const PlanCheckout = (props: PlanCheckoutProps) => {
  const { onClickOneShotPlan, onClickYearlyPlan } = props;

  const { localizedPlans } = usePlansContext();
  const laptop = useMediaQuery('laptop');

  return (
    <article
      className={classNames(
        'PlanCheckout',
        props.className
      )}
      data-testid="PlanCheckout"
      css={css`
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 42px;
        row-gap: 42px;
        ${laptop.down.mq} {
          flex-direction: column-reverse;
        }
      `}
    >
      {(localizedPlans ?? []).map(plan => {
        return (
          <CheckoutCard
            key={plan.planId}
            plan={plan}
            selected={plan.planType === 'yearly'}
            css={css`
              ${laptop.down.mq} {
                margin: auto;
              }
            `}
            onClickPlan={() => {
              // plan type에 맞게 amplitude 이벤트와 click 이벤트를 호출합니다.
              if (plan.planType === 'one-shot') {
                onClickOneShotPlan && onClickOneShotPlan(plan);
              } else if (plan.planType === 'yearly') {
                onClickYearlyPlan && onClickYearlyPlan(plan);
              }
            }}
          />
        );
      })}
    </article>
  );
};
