import { Fragment, useEffect, useState } from 'react';

import { CSROnly } from '../CSROnly';
import { TickProps } from './Tick.types';

/**
 * Web Worker를 사용한 Timer 컴포넌트입니다.
 * period 주기마다 children으로 전달 된 함수가 실행되어 children 함수에서 반환 되는 ReactNode를 출력합니다.
 * Hydration 이슈로 인해 CSR에서만 노출됩니다.
 */
export const Tick = ({
  disabled = false,
  period = 1000,
  callback,
  children,
}: TickProps) => {
  const [newChildren, setNewChildren] = useState(children?.(new Date()));

  useEffect(() => {
    if (disabled === false) {
      const worker = new Worker(new URL('../../workers/tick.ts', import.meta.url));

      worker.postMessage({ type: 'cooldown', data: period });
      worker.addEventListener('message', (event) => {
        if (event.data?.type === 'tick') {
          callback?.(new Date());
          setNewChildren(children?.(new Date()));
        }
      });

      return () => {
        worker.terminate();
      };
    }
  }, [
    disabled,
    callback,
    children,
  ]);

  return (
    <Fragment>
      <CSROnly>
        { newChildren }
      </CSROnly>
    </Fragment>
  );
};
