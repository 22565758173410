import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { useErrorBoundary } from 'react-error-boundary';

import { ClientErrorMessage } from '../ClientErrorMessage';

// 전역 예외에 잡히는 에러 발생시 보여지는 에러 컴포넌트
export const ErrorFallback = () => {
  const { t } = useTranslation(['common']);
  const { resetBoundary } = useErrorBoundary();
  const errorMessage =
    typeof t('common:error.unknown-error') === 'string'
      ? t('common:error.unknown-error')
      : 'An error has occurred. We will collect this error for improvement. We apologize for the inconvenience.';

  return (
    <ErrorFallbackWrapper>
      <ClientErrorMessage
        css={css`
          margin: auto;
        `}
        message={errorMessage}
        homeButtonText="Reset"
        homeButtonCallback={resetBoundary}
      />
    </ErrorFallbackWrapper>
  );
};

const ErrorFallbackWrapper = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
`;

export default ErrorFallback;
