
import { AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders, RawAxiosResponseHeaders } from 'axios';

import { AddMyWorkItemBody, AddMyWorkItemResponse, CopyMyWorkItemPayload, DetailedMyWorkResponse, ExportMyWorkParams, GetMyWorkListParams, GetMyWorkListResponse, GetTrashMyWorkListParams, GetTrashMyWorkListResponse, SaveMyWorkPayload, UpdateMyWorkItemMetadataPayload } from '../../../types/my-work';
import { APIClient } from '../APIClient';

export class MyWorksAPI extends APIClient {
  getList(userId: string, params: GetMyWorkListParams, headers?: RawAxiosRequestHeaders) {
    const _headers: RawAxiosResponseHeaders = {
      'Cache-Control': 'max-age=0',
      ...headers,
    };

    return this.axios.get<GetMyWorkListResponse>(`/${this.appName}/users/${userId}/myWorks/list`, {
      headers: _headers,
      params: {
        ...params,
        t: Date.now(),
      },
    });
  }

  deleteItem(userId: string, myWorkId: string): Promise<AxiosResponse<void>> {
    return this.axios.put(`/${this.appName}/users/${userId}/myWorks/${myWorkId}/trash`);
  }

  addItem(userId: string, config?: AxiosRequestConfig<AddMyWorkItemBody> | AddMyWorkItemBody): Promise<AxiosResponse<AddMyWorkItemResponse>> {
    return this.axios.post(`/${this.appName}/users/${userId}/myWorks`, config);
  }

  copyItem(userId: string, myWorkId: string, config?: AxiosRequestConfig<CopyMyWorkItemPayload> | CopyMyWorkItemPayload) {
    return this.axios.post(`/${this.appName}/users/${userId}/myWorks/${myWorkId}`, config);
  }

  getItem(userId: string, myWorkId: string, headers?: RawAxiosRequestHeaders) {
    const _headers = {
      'Cache-Control': 'max-age=0',
      ...headers,
    };

    return this.axios.get<DetailedMyWorkResponse>(
      `/${this.appName}/users/${userId}/myWorks/${myWorkId}`,
      { headers: _headers }
    );
  }

  getTrashItemsCount(userId: string) {
    const params = {
      limit: 1,
      includeTotalCount: true,
    };

    return this.getTrashList(userId, params);
  }

  updateItemMetadata(userId: string, myWorkId: string, config?: AxiosRequestConfig<UpdateMyWorkItemMetadataPayload> | UpdateMyWorkItemMetadataPayload) {
    return this.axios.patch(`/${this.appName}/users/${userId}/myWorks/${myWorkId}`, config);
  }

  getTrashList(userId: string, params?: GetTrashMyWorkListParams, headers?: RawAxiosRequestHeaders) {
    const _headers: RawAxiosResponseHeaders = {
      'Cache-Control': 'max-age=0',
      ...headers,
    };

    return this.axios.get<GetTrashMyWorkListResponse>(`/${this.appName}/users/${userId}/myWorks/trash/list`, {
      headers: _headers,
      params: {
        ...params,
        t: Date.now(),
      },
    }
    );
  }

  deleteTrashItem(userId: string, myWorkId: string) {
    return this.axios.delete(`/${this.appName}/users/${userId}/myWorks/${myWorkId}`);
  }

  restoreTrashItem(userId: string, myWorkId: string) {
    return this.axios.post(`/${this.appName}/users/${userId}/myWorks/${myWorkId}/restore`);
  }

  save(userId: string, myWorkId: string, config?: AxiosRequestConfig<SaveMyWorkPayload> | SaveMyWorkPayload) {
    return this.axios.put(`/${this.appName}/users/${userId}/myWorks/${myWorkId}`, config);
  }

  saveAsGuest(myWorkId: string, payload: SaveMyWorkPayload) {
    return this.axios.put(`/${this.appName}/users/guest/myWorks/${myWorkId}`, { ...payload });
  }

  migrate(userId: string, myWorkId: string) {
    return this.axios.patch(`/${this.appName}/users/${userId}/myWorks/${myWorkId}/migrate`);
  }

  export(userId: string, myWorkId: string, params: ExportMyWorkParams) {
    return this.axios.post(
      `/${this.appName}/users/${userId}/myWorks/${myWorkId}/export`,
      null,
      { params }
    );
  }
}

export const myWorksAPIClient = new MyWorksAPI;

export default myWorksAPIClient;
