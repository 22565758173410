import facepaint from 'facepaint';

const weightBold = 700;
const weightSemibold = 600;
const weightMedium = 500;
const weightRegular = 400;

const breakpoints = {
  desktop: 1366,
  laptop: 960,
  tablet: 600,
  mobile: 0,
};

const typographyOptions = {
  'basic-font': 'Pretendard',
  'weight-semibold': weightSemibold,
  'weight-medium': weightMedium,
  'weight-regular': weightRegular,
};

export const variants = {
  'h1': {
    fontSize: '56px',
    fontWeight: weightBold,
    lineHeight: '130%',
  },
  'h2': {
    fontSize: '48px',
    fontWeight: weightBold,
    lineHeight: '130%',
  },
  'h3': {
    fontSize: '36px',
    fontWeight: weightBold,
    lineHeight: '130%',
  },
  'h4': {
    fontSize: '32px',
    fontWeight: weightBold,
    lineHeight: '130%',
  },
  'h5': {
    fontSize: '24px',
    fontWeight: weightBold,
    lineHeight: '130%',
  },
  'h6': {
    fontSize: '18px',
    fontWeight: weightBold,
    lineHeight: '130%',
  },
  'body1': {
    fontWeight: weightMedium,
    fontSize: '20px',
    lineHeight: '130%',
  },
  'body2': {
    fontWeight: weightMedium,
    fontSize: '18px',
    lineHeight: '130%',
  },
  'body3': {
    fontSize: '18px',
    fontWeight: weightRegular,
    lineHeight: '130%',
  },
  'body4': {
    fontSize: '16px',
    fontWeight: weightRegular,
    lineHeight: '130%',
  },
  'body5': {
    fontSize: '16px',
    fontWeight: weightMedium,
    lineHeight: '130%',
  },
  'body6': {
    fontSize: '14px',
    fontWeight: weightRegular,
    lineHeight: '130%',
  },
  'body7': {
    fontSize: '14px',
    fontWeight: weightMedium,
    lineHeight: '130%',
  },
  'body8': {
    fontSize: '12px',
    fontWeight: weightRegular,
    lineHeight: '130%',
  },
  'body9': {
    fontSize: '12px',
    fontWeight: weightRegular,
    lineHeight: '130%',
  },
  'body10': {
    fontSize: '10px',
    fontWeight: weightRegular,
    lineHeight: '130%',
  },
  'body11': {
    fontSize: '10px',
    fontWeight: weightMedium,
    lineHeight: '130%',
  },

  'button1': {
    fontSize: '20px',
    fontWeight: weightMedium,
    lineHeight: '130%',
  },
  'button2': {
    fontSize: '16px',
    fontWeight: weightMedium,
    lineHeight: '130%',
  },
  'button3': {
    fontSize: '18px',
    fontWeight: weightMedium,
    lineHeight: '130%',
  },
  'button4': {
    fontSize: '16px',
    fontWeight: weightRegular,
    lineHeight: '130%',
  },
  'button5': {
    fontSize: '14px',
    fontWeight: weightRegular,
    lineHeight: '130%',
  },
  'p': {
    fontSize: '16px',
    fontWeight: weightRegular,
    lineHeight: '130%',
  },
  'sm': {
    fontSize: '14px',
    fontWeight: weightMedium,
    lineHeight: '130%',
  },
  'xs': {
    fontSize: '12px',
    fontWeight: weightRegular,
    lineHeight: '130%',
  },
};

export const DefaultTheme = {
  colors: {
    // Primary Color
    'primary-color-01': '#FF783E',
    'primary-color-02': '#FF8E26',
    'primary-color-03': '#FFBD84',
    'primary-color-04': '#FFD9B9',
    'primary-color-05': '#F7EFE8',
    'primary-color-06': '#F7EFE8',
    'primary-gradient-01': 'linear-gradient(283.28deg, #FF6525 12.92%, #FF8E25 83.6%)',

    // Neutral Color
    'neutral-color-01': '#1C1C1C',
    'neutral-color-02': '#252525',
    'neutral-color-03': '#393939',
    'neutral-color-04': '#464748',
    'neutral-color-05': '#5A5B5C',
    'neutral-color-06': '#8B8C8E',
    'neutral-color-07': '#CCCCCC',
    'neutral-color-08': '#DDDDDD',
    'neutral-color-09': '#F2F2F2',
    'neutral-color-10': '#FFFFFF',

    'pure-black': '#000000',
    'gray-color-gray-50': '#F5F5F5',
    'gray-color-gray-700': '#3D3D3D',
    'inverse-background-background-inverse-secondary': '#282828',

    // Second Color
    'second-color-1': '#9CA9EB',
    'second-color-2': '#686CC7',
    'second-color-3': '#F2F7FD',
  },
  typography: {
    ...typographyOptions,
    ...variants,
  },
  variables: {
    'container-padding-left': 50,
    'container-padding-right': 50,
    'container-padding-bottom': 100,
    'container-padding-top': 50,
  },
  breakpoints,
  mq: facepaint(Object.values(breakpoints).map((width) => `@media (min-width: ${width}px)`)),
};

export type DEFAULT_THEME = typeof DefaultTheme;

export type TYPOGRAPHY_VARIANTS = keyof typeof variants;
