import _ from 'lodash';
import { useRouter } from 'next/router';
import { Fragment, PropsWithChildren, useCallback, useEffect } from 'react';

import { myWorksAPIClient } from '~/src/api/v2/MyWorksAPI';
import { USER_PLAN_ONE_SHOT, USER_PLAN_YEARLY } from '~/src/constants/UserPlan';
import { useAuth } from '~/src/hooks/v2/Account/useAuth';
import { usePayment } from '~/src/hooks/v2/Account/usePayment';

import { ACTION_CREATE_MYWORK, ACTION_PAYMENT_PAYPLE, ACTION_SIGNIN, ACTION_THROTTLE_DELAY } from '../../../constants/RouteAction';
import { useLottieLoadingOverlay } from '../../app/LottieLoadingOverlayProvider';
import { useAccountDialog } from '../Dialogs';

type ActionParamsKeys = `action_params_${string}`;

type RouteQuery = {
  // eslint-disable-next-line no-unused-vars
  [K in ActionParamsKeys]?: string;
} & { action?: string };

export const RouteActionProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const query = router.query as RouteQuery;

  const { userId, name } = useAuth();

  const {
    show: showAccountDialog,
  } = useAccountDialog();
  const {
    callOneShotPayment,
    callSubscriptionPayment,
  } = usePayment();
  const {
    show: showLottieLoadingOverlay,
    hide: hideLottieLoadingOverlay,
  } = useLottieLoadingOverlay();

  const { action } = query;

  /**
   * 페이플 결제창을 바로 열 수 있는 액션입니다.
   */
  const actionPaymentPayple = useCallback(_.throttle((
    query: RouteQuery,
    path: string
  ) => {
    const {
      action_params_currency: currency,
      action_params_planId: planId,
      action_params_planType: planType,
      action_params_planName: planName,
      action_params_price: price,
    } = query;

    if (currency && planId && planType && planName && price) {
      if (planType === USER_PLAN_YEARLY) {
        callSubscriptionPayment({
          currency,
          planName,
          price,
          planId,
          planType,
        });
      } else if (planType === USER_PLAN_ONE_SHOT) {
        callOneShotPayment({
          currency,
          planName,
          price,
          planId,
          planType,
        });
      }

      router.replace(path, undefined, { shallow: true });
    }
  }, ACTION_THROTTLE_DELAY), []);

  /**
   * 로그인 모달을 바로 열 수 있는 액션입니다.
   */
  const actionSignin = useCallback(_.throttle((query: RouteQuery, path: string) => {
    const {
      action_params_redirectUri,
    } = query;

    showAccountDialog(action_params_redirectUri);
    router.replace(path, undefined, { shallow: true });
  }, ACTION_THROTTLE_DELAY), []);

  /**
   * 마이워크를 바로 생성할 수 있는 액션입니다.
   */
  const actionCreateMyWork = useCallback(_.throttle((query: RouteQuery, userId?: string, name?: string) => {
    // 마이워크 자동 생성 하기
    const {
      action_params_templateId: templateId,
    } = query;

    if (userId && name && templateId) {
      const loadingOverlay = showLottieLoadingOverlay();

      myWorksAPIClient
        .addItem(userId, { author: name, templateId })
        .then(({ data }) => {
          return router.push(`/editor?myWorkId=${data.myWorkId}`);
        }).finally(() => {
          hideLottieLoadingOverlay(loadingOverlay);
        });
    }

    router.replace(router.pathname, undefined, { shallow: true });
  }, ACTION_THROTTLE_DELAY), []);

  useEffect(() => {
    if (action === ACTION_PAYMENT_PAYPLE) {
      // 페이플 팝업 열기
      actionPaymentPayple(query, router.pathname);
    } else if (action === ACTION_SIGNIN) {
      actionSignin(query, router.pathname);
    } else if (action === ACTION_CREATE_MYWORK) {
      actionCreateMyWork(query, userId, name);
    }
  }, [
    action,
    actionCreateMyWork,
    actionPaymentPayple,
    actionSignin,
    query,
    router,
    userId,
    name,
  ]);

  return (
    <Fragment>
      { children }
    </Fragment>
  );
};
