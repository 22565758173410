import { TemplateStickerObject } from '@lws/types';
import uuid from 'react-uuid';
import URLParse from 'url-parse';

import { calculateImageMaxSize } from '../../utils/editor/template';
import { ObjectManager } from '../ObjectManager';

export class StickerObjectManager extends ObjectManager<TemplateStickerObject> {
  constructor(object?: Partial<TemplateStickerObject>) {
    const defaultObject: Partial<TemplateStickerObject> = {
      type: 'CLStickerView',
      centerX: 0.5,
      centerY: 0.5,
      hexColor: '#none',
      alpha: 1,
      maskImageCenterX: 0,
      maskImageCenterY: 0,
      maskImageScale: 0,
      arg: 0,
      hue: 0,
      scale: 1,
      brightness: 0,
      decoratorIds: [],
      parameters: {
        primaryKey: uuid(),
      },
    };

    super(object ? object : defaultObject);
  }

  /**
   * 리소스 ID를 contentId에 적용합니다.
   * @param resourceId Resource ID
   * @returns
   */
  public setContentId(resourceId: string) {
    if (this.object === undefined) return this;

    this.object = {
      ...this.object,
      contentId: `pixo-app://resource@embedded/symbol/${resourceId}`,
    };

    return this;
  }

  public getResourceId() {
    if (this.object.contentId === undefined) return undefined;

    const parser = URLParse(this.object.contentId);
    const [,, resourceId] = parser.pathname.split('/');

    return resourceId;
  }

  /**
   * FrameWidth와 FrameHeight를 설정합니다.
   * @param imageWidth
   * @param imageHeight
   * @param canvasWidth
   * @param canvasHeight
   * @param deviceScale
   * @returns
   */
  public initializeObjectSize(
    imageWidth: number,
    imageHeight: number,
    canvasWidth: number,
    canvasHeight: number,
    deviceScale: number
  ) {
    if (this.object === undefined) return this;

    const canvasStandardSize = Math.min(canvasWidth, canvasHeight);
    const imageMaxSize = calculateImageMaxSize(canvasWidth, canvasHeight);
    const symbolStandardSize = Math.round(imageMaxSize * deviceScale) + 36;
    const scale = (canvasStandardSize * 0.5) / symbolStandardSize;
    const frameSize = imageMaxSize * scale * deviceScale;

    const frameWidth = imageWidth > imageHeight ? frameSize : frameSize * (imageWidth / imageHeight);
    const frameHeight = imageHeight > imageWidth ? frameSize : frameSize * (imageHeight / imageWidth);

    this.object = {
      ...this.object,
      frameWidth,
      frameHeight,
      scale,
    };

    return this;
  }
}
