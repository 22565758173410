import { css } from '@emotion/react';

import AmazonWebServicesLogo from '@/assets/svgs/amazon_web_services_logo.svg';
import GoogleCloudLogo from '@/assets/svgs/google_cloud_logo.svg';
import JCBLogo from '@/assets/svgs/jcb_logo.svg';
import MastercardLogo from '@/assets/svgs/mastercard_logo.svg';
import VisaLogo from '@/assets/svgs/visa_logo.svg';
import { ProductPlan } from '~/src/api/v1/PolicyAPI';
import { PlanBenefit } from '~/src/components/business/PlanContainer/components/PlanBenefit/PlanBenefit';
import { PlanCheckout } from '~/src/components/business/PlanContainer/components/PlanCheckout/PlanCheckout';
import { PlanPartnership } from '~/src/components/business/PlanContainer/components/PlanPartnership/PlanPartnership';
import { PlanReview } from '~/src/components/business/PlanContainer/components/PlanReview/PlanReview';
import { PartnershipLogo } from '~/src/types/common';

import { QuestionsSection } from './components/QuestionsSection';

interface PlanContainerProps {
  plans: ProductPlan[];
  onClickOneShotPlan?: (plan: ProductPlan) => void;
  onClickYearlyPlan?: (plan: ProductPlan) => void;
}

const partnerships: PartnershipLogo[] = [
  { src: AmazonWebServicesLogo, alt: 'amazon_web_services_logo' },
  { src: MastercardLogo, alt: 'mastercard_logo' },
  { src: GoogleCloudLogo, alt: 'google_cloud_logo' },
  { src: VisaLogo, alt: 'visa_logo' },
  { src: JCBLogo, alt: 'jcb_logo' },
];

export const PlanContainer = (props: PlanContainerProps) => {
  const { onClickOneShotPlan, onClickYearlyPlan } = props;

  return (
    <>
      <PlanCheckout
        css={css`
          margin-top: 76px;
        `}
        onClickOneShotPlan={onClickOneShotPlan}
        onClickYearlyPlan={onClickYearlyPlan}
      />
      <PlanPartnership
        partnerships={partnerships}
        css={css`
          margin-top: 72px;
        `}
      />
      <PlanReview
        css={css`
          max-width: calc(1050px + 100px);
          margin: auto;
          margin-top: 120px;
        `}
      />
      <PlanBenefit
        css={css`
          margin-top: 100px;
        `}
      />
      <QuestionsSection />
    </>
  );
};
