import { GetObjectPositionArguments } from './types';

export const convertDegToRad = (degree: number) => {
  return degree * (Math.PI / 180);
};

export const convertRadToDeg = (radian: number) => radian * (180 / Math.PI);

export const getObjectPosition = ({
  frameWidth,
  frameHeight,
  centerX,
  centerY,
  canvasWidth,
  canvasHeight,
}: GetObjectPositionArguments) => {
  const left = canvasWidth * centerX - frameWidth / 2;
  const top = canvasHeight * centerY - frameHeight / 2;

  return { left, top };
};

export const getObjectRotateCSS = (angle: number, isDegree = false) => {
  return isDegree ? `rotateZ(${angle}deg)` : `rotateZ(${angle}rad)`;
};

export const getFlipCSS = (isFlip: boolean) => {
  return isFlip ? 'rotateY(180deg)' : 'rotateY(0)';
};

export const getSingleLineText = (text: string) => {
  return text.replaceAll('\\n', '\n').replaceAll('\n', '&nbsp');
};
