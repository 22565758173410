import { useContext } from 'react';

import { AccountDialogContext } from './AccountDialogProvider';

export const useAccountDialog = () => {
  const context = useContext(AccountDialogContext);

  if (context === undefined) throw new Error('AccountDialogProvider is not defined.');

  const {
    show,
    hide,
    getRedirectUri,
    removeRedirectUri,
  } = context;

  return {
    show,
    hide,
    getRedirectUri,
    removeRedirectUri,
  };
};
