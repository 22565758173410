export const MAXIMUM_ZOOM_SCALE = 5;

export const MINIMUM_ZOOM_SCALE = 0.1;

export const INITIAL_ZOOM_SCALE = 1;

export const MIN_KERNING_SLIDER_VALUE = 0;

export const MAX_KERNING_SLIDER_VALUE = 1;

export const KERNING_MULTIPLIER = 35;

export const MIN_CONTENTFUL_KERNING_VALUE = -.5;

export const MAX_CONTENTFUL_KERNING_VALUE = 1;

export const CONTENTFUL_DEFAULT_KERNING_VALUE = 1;

export const CENTER_KERNING_VALUE = (MIN_KERNING_SLIDER_VALUE + MAX_KERNING_SLIDER_VALUE) / 2;

export const DEFAULT_FONT_SIZE = 50;

export const MIN_FONT_SCALE = 20 / DEFAULT_FONT_SIZE / 2;

export const MAX_FONT_SCALE = 6;

export const DEFAULT_LINE_SPACING = 1;

export const MIN_LINE_SPACING_SLIDER_VALUE = 0;

export const MAX_LINE_SPACING_SLIDE_VALUE = 1;

export const MIN_LINE_SPACING_VALUE = 0.000001;

export const MAX_LINE_SPACING_VALUE = 1;

export const DEFAULT_LINE_HEIGHT_MULTIPLIER = 3;

/**
 * 텍스처의 위치 표현 범위
 * 최대 1, 최소 0
 * maskImageCenterX, maskImageCenterY는 0 ~ 1까지 비율로 표현함
 * { maskImageCenterX: 0.5, maskImageCenterY: 0.5 } 라면 textureWidth, textureHeight의 중심점으로부터 가로, 세로 반만큼 움직였다는 의미
 */
export const MAX_TEXTURE_IMAGE_CENTER = 1;
export const MIN_TEXTURE_IMAGE_CENTER = 0;

/**
 * 실제 Contentful에 들어가게 될 lineSpacing 최소값
 * ex)  MIN_LINE_SPACING_VALUE = 0.000001
 *      DEFAULT_LINE_HEIGHT_MULTIPLIER = 3
 *      MIN_LINE_SPACING_VALUE * DEFAULT_LINE_HEIGHT_MULTIPLIER = 0.000003
 */
export const MIN_CONTENTFUL_LINE_SPACING_VALUE = MIN_LINE_SPACING_VALUE * DEFAULT_LINE_HEIGHT_MULTIPLIER;

/**
 * 실제 Contentful에 들어가게 될 lineSpacing 최대
 * ex)  MAX_LINE_SPACING_VALUE = 1
 *      DEFAULT_LINE_HEIGHT_MULTIPLIER = 3
 *      MAX_LINE_SPACING_VALUE * DEFAULT_LINE_HEIGHT_MULTIPLIER = 3
 */
export const MAX_CONTENTFUL_LINE_SPACING_VALUE = MAX_LINE_SPACING_VALUE * DEFAULT_LINE_HEIGHT_MULTIPLIER;

/**
 * Legacy
 * iOS 에서 특정 이유로 스펙이 변경되면서 레거시를 변환하기 위해 사용하는 값
 */
export const DEFAULT_LINE_HEIGHT_MULTIPLE = 0.20000000298023224;

/**
 * 모든 폰트에 대응 되는 lineHeight를 구하는게 사실상 불가능하기 때문에 0.75 근사치로 표현
 */
export const DEFAULT_ORIGINAL_LINE_HEIGHT_MULTIPLE = 0.5;

/**
 * 화면에 렌더링 되는 템플릿의 최대 사이즈
 */
export const MAX_VISUAL_TEMPLATE_WIDTH = 2400;
export const MAX_VISUAL_TEMPLATE_HEIGHT = 2400;

export const EDITOR_CONFIG = {
  // 자간
  MIN_KERNING_SLIDER_VALUE,
  MAX_KERNING_SLIDER_VALUE,
  CENTER_KERNING_VALUE,

  // 폰트 사이즈
  MIN_FONT_SCALE,
  MAX_FONT_SCALE,

  // 행간
  MIN_LINE_SPACING_SLIDER_VALUE,
  MAX_LINE_SPACING_SLIDE_VALUE,
  MIN_LINE_SPACING_VALUE,
  MAX_LINE_SPACING_VALUE,
  DEFAULT_LINE_HEIGHT_MULTIPLIER,
  DEFAULT_LINE_HEIGHT_MULTIPLE,

  // 캔버스 리사이즈 수치
  MAX_VISUAL_TEMPLATE_WIDTH,
  MAX_VISUAL_TEMPLATE_HEIGHT,
};

// 줌 확대 옵션
export const ZOOM_LIST_OPTIONS = [
  300,
  200,
  125,
  100,
  75,
  50,
  25,
  10,
];

export default EDITOR_CONFIG;

export const INDIVIDUAL_DOWNLOAD_TYPE = 'individual';
export const BATCH_DOWNLOAD_TYPE = 'batch';
export const NO_DOWNLOAD_TYPE = 'no';

export const BACKGROUND_TYPE_COLOR = 'color';
export const BACKGROUND_TYPE_GRADIENT = 'gradient';
