import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import classNames from 'classnames';
import Image from 'next/image';

import { IconProps, IconSet } from './Icon.types';

export const Icon: React.FC<IconProps> = (props) => {
  const theme = useTheme();
  const icon = IconSet[props.icon];
  const initializedWidth = icon['3x'].width / 3;
  const sizes = `
    (max-width: ${theme.app.breakpoints.tablet}px) ${initializedWidth}px,
    (max-width: ${theme.app.breakpoints.laptop}px) ${initializedWidth}px,
    ${initializedWidth}px
  `;

  return (
    <Image
      className={classNames(
        'Icon',
        props.className
      )}
      style={props.style}
      src={icon['3x'].src}
      sizes={sizes}
      width={props.width ?? icon['3x'].width}
      height={props.height ?? icon['3x'].height}
      draggable={false}
      alt=""
      priority
      css={css`
        user-select: none;
      `}
      { ...props }
    />
  );
};
