import { css, styled, Typography, useTheme   } from '@mui/material';
import classNames from 'classnames';
import { MouseEventHandler, useMemo } from 'react';

import { Button } from '~/src/components/app/Button/v1';
import { Icon } from '~/src/components/app/Icon/v1';
import { AUTH_PROVIDER_APPLE, AUTH_PROVIDER_FACEBOOK, AUTH_PROVIDER_GOOGLE } from '~/src/constants/Auth';
import { AuthProviderName, ComponentProps } from '~/src/types/common';

export interface SocialButtonProps extends ComponentProps {
  variant?: AuthProviderName;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const SocialButton = (props: SocialButtonProps) => {
  const theme = useTheme();

  const label = useMemo(() => {
    if (props.variant === AUTH_PROVIDER_GOOGLE) return 'Continue with Google';
    if (props.variant === AUTH_PROVIDER_FACEBOOK) return 'Continue with Facebook';
    if (props.variant === AUTH_PROVIDER_APPLE) return 'Continue with Apple';

    return '';
  }, [props.variant]);

  const IconContainer = styled(Icon)`
    margin-right: 20px;
  `;

  return (
    <Button
      className={classNames(
        props.className
      )}
      style={props.style}
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 8px;
        border-color: ${theme.app.colors['neutral-color-09']} !important;
        background-color: ${theme.app.colors['neutral-color-09']} !important;
      `}
      color="plain"
      onClick={props.onClick}
    >
      {
        props.variant === AUTH_PROVIDER_GOOGLE &&
          <IconContainer
            icon="login-google"
            width={24}
            height={24}
          />
      }
      {
        props.variant === AUTH_PROVIDER_FACEBOOK &&
          <IconContainer
            icon="login-facebook"
            width={24}
            height={24}
          />
      }
      {
        props.variant === AUTH_PROVIDER_APPLE &&
          <IconContainer
            icon="login-apple"
            width={24}
            height={24}
          />
      }
      <Typography
        css={css`
          flex: 1;
          text-align: left;
          color: ${theme.app.colors['neutral-color-04']};
        `}
        variant="button4"
      >
        { label }
      </Typography>
    </Button>
  );
};
