import styled from '@emotion/styled';
import { Button as MuiButton } from '@mui/material';

import {
  BUTTON_COLOR_PRIMARY,
  BUTTON_DEFAULT_PREFIX_ICON_HEIGHT,
  BUTTON_DEFAULT_PREFIX_ICON_WIDTH,
  BUTTON_DEFAULT_SUFFIX_ICON_HEIGHT,
  BUTTON_DEFAULT_SUFFIX_ICON_WIDTH,
  BUTTON_LOADING_TEST_ID,
  BUTTON_PREFIX_ICON_TEST_ID,
  BUTTON_RADIUS_ROUND,
  BUTTON_RADIUS_SQUARE,
  BUTTON_SIZE_LARGE,
  BUTTON_SIZE_MEDIUM,
  BUTTON_SIZE_X_LARGE,
  BUTTON_STATUS_DISABLE,
  BUTTON_STATUS_ENABLE,
  BUTTON_STATUS_HOVER,
  BUTTON_STATUS_PRESSED,
  BUTTON_STATUS_SELECT,
  BUTTON_SUFFIX_ICON_TEST_ID,
  BUTTON_TEST_ID,
  BUTTON_VARIANT_CONTAINED
} from '../../constants/Button';
import { NORMAL_FONT } from '../../styles/typography';
import { Icon } from '../Icon';
import { IconProps } from '../Icon/Icon.types';
import { LoadingIndicator } from '../LoadingIndicator';
import { ButtonColorStyles, ButtonSizeStyles } from './Button.styles';
import { ButtonProps, ButtonStatus } from './Button.types';

const getHexToColorWithOpacity = (color: string, opacity: number) => {
  return `${color}${(opacity * 100).toString(16).padStart(2, '0')}`;
};

const buttonStatuses: ButtonStatus[] = [
  BUTTON_STATUS_HOVER,
  BUTTON_STATUS_PRESSED,
  BUTTON_STATUS_SELECT,
  BUTTON_STATUS_DISABLE,
];

export const Button = ({
  loading,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      loading={loading}
      { ...props }
    />
  );
};

const StyledButton = styled(({
  className,
  style,
  loading,
  disabled,
  children,
  prefixIcon,
  prefixIconWidth = BUTTON_DEFAULT_PREFIX_ICON_WIDTH,
  prefixIconHeight = BUTTON_DEFAULT_PREFIX_ICON_HEIGHT,
  suffixIcon,
  suffixIconWidth = BUTTON_DEFAULT_SUFFIX_ICON_WIDTH,
  suffixIconHeight = BUTTON_DEFAULT_SUFFIX_ICON_HEIGHT,
  onClick,
}: ButtonProps) => {
  const isDisabled = disabled || loading || false;

  return (
    <MuiButton
      data-testid={BUTTON_TEST_ID}
      className={className}
      style={style}
      disabled={isDisabled}
      onClick={onClick}
    >
      {
        prefixIcon
          && (
            <PrefixIcon
              data-testid={BUTTON_PREFIX_ICON_TEST_ID}
              icon={prefixIcon}
              width={prefixIconWidth}
              height={prefixIconHeight}
            />
          )
      }
      {
        loading
          ? (
            <div
              data-testid={BUTTON_LOADING_TEST_ID}
              className="Button__LoadingIndicator"
            >
              <LoadingIndicator
                style={{
                  width: 24,
                  height: 24,
                }}
              />
            </div>
          )
          : (
            children
          )
      }
      {
        suffixIcon
          && (
            <SuffixIcon
              data-testid={BUTTON_SUFFIX_ICON_TEST_ID}
              icon={suffixIcon}
              width={suffixIconWidth}
              height={suffixIconHeight}
            />
          )
      }
    </MuiButton>
  );
})(({
  color = BUTTON_COLOR_PRIMARY,
  size = BUTTON_SIZE_MEDIUM,
  loading = false,
  radius = BUTTON_RADIUS_SQUARE,
  variant = BUTTON_VARIANT_CONTAINED,
}) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: NORMAL_FONT,
    textTransform: 'none',
    boxShadow: 'none !important',
    ...(
      radius === BUTTON_RADIUS_SQUARE
      && {
        borderRadius: 8,
      }
    ),
    ...(
      radius === BUTTON_RADIUS_ROUND
      && {
        borderRadius: 1000,
      }
    ),
    ...(
      size === BUTTON_SIZE_X_LARGE && loading
      && {
        padding: '0 16px',
      }
    ),
    ...(
      size === BUTTON_SIZE_LARGE && loading
      && {
        padding: '0 12px',
      }
    ),
    ...(
      size === BUTTON_SIZE_MEDIUM && loading
      && {
        padding: '0 10px',
      }
    ),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: getHexToColorWithOpacity(
      ButtonColorStyles[color][variant][BUTTON_STATUS_ENABLE].border.hex,
      ButtonColorStyles[color][variant][BUTTON_STATUS_ENABLE].border.opacity
    ),
    color: ButtonColorStyles[color][variant][BUTTON_STATUS_ENABLE].color.hex,
    backgroundColor: ButtonColorStyles[color][variant][BUTTON_STATUS_ENABLE].background.hex,
    lineHeight: ButtonSizeStyles[size].typography.lineHeight,
    fontWeight: ButtonSizeStyles[size].typography.fontWeight,
    fontSize: ButtonSizeStyles[size].typography.fontSize,
    paddingTop: ButtonSizeStyles[size].paddingTop,
    paddingRight: ButtonSizeStyles[size].paddingRight,
    paddingBottom: ButtonSizeStyles[size].paddingBottom,
    paddingLeft: ButtonSizeStyles[size].paddingLeft,
    height: ButtonSizeStyles[size].height,
    ...(
      buttonStatuses.map((status) => {
        let cssEvent = '&:hover';
        if (status === BUTTON_STATUS_SELECT) cssEvent = '&:focus';
        if (status === BUTTON_STATUS_PRESSED) cssEvent = '&:active';
        if (status === BUTTON_STATUS_DISABLE) cssEvent = '&:disabled';

        const style = {
          [cssEvent]: {
            borderColor: getHexToColorWithOpacity(
              ButtonColorStyles[color][variant][status].color.hex,
              ButtonColorStyles[color][variant][status].color.opacity
            ),
            backgroundColor: ButtonColorStyles[color][variant][status].background.hex,
            color: ButtonColorStyles[color][variant][status].color.hex,
          },
        };

        return style;
      }).reduce((acc, styles) => ({ ...acc, ...styles }), {})
    ),
  };
});

const PrefixIcon = styled(({
  width,
  height,
  ...props
}: IconProps) => {
  return (
    <div
      data-testid={BUTTON_PREFIX_ICON_TEST_ID}
      style={{
        width,
        height,
      }}
    >
      <Icon
        { ...props }
      />
    </div>
  );
})`
  margin-right: 8px;
`;

const SuffixIcon = styled(({
  width,
  height,
  ...props
}: IconProps) => {
  return (
    <div
      data-testid={BUTTON_SUFFIX_ICON_TEST_ID}
      style={{
        width,
        height,
      }}
    >
      <Icon
        { ...props }
      />
    </div>
  );
})`
  margin-left: 8px;
`;
