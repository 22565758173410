import { CSSProperties } from 'react';

import BinTrash3x from '@/assets/icons/app/bin-trash-style-5-vertical-lines 2@3x.png';
import BtnCloseDefaultBlack3x from '@/assets/icons/app/btn_close_default_black@3x.png';
import BtnCloseDefault3x from '@/assets/icons/app/btn_close_default@3x.png';
import CopyDuplicate3x from '@/assets/icons/app/copy-duplicate-object-add-plus 3@3x.png';
import DownloadArrow3x from '@/assets/icons/app/download-arrow 3@3x.png';
import ExitLogOut3x from '@/assets/icons/app/exit-log-out 3@3x.png';
import CheckGrey3x from '@/assets/icons/app/ic_check_grey@3x.png';
import Facebook3x from '@/assets/icons/app/ic_fb@3x.png';
import FolderGrey3x from '@/assets/icons/app/ic_folder_grey@3x.png';
import Instagram3x from '@/assets/icons/app/ic_instagram@3x.png';
import MyPageSelected3x from '@/assets/icons/app/ic_mypage_selected@3x.png';
import Redo3x from '@/assets/icons/app/ic_redo@3x.png';
import TrashGrey3x from '@/assets/icons/app/ic_trash_grey@3x.png';
import Trash3x from '@/assets/icons/app/ic_trash@3x.png';
import Undo3x from '@/assets/icons/app/ic_undo@3x.png';
import Youtube3x from '@/assets/icons/app/ic_youtube@3x.png';
import AddMyPageEmptry3x from '@/assets/icons/app/ico_add_mypage_empty@3x.png';
import Back3x from '@/assets/icons/app/ico_back@3x.png';
import CheckDefault3x from '@/assets/icons/app/ico_check_default@3x.png';
import CheckHover3x from '@/assets/icons/app/ico_check_hover@3x.png';
import CheckPressed3x from '@/assets/icons/app/ico_check_pressed@3x.png';
import Close3x from '@/assets/icons/app/ico_close@3x.png';
import Download3x from '@/assets/icons/app/ico_download@3x.png';
import EditTitle3x from '@/assets/icons/app/ico_edit title@3x.png';
import IconEdit3x from '@/assets/icons/app/ico_edit@3x.png';
import Enlarge3x from '@/assets/icons/app/ico_enlarge@3x.png';
import GraphicActive3x from '@/assets/icons/app/ico_graphic_active@3x.png';
import Graphic from '@/assets/icons/app/ico_graphic@3x.png';
import HandleRotateActive3x from '@/assets/icons/app/ico_handle_rotate_Active@3x.png';
import HandleRotate3x from '@/assets/icons/app/ico_handle_rotate@3x.png';
import Help3x from '@/assets/icons/app/ico_help@3x.png';
import Imagetype3x from '@/assets/icons/app/ico_imagetype@3x.png';
import LoginApple3x from '@/assets/icons/app/ico_login_apple@3x.png';
import LoginFacebook3x from '@/assets/icons/app/ico_login_fb@3x.png';
import LoginGoogle3x from '@/assets/icons/app/ico_login_google@3x.png';
import MoreDefault3x from '@/assets/icons/app/ico_more_default@3x.png';
import MoreHover3x from '@/assets/icons/app/ico_more_hover@3x.png';
import MyWork3x from '@/assets/icons/app/ico_mywork@3x.png';
import NavbarHamburger3x from '@/assets/icons/app/ico_navBar_hamburger@3x.png';
import Open3x from '@/assets/icons/app/ico_open@3x.png';
import PaymentBack3x from '@/assets/icons/app/ico_payment_back@3x.png';
import Payment3x from '@/assets/icons/app/ico_payment@3x.png';
import Pertrash3x from '@/assets/icons/app/ico_pertrash@3x.png';
import Profile3x from '@/assets/icons/app/ico_profile@3x.png';
import Save3x from '@/assets/icons/app/ico_save@3x.png';
import Selected3x from '@/assets/icons/app/ico_selcted@3x.png';
import TemplatesActive3x from '@/assets/icons/app/ico_templates_active@3x.png';
import Templates from '@/assets/icons/app/ico_templates@3x.png';
import MaterialSymbolsKeyboardArrowDownRounded3x from '@/assets/icons/app/material-symbols_keyboard-arrow-down-rounded@3x.png';
import SettingStyle5Big3x from '@/assets/icons/app/setting-style-5-big 3@3x.png';
import ShareStarSquare3x from '@/assets/icons/app/share-star-square 3@3x.png';
import TrashBinRestoreBackUpArrow3x from '@/assets/icons/app/trash-bin-restore-back-up-arrow 1@3x.png';

export const IconSet = {
  'navbar-hamburger': {
    '3x': NavbarHamburger3x,
  },
  'undo': {
    '3x': Undo3x,
  },
  'redo': {
    '3x': Redo3x,
  },
  'help': {
    '3x': Help3x,
  },
  'payment-back': {
    '3x': PaymentBack3x,
  },
  'handle-rotate': {
    '3x': HandleRotate3x,
  },
  'handle-rotate-active': {
    '3x': HandleRotateActive3x,
  },
  'selected': {
    '3x': Selected3x,
  },
  'material-symbols_keyboard-arrow-down-rounded': {
    '3x': MaterialSymbolsKeyboardArrowDownRounded3x,
  },
  'imagetype': {
    '3x': Imagetype3x,
  },
  'trash': {
    '3x': Trash3x,
  },
  'trash-bin-restore-back-up-arrow': {
    '3x': TrashBinRestoreBackUpArrow3x,
  },
  'pertrash': {
    '3x': Pertrash3x,
  },
  'graphic-active': {
    '3x': GraphicActive3x,
  },
  'templates-active': {
    '3x': TemplatesActive3x,
  },
  'enlarge': {
    '3x': Enlarge3x,
  },
  'graphic': {
    '3x': Graphic,
  },
  'templates': {
    '3x': Templates,
  },
  'save': {
    '3x': Save3x,
  },
  'download': {
    '3x': Download3x,
  },
  'back': {
    '3x': Back3x,
  },
  'edit': {
    '3x': IconEdit3x,
  },
  'bin-trash-style-5-vertical-lines': {
    '3x': BinTrash3x,
  },
  'copy-duplicate-object-add-plus': {
    '3x': CopyDuplicate3x,
  },
  'btn-close-default': {
    '3x': BtnCloseDefault3x,
  },
  'btn-close-default-black': {
    '3x': BtnCloseDefaultBlack3x,
  },
  'download-arrow': {
    '3x': DownloadArrow3x,
  },
  'exit-log-out': {
    '3x': ExitLogOut3x,
  },
  'setting-style-5-big': {
    '3x': SettingStyle5Big3x,
  },
  'share-star-square': {
    '3x': ShareStarSquare3x,
  },
  'folder-grey': {
    '3x': FolderGrey3x,
  },
  'trash-grey': {
    '3x': TrashGrey3x,
  },
  'check-grey': {
    '3x': CheckGrey3x,
  },
  'add-mypage-empty': {
    '3x': AddMyPageEmptry3x,
  },
  'login-google': {
    '3x': LoginGoogle3x,
  },
  'youtube': {
    '3x': Youtube3x,
  },
  'facebook': {
    '3x': Facebook3x,
  },
  'instagram': {
    '3x': Instagram3x,
  },
  'my-page-selected': {
    '3x': MyPageSelected3x,
  },
  'login-apple': {
    '3x': LoginApple3x,
  },
  'login-facebook': {
    '3x': LoginFacebook3x,
  },
  'mywork': {
    '3x': MyWork3x,
  },
  'more-default': {
    '3x': MoreDefault3x,
  },
  'more-hover': {
    '3x': MoreHover3x,
  },
  'check-default': {
    '3x': CheckDefault3x,
  },
  'check-hover': {
    '3x': CheckHover3x,
  },
  'check-pressed': {
    '3x': CheckPressed3x,
  },
  'profile': {
    '3x': Profile3x,
  },
  'payment': {
    '3x': Payment3x,
  },
  'edit-title': {
    '3x': EditTitle3x,
  },
  'open': {
    '3x': Open3x,
  },
  'close': {
    '3x': Close3x,
  },
};

export type ICON_NAMES = keyof typeof IconSet;

export interface IconProps {
  className?: string;
  style?: CSSProperties;
  icon: ICON_NAMES;
  alt?: string;
  width?: number;
  height?: number;
}
