import { createContext } from 'react';

import { StickerContextValue, StickerProviderProps } from './StickerProvider.types';

export const StickerContext = createContext<StickerContextValue | undefined>(undefined);

export const StickerProvider = ({
  children,
  object,
}: StickerProviderProps) => {
  return (
    <StickerContext.Provider
      value={{ object }}
    >
      { children }
    </StickerContext.Provider>
  );
};
