export class ClientError extends Error {
  constructor(name: string, message: string) {
    super();
    this.name = name;
    this.message = message;
  }
}

/**
 * 어떠한 데이터의 유효 기간이 만료 되었을 때 반환합니다.
 */
export class ExpiredError extends ClientError {
  constructor(expiredVariableNames: string[] = []) {
    const name = 'EXPIRED_ERROR';
    const message = expiredVariableNames.length > 0
      ? `${expiredVariableNames.join(', ')} is expired.`
      : 'Data is expired.';

    super(name, message);
  }
}

/**
 * 필수 데이터가 전달 되지 않았을 때 반환합니다.
 */
export class RequiredError extends ClientError {
  constructor(variableNames: string[] = []) {
    const name = 'REQUIRED_ERROR';
    const message = variableNames.length > 0
      ? `${variableNames.join(', ')} is required.`
      : 'Required field is empty';

    super(name, message);
  }
}
