import { useContext } from 'react';

import { ConfirmContext } from './ConfirmProvider';

export const useConfirm = () => {
  const context = useContext(ConfirmContext);

  if (context === undefined) throw new Error('ConfirmContext is undefined.');

  return context;
};
