/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { type PropsWithChildren, useContext } from 'react';

import { getFlipCSS, getObjectPosition, getObjectRotateCSS } from '../../utils/common';
import { RendererContext } from '../Renderer/RendererProvider';
import { StickerContext } from './StickerProvider';

export const StickerStyler = ({ children }: PropsWithChildren) => {
  const renderContext = useContext(RendererContext);
  const context = useContext(StickerContext);

  if (renderContext === undefined) throw new Error('RenderProvider not declared');
  if (context === undefined) throw new Error('StickerProvider not declared');

  const { object } = context;

  const {
    width: canvasWidth,
    height: canvasHeight,
  } = renderContext;

  const {
    frameWidth,
    frameHeight,
    centerX,
    centerY,
    arg,
    flipped,
    alpha,
  } = object;

  const { left, top } = getObjectPosition({
    canvasWidth,
    canvasHeight,
    frameWidth,
    frameHeight,
    centerX,
    centerY,
  });

  const rotateStyle = getObjectRotateCSS(arg);

  const flipStyle = getFlipCSS(flipped);

  return (
    <div
      className="StickerStyler"
      data-object
      style={{
        left,
        top,
        width: frameWidth,
        height: frameHeight,
        transform: `${rotateStyle} ${flipStyle}`,
        opacity: alpha,
      }}
      css={css`
        position: absolute;
      `}
    >
      { children }
    </div>
  );
};
