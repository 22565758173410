import { PropsWithChildren, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from '../../app/ErrorFallback';

interface ErrorProperties {
  cause: any;
  info?: string;
}

const ERROR_UNKNOWN_CAUSE = 'Unknown Error';

export const AppErrorBoundary = ({ children }: PropsWithChildren) => {
  const logError = useCallback((error: any, info?: { componentStack: string }) => {
    // 전역 에러 로깅 트래킹을 위해 SmartLook 전역 에러 커스텀 이벤트 호출
    if(process.env.NODE_ENV === 'production') {
      const properties: ErrorProperties = {
        cause: ERROR_UNKNOWN_CAUSE,
        info: info?.componentStack,
      };

      if (error instanceof Error) {
        properties.cause = error.cause ?? error.message;
        window?.smartlook('error', properties);
      }

      if (typeof error === 'string') {
        properties.cause = error;
        window.smartlook('error', properties);
      }
    }
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={logError}
    >
      { children }
    </ErrorBoundary>
  );
};
