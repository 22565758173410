import { useEffect } from 'react';

import { UseResizeObserverArguments } from './useResizeObserver.types';

export const useResizeObserver = ({
  element,
  callback,
}: UseResizeObserverArguments) => {
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        callback?.(entry);
      });
    });

    element && observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [
    element,
    callback,
  ]);
};
