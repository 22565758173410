import { getStickerObjectResourceId, SwitchCase } from '@lws/common';
import { Fragment, useContext, useEffect, useState } from 'react';

import { isSVG } from '../../utils/sticker';
import { ResourcesContext } from '../Renderer/ResourcesProvider';
import { ImageRenderer } from './ImageRenderer';
import { StickerContext } from './StickerProvider';
import { SVGResourceRenderer } from './SVGResourceRenderer';

export const StickerResourceRenderer = () => {
  const resourcesContext = useContext(ResourcesContext);
  const context = useContext(StickerContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSVGResource, setIsSVGResource] = useState(false);

  if (resourcesContext === undefined) throw new Error('ResourcesProvider not declared');
  if (context === undefined) throw new Error('StickerProvider not declared');

  const { object } = context;
  const { contentId } = object;
  const { symbol } = resourcesContext;

  const resourceId = getStickerObjectResourceId(contentId);
  const resource = symbol[resourceId];

  if (resource === undefined) throw new Error(`Empty resoure: ${resourceId}`);

  useEffect(() => {
    isSVG(resource)
      .then((flag) => {
        setIsSVGResource(flag);
      })
      .finally(() => {
        setIsLoaded(true);
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <SwitchCase
        value={isLoaded}
        caseBy={{
          'true': (
            <SwitchCase
              value={isSVGResource}
              caseBy={{
                'true': (
                  <SVGResourceRenderer />
                ),
                'false': (
                  <ImageRenderer />
                ),
              }}
            />
          ),
        }}
      />
    </Fragment>
  );
};
