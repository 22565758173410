import { Fragment } from 'react';

import { SwitchCaseProps } from './SwitchCase.types';

export const SwitchCase = ({ caseBy, value, defaultComponent }: SwitchCaseProps) => {
  return (
    <Fragment>
      { caseBy[value] ?? defaultComponent }
    </Fragment>
  );
};
